import {
    Container,
    Grid,
    Box,
    ButtonBase,
    makeStyles,
    Card,
    CardMedia,
    CardContent,
    useTheme,
    Slide,
    Typography,
    Hidden,
    useMediaQuery,IconButton,Snackbar,
  } from "@material-ui/core";
  import { useState, useEffect } from "react";
  import parse from "react-html-parser";
  import MuiAlert from "@material-ui/lab/Alert";
  import Axios from "axios";
  import {Link, useParams} from 'react-router-dom';
  import { Helmet } from "react-helmet-async";
  import LinkIcon from '@material-ui/icons/Link';
  import "yet-another-react-lightbox/styles.css";
  import Lightbox from "yet-another-react-lightbox";
  import { Loading } from "./loading";
  
  import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
   // RedditShareButton,
   // RedditIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
   // ViberShareButton,
   XIcon,
    WhatsappShareButton,
    WhatsappIcon,
    RedditIcon,
    RedditShareButton
    
  } from "react-share";

  import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
  
  const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";
  
  const useStyles = makeStyles({
    cardshadow: {
      "&:hover": {
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      },
    },
  });

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  
  export const SingleArticle = () => {
    const params = useParams();
    const id = params.id;
    const theme = useTheme();
    const url=window.location.href;
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [metaTitle, setMetaTitle] = useState("");

    const [snackBar, setSnackBar] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState("success");
    const [snackBarType, setSnackBarType] = useState("Invalid");
  
    const openSnkbar = (type, msg) => {
      setSnackBarMsg(msg);
      setSnackBarType(type);
      setSnackBar(true);
    };
  
  const [open1, setOpen1] = useState(false);
 
 
    const [isloading, setIsloading] = useState(true);

 
  const [tooSlideImages, setTooSlideImages] = useState([]);
   
 
  const [closeOnPullDown, setCloseOnPullDown] =  useState(true);
    const [closeOnBackdropClick, setCloseOnBackdropClick] = useState(true);
    const handleClick = () => {
      const anchor = document.querySelector("#back-to-top-anchor");
  
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };
  
    useEffect(() => {
      getMetaData();
    }, []);
  
    const getMetaData = async() => {
        const formdata = new FormData();
        formdata.append("article_id", id);
        Axios
          .post("/api/get_single_article", formdata)
          .then((res) => {
            console.log(res.data.data);
            if (res.data.status === true) {
              setMetaTitle(res.data.data.article_title);
              setData(res.data.data);
              handleClick();
              setIsloading(false);
            }
          }) ;
    };
  
 
  // Function to handle click on any image (other than the first) and open a lightbox with a specific starting index.
  const handleImageClick = (index) => {
    var slideImages = data.article_images.split(",");
    // Rearrange the images array to start from the clicked index and set it for the lightbox.
    var shiftImages = slideImages.splice(0, index + 1);
    slideImages.push(...shiftImages);
    // Set the images for the lightbox and open it.
    setTooSlideImages(slideImages);
    setOpen1(true);
     
  };

   
  
    return (
      <div>
        <Helmet>
          <title>{metaTitle}</title>
           
        </Helmet>
         
       
        {isloading ? (
          <Loading />
        ) : (
          <Box
            boxShadow={3}
            p={3}
             
            style={{ backgroundColor: theme.palette.cream.main }}
          >
          <Container maxWidth="lg">
            
            <br/>
          
            {open1 && (
                      // Lightbox component for additional images in a separate state (`tooSlideImages`).
                      <Lightbox
                        open={open1}
                        close={() => setOpen1(false)}
                        slides={tooSlideImages.map((src, index) => ({
                          src: `/static/articles/${src}`,
                          index,
                        }))}

                        controller={{ closeOnPullDown, closeOnBackdropClick }}
                        render={{
                            buttonPrev: tooSlideImages.length>1 ? undefined : () => null,
                            buttonNext: tooSlideImages.length>1 ? undefined : () => null,
                          }}
                       
                      />
                    )}
          
               
              <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={4}
                        mt={4}
                        >
                      
                      <Grid item xs={12}>
                            <div class="article_title">
                                <h1 style={{color:"#4c004c"}}>{data.article_title}
                                    <span style={{marginTop:"5px"}}><b>Posted Date: </b> {data.article_published_date}</span>
                                </h1>
                            </div>
                        </Grid>

                     
                     {data.article_images.split(",").length===1?(
                        <Grid item xs={12}>
                          <Box
                              style={{
                                width: "100%",
                                // paddingBottom: "70%",
                                height: "600px",  
                                 position: "relative",
                              }}
                            >
                            <img 
                                src={`/static/articles/${data.article_images.split(",")[0]
                            }`}
                            onClick={() => {
                              handleImageClick(-1);
                              
                            }}
                                alt={data.article_title}
                                style={{ width: "100%", height: "100%", cursor: "pointer",objectFit: "cover" }}
                            />
                        </Box>
                        </Grid>
                        ):(
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                          >
                           <Grid item xs={12} sm={12} md={6}> 
                           <Box
                              style={{
                                width: "100%",
                                // paddingBottom: "70%",
                                height: "600px",  
                                 position: "relative",
                                 
                              }}
                            >
                                      <img 
                                          src={`/static/articles/${data.article_images.split(",")[0]
                                      }`}
                                      onClick={() => {
                                        handleImageClick(-1);
                                        
                                      }}
                                          alt={data.article_title}
                                          style={{ width: "100%", height: "100%", cursor: "pointer",objectFit: "cover" }}
                                      />
                            </Box>        
                           </Grid>
                           <Hidden smDown>
                            <Grid item  md={6}  > 
                            {  data?.article_images.split(",").length >3 ? <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                              >
                                {  data?.article_images.split(",")
                              .slice(1, 5)
                              .map((i,  index) => (
                                <>
                               { index!== 3 && <Grid item   md={6}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "300px",  
                                      // paddingBottom: "70%",
                                      position: "relative",
                                    }}
                                  >
 
                                    <img
                                      src={`/static/articles/${i}`}
                                      onClick={() => {
                                        
                                        handleImageClick(index);
                                        
                                      }}
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        cursor: "pointer",
                                      }}
                                    />
                                </Box>
                               
                                </Grid>}
                                
                                { index=== 3 && <Grid item   md={6}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "300px",  
                                       position: "relative",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                       handleImageClick(index);
                                      
                                    }}
                                  >
                                    
                                    <img
                                      src={`/static/articles/${i}`}
                                      
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        
                                        opacity:data.article_images.split(",").length > 5 ?"0.5":"1.0"   
                                      }}
                                    />
                                   {data?.article_images.split(",").length > 5 && <Typography
                                    variant="h2"
                                    style={{
                                      color: "#4c004c",
                                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                                      position: 'absolute', 
                                      top: '50%',           
                                      left: '50%',          
                                      transform: 'translate(-50%, -50%)',  
                                    }}
                                  >
                                    +
                                    {data?.article_images.split(",").length - 5}
                                  </Typography> }
                                </Box>
                               
                                </Grid>}
                                
                                </>
                              ))}

                              
                            </Grid>:<Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                              >
                                {  data?.article_images.split(",")
                              .slice(1, 3)
                              .map((i,  index) => (
                                <>
                               <Grid item   md={12}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "300px",  
                                      // paddingBottom: "70%",
                                      position: "relative",
                                    }}
                                  >
 
                                    <img
                                      src={`/static/articles/${i}`}
                                      onClick={() => {
                                        console.log("Clicked Chethan", index);
                                        handleImageClick(index);
                                        
                                      }}
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        cursor: "pointer",
                                      }}
                                    />
                                </Box>
                               
                                </Grid> 
                                 
                                
                                </>
                              ))}

                              
                            </Grid>}
                            
                            </Grid>
                            </Hidden>             
                          </Grid>
                        </Grid>
                        )}  
                        <Hidden mdUp>
                        <Grid item xs={12}>
                        <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                              >
                                { data?.article_images.split(",")
                              .slice(1, 5)
                              .map((i,  index) => (
                                <>
                               { index!== 3 && <Grid item  xs={3} sm={3}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "100px",  
                                      // paddingBottom: "70%",
                                      position: "relative",
                                    }}
                                  >
 
                                    <img
                                      src={`/static/articles/${i}`}
                                      onClick={() => {
                                        console.log("Clicked Chethan", index);
                                        handleImageClick(index);
                                        
                                      }}
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        cursor: "pointer",
                                      }}
                                    />
                                </Box>
                               
                                </Grid>}
                                
                                { index=== 3 && <Grid item   xs={3} sm={3}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "100px",  
                                       position: "relative",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                       handleImageClick(index);
                                      
                                    }}
                                  >
                                    
                                    <img
                                      src={`/static/articles/${i}`}
                                      
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        
                                        opacity:data.article_images.split(",").length > 5 ?"0.5":"1.0"   
                                      }}
                                    />
                                   {data?.article_images.split(",").length > 5 && <Typography
                                    variant="h2"
                                    style={{
                                      color: "#4c004c",
                                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                                      position: 'absolute', 
                                      top: '50%',           
                                      left: '50%',          
                                      transform: 'translate(-50%, -50%)',  
                                    }}
                                  >
                                    +
                                    {data?.article_images.split(",").length - 5}
                                  </Typography> }
                                </Box>
                               
                                </Grid>}
                                
                                </>
                              ))}

                              
                            </Grid>
                        </Grid>
                        </Hidden>
                        <Snackbar
                      open={snackBar}
                      autoHideDuration={4000}
                      onClose={() => {
                        setSnackBar(false);
                      }}
                    >
                      <Alert
                        onClose={() => {
                          setSnackBar(false);
                        }}
                        severity={snackBarType}
                        style={{
                          color: snackBarType === "warning" ? "black" : "white",
                        }}
                      >
                        {snackBarMsg}
                      </Alert>
                    </Snackbar>
                        <Grid item xs={12} style={{textAlign:'left',letterSpacing: '3px',textTransform: 'uppercase'}}>
                        <Typography variant="body1" gutterBottom  style={{color:theme.palette.secondary.main   }}>
                                      <b>Share this Article:  </b>
                        <FacebookShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <FacebookIcon size={30} round={true}/>
                        </FacebookShareButton>      
                        <LinkedinShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <LinkedinIcon size={30} round={true}/>
                        </LinkedinShareButton>   
                        <TwitterShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <XIcon size={30} round={true}/>
                        </TwitterShareButton>    
                         
                        <WhatsappShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <WhatsappIcon size={30} round={true}/>
                        </WhatsappShareButton>
                        <RedditShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <RedditIcon size={30} round={true}/>
                        </RedditShareButton>
                        <TelegramShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <TelegramIcon size={30} round={true}/>
                        </TelegramShareButton>
                        <EmailShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <EmailIcon size={30} round={true}/>
                        </EmailShareButton>
                        {/* <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(url)
                              .then(() => openSnkbar("success", "Link copied to clipboard!") )
                              .catch(() => {

                              });
                          }}
                          aria-label="copy link"
                        >
                        <LinkIcon fontSize="medium"/>
                        </IconButton> */}
                        </Typography>
                        
                     </Grid>
                        
                         
                        <Grid item xs={12}>
                        <p className="article_desc">{parse(
                                    data.article_desc
                        )}</p>
                        </Grid>
                        <Grid item xs={12} style={{borderBottom:"2px Solid black"}}>

                        </Grid>

                        </Grid>  
                         
               
            <Slide
                direction="right"
                in={true}
                mountOnEnter
                unmountOnExit
                {...(true ? { timeout: 1000 } : {})}
              >
            <div >
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <blockquote className="article_desc">
              At Arawinz Soft Solutions, we are not just keeping pace with the ever-evolving digital landscape; we are at the forefront, crafting the future. Each update, every innovation, is a testament to our journey towards excellence. Our 'What's New' page is a showcase of this relentless pursuit – a window into how we're transforming ideas into cutting-edge solutions. It's where creativity meets technology, ensuring we're always a step ahead in delivering exceptional value to our clients. Join us here in witnessing the dawn of new possibilities and the unfolding of IT breakthroughs.
                <span>-&nbsp;Dr. P. Srinivas Rao, CEO</span>
              </blockquote>
              <br/>
              <br/>
            </div>
            </Slide>
          </Container>
          
          </Box>
        )}
     
      </div>
    );
  };
  
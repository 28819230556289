import {
  Container,
  Grid,
  Button,
  Grow,
  Typography,
  Paper,
  useTheme,
  Box,
  Slide,
  Avatar,
  makeStyles,
  ButtonBase
} from "@material-ui/core";
import { useState, useEffect } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import { Helmet } from "react-helmet-async";
import { Loading } from "./loading";
import parse from "html-react-parser";

import { Link } from "react-router-dom";

import Card from "@material-ui/core/Card";

import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";

const useStyles = makeStyles({
  nounderline: {
    "&:hover": { textDecoration: "none" },
  },
  cardshadow: {
    "&:hover": {
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    },
  },
});

export const Services = () => {
  const theme = useTheme();

  const classes = useStyles();

  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [isloading, setIsloading] = useState(true);
  const [services, setServices] = useState();
  //const [checked, setChecked] =  useState(true);

  const handleClick = () => {
    const anchor = document.querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    getMetaData();
  }, []);

  const getMetaData = () => {
    const formData = new FormData();
    formData.append("page", 2);
    Axios.get("/api/getservices", {
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.data.status) {
        setServices(res.data.data);
      }
    });
    Axios.post("/api/getmetadata", formData, {
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.data.status) {
        setMetaTitle(res.data.data.meta_title);
        setMetaKeywords(res.data.data.meta_keywords);
        setMetaDesc(res.data.data.meta_desc);
        setIsloading(false);
        handleClick();
      }
    });
  };

  const jumbotroncss = {
    backgroundImage:
      'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url("/static/services.jpg")',
    opacity: 0.8,
    backgroundAttachment: "fixed",
    color: "white",
    minHeight: "250px",
    //marginTop: "0px",
    marginBottom: "0px",
    borderRadius:"0px"
  };

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
        <meta name="keywords" content={metaKeywords}></meta>
      </Helmet>
      <div className="jumbotron text-center" style={jumbotroncss}>
        <Paper style={{ opacity: "0.8" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h2"
              style={{
                color: "white",
                fontWeight: 500,
                WebkitTextStrokeWidth: 2,
                letterSpacing: 8,
                WebkitTextStrokeColor: theme.palette.primary.main,
              }}
            >
              Our{" "}
            </Typography>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Typography
              variant="h2"
              style={{
                color: theme.palette.primary.main,
                fontWeight: 500,
                letterSpacing: 8,
              }}
            >
              Services
            </Typography>
          </Grid>
          <Typography variant="h6" style={{color:theme.palette.primary.main,letterSpacing:1
          }}>
Elevate Your Business with Our IT Services          </Typography>
          <br />
        </Paper>
      </div>
       
      {isloading ? (
        <Loading />
      ) : (
        <Box
          boxShadow={3}
          p={3}
          // mt={4}
          style={{ backgroundColor: theme.palette.cream.main }}
        >
          <Container maxWidth="lg">
          <br/>
          <Slide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              {...(true ? { timeout: 1000 } : {})}
            >
              <div class="two">
                <h1 style={{color:"#4c004c"}}>Solutions Crafted for Success: Our Service Range
                  <span>Explore the Breadth of Our Expertise and Customized IT Services</span>
                </h1>
              </div>
 
            </Slide>
            <br />
            <br />
            {services && (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={5}
                className="text-center"
              >
                {services.map((service, key) => {
                  let serv_dec = parse(service.serv_desc.substring(0, 200) + "...&nbsp;&nbsp;<span style='color: #4c004c;'> <strong>Know more</strong></span>");
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <Grow
                        in={true}
                        {...(true ? { timeout: 500 * (key + 1) } : {})}
                      >
                        <ButtonBase
                          className={classes.nounderline}
                          component={Link}
                          to={
                            "/service-details/" +
                            service.serv_name.split(" ").join("-") +
                            "/" +
                            service.serv_id
                          }
                        >

                       
                        <Card
                          style={{
                            width: "100%",
                            height:"490px",
                            border: "1px groove #4c004c",
                          }}
                          className={classes.cardshadow}
                        >
                          <CardMedia
                            style={{ height: 200 }}
                            image={"/static/services/" + service.serv_image}
                            title={service.serv_name}
                          />
                          <CardContent>
                            <Typography
                              variant="h5"
                              className="article_desc"
                              style={{ color: theme.palette.secondary.main,textAlign:"left" }}
                              gutterBottom
                            >
                              {service.serv_name}
                            </Typography>

                            <Typography
                              variant="body2"
                              color="textSecondary"
                              className="article_desc"
                              style={{  textAlign:"left", color: "#000000" }}
                            >
                              {serv_dec}{" "} 
                              {/* <span style={{ color: theme.palette.secondary.main }}>Know more</span> */}
                               
                            </Typography>
                          </CardContent>
                        </Card>
                        </ButtonBase>
                      </Grow>
                    </Grid>
                  );
                })}
              </Grid>
            )}

            <br />
            <br />
            <br />
          </Container>
          <Container maxWidth="lg">
          <br/>
          <Slide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              {...(true ? { timeout: 1000 } : {})}
            >
              <div class="two">
                <h1 style={{color:"#4c004c"}}>Our Toolbox: Advanced Technologies at Work
                  <span>Exploring the Cutting-Edge Tools and Platforms We Utilize</span>
                </h1>
              </div>
 
            </Slide>
            <br />
            <br />
            <Slide
              direction="down"
              in={true}
              mountOnEnter
              unmountOnExit
              {...(true ? { timeout: 2000 } : {})}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="NodeJs"
                    src="/static/techs/nodejs.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="Python"
                    src="/static/techs/python.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="dart"
                    src="/static/techs/dart.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                {/* <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <Avatar
                    alt="Ruby"
                    src="/static/techs/ruby.png"
                    style={{
                      borderRadius: "0px",
                      width: theme.spacing(9),
                      height: theme.spacing(9),
                    }}
                  />
                </Grid> */}

                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="ReactJs"
                    src="/static/techs/react.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="c-sharp"
                    src="/static/techs/c-sharp.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="php"
                    src="/static/techs/php.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>

                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="ExpressJs"
                    src="/static/techs/express.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="Flask"
                    src="/static/techs/flask.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid><Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="Pyteal"
                    src="/static/techs/pyteal.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid><Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="Solidity"
                    src="/static/techs/solidity.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="Flutter"
                    src="/static/techs/flutter.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="Django"
                    src="/static/techs/django.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                {/* <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <Avatar
                    alt="rails"
                    src="/static/techs/rails.png"
                    style={{
                      borderRadius: "0px",
                      width: theme.spacing(9),
                      height: theme.spacing(9),
                    }}
                  />
                </Grid> */}
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="codeigniter"
                    src="/static/techs/codeigniter.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>

                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="Xcode"
                    src="/static/techs/xcode.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="Android Studio"
                    src="/static/techs/androidstudio.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="Unity"
                    src="/static/techs/unity.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="javascript"
                    src="/static/techs/javascript.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="jquery"
                    src="/static/techs/jquery.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="Html"
                    src="/static/techs/html.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="CSS"
                    src="/static/techs/css.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="Bootstrap"
                    src="/static/techs/bootstrap.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>

                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="MongoDb"
                    src="/static/techs/mongodb.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="mysql"
                    src="/static/techs/mysql.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={2} sm={3} xs={4}>
                  <img
                    alt="firebase"
                    src="/static/techs/firebase.png"
                    style={{
                       
                      objectFit: "contain" ,
                      width: "100%",
                      height: "100px",
                    }}
                  />
                </Grid>
              </Grid>
            </Slide>
            <br />
            <Slide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              {...(true ? { timeout: 1000 } : {})}
            >
          <div >
            <br/>
            <br/>
            <blockquote className="article_desc">
            As Managing Director and Architect of ESAY and AraFaceFinder, I witness firsthand the Arawinz team's blend of innovation and dedication. We're committed to excellence, understanding our clients' needs, and shaping a smarter, more connected future.
              <span>-&nbsp;Chethan. P. S. S, Managing Director and Chief Architect</span>
            </blockquote>
            <br/>
            <br/>
          </div>
          </Slide>
          </Container>
        </Box>
      )}
      
    </div>
  );
};

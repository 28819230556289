import {Grid,useTheme} from '@material-ui/core';
import {CircularProgress} from '@material-ui/core';

export const Loading = () =>{
    const theme = useTheme()
    return (<Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    style={{height:'70vh' }}
    >
        <CircularProgress style={{color: theme.palette.secondary.main}} />
    </Grid>);
}
 
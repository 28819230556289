import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Snackbar,
  Switch,
  MenuItem,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import { Loading } from "./loading";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import CKEditor from "react-ckeditor-component";
const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
let greenColor = {
  color: "green",
  fontWeight: "bold",
};

let blackColor = {
  color: "black",
};
export const UpdateTeam = () => {
  let { id } = useParams();
  const [isloading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [skills, setSkills] = useState("");
  const [disgn, setDisgn] = useState("");
  const [desc, setDesc] = useState("");
  const [role,setRole] = useState("")
  const [empPic, setEmpPic] = useState({ raw: "" });
  const [status, setStatus] = useState(true);
  const [priority, setPriority] = useState();
  const [oldImage, setOldImage] = useState("");
  const [err, setErr] = useState(0);
  const [disablebutton, setDisablebutton] = useState(false);

  const [snackBar, setSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("success");
  const [snackBarType, setSnackBarType] = useState("Invalid");
  const navigate = useNavigate();
  const openSnkbar = (type, msg) => {
    setSnackBarMsg(msg);
    setSnackBarType(type);
    setSnackBar(true);
  };

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    const formData = new FormData();
    formData.append("idd", id);
    Axios.post("/api/select_single_team", formData, {
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.data.status) {
        console.log(res.data.data);
        setName(res.data.data.name);
        setPriority(res.data.data.priority);
        setSkills(res.data.data.skill);
        setDisgn(res.data.data.designation);
        setDesc(res.data.data.description);
        // setRole(res.data.data.role);
        if (res.data.data.image !== null) {
          setOldImage(res.data.data.image);
        }
        if (res.data.data.status === 1) {
          setStatus(true);
        } else {
          setStatus(false);
        }
        setIsLoading(false);
      }
    });
  };
  const onSubmit = () => {
    setErr(0);
    if (name == "") {
      setErr(1);
      openSnkbar("error", "Please Enter Employe Name....");
    } else if (disgn == "") {
      setErr(2);
      openSnkbar("error", "Please Enter Employe Designation....");
    } else if (skills == "") {
      setErr(3);
      openSnkbar("error", "Please Select Skills....");
    } else if (desc == "") {
      setErr(4);
      openSnkbar("error", "Please Enter Employe Description....");
    }
    // else if (role == "") {
    //   setErr(5);
    //   openSnkbar("error", "Please Select Role....");
    // } 
    else if (priority == "") {
      setErr(6);
      openSnkbar("error", "Please Select Priority...");
    } else if (empPic.raw == "" && oldImage == "") {
      setErr(7);
      openSnkbar("error", "Please Select a Employe Image....");
    } else {
      openSnkbar("warning", "Please wait.....");
      setDisablebutton(true);
      const formData = new FormData();
      if (empPic.raw !== "") {
        formData.append("image", empPic.raw);
      }
      formData.append("idd", id);
      formData.append("designation", disgn);
      formData.append("skills", skills);
      formData.append("priority", priority);
      formData.append("name", name);
      formData.append("description", desc);
      formData.append("oldimage", oldImage);
      if (status) {
        formData.append("status", "1");
      } else {
        formData.append("status", "0");
      }
      Axios.post("/api/update_team", formData, {
        headers: { headKey: headKey },
      }).then((res) => {
        if (res.data.status) {
          setDisablebutton(false);
          setEmpPic({ raw: "" });
          openSnkbar("success", res.data.msg);
          setIsLoading(true);
          navigate("/dash/team");
        } else {
          setDisablebutton(false);
          openSnkbar("error", "Something went wrong... try later.");
        }
      });
    }
  };
  return (
    <Container>
      <Helmet>
        <title>Edit Team - Admin | Arawinz Soft Solutions</title>
        <meta
          name="description"
          content="Arawinz Soft Solutions is one of the finest Software Development company. We provide services in Web Development-Full Stack, Web Designing (Static & Responsive), Mobile App Development, Social Media Marketing, SEO, Data Analytics,  CRM, ERP, Mobile Gaming and Artificial Intelligence."
        />
        <meta
          name="keywords"
          content=" Testimonials Top Web Development company in Gachibowli, SEO companies in Hyderabad, Best Application development company in India, Top 5 development companies in Vizag, Digital and Social Media Marketing, Data Analytics company in Hitech city, Graphics Design and Gaming company in Jubilee hills, Best Artificial Intelligence company in Hyderabad, Top gaming organization near me, Available ERP &amp; CRM developers Madhapur"
        ></meta>
      </Helmet>
      {isloading ? (
        <Loading />
      ) : (
        <Box boxShadow={3} p={3} m={4} style={{ border: "2px solid #333333" }}>
          <Snackbar
            open={snackBar}
            autoHideDuration={4000}
            onClose={() => {
              setSnackBar(false);
            }}
          >
            <Alert
              onClose={() => {
                setSnackBar(false);
              }}
              severity={snackBarType}
              style={{
                color: snackBarType === "warning" ? "black" : "white",
              }}
            >
              {snackBarMsg}
            </Alert>
          </Snackbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={4}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <h2
                style={{
                  textDecoration: "underline",
                  marginBottom: "0px",
                  textAlign: "center",
                }}
              >
                Edit Employee
              </h2>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                id="filled-required"
                label="Name"
                variant="filled"
                error={err === 1 && true}
                value={name}
                size="small"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                id="filled-required"
                label="Disgn"
                variant="filled"
                error={err === 2 && true}
                value={disgn}
                size="small"
                onChange={(e) => {
                  setDisgn(e.target.value);
                }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                id="filled-required"
                label="Skills"
                variant="filled"
                error={err === 3 && true}
                value={skills}
                size="small"
                onChange={(e) => {
                  setSkills(e.target.value);
                }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="filled-required"
              label="Description"
              variant="filled"
              error={err === 4 && true}
              value={desc}
              size="small"
              multiline
              maxRows={4}
              onChange={(e) => {
                setDesc(e.target.value);
              }}
              required
              fullWidth
            />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField select
                id="filled-required"
                label="Role"
                variant="filled"
                error={err === 5 && true}
                value={role}
                size="small"
                onChange={(e) => {
                  setRole(e.target.value);
                }}
                required
                fullWidth
              > <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem></TextField>
            </Grid> */}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                select
                id="filled-required"
                label="Priority"
                variant="filled"
                error={err === 6 && true}
                value={priority}
                size="small"
                onChange={(e) => {
                  setPriority(e.target.value);
                }}
                required
                fullWidth
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Switch
                checked={status}
                onChange={() => {
                  setStatus(!status);
                }}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              ></Switch>
              Status -{" "}
              <span style={status ? greenColor : blackColor}>Show</span> /{" "}
              <span style={!status ? greenColor : blackColor}>Hide</span>
            </Grid>
            <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
              {oldImage != "" && (
                <img
                  width="250px"
                  height="250px"
                  src={"/static/team/" + oldImage}
                />
              )}
              <br />
              {empPic.raw != "" && (
                <img width="250px" height="250px" src={empPic.preview} />
              )}
              <br />
              <br />

              <input
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e.target.files.length != 0) {
                    var type = e.target.files[0].type;

                    if (
                      type === "image/jpeg" ||
                      type === "image/jpg" ||
                      type === "image/png"
                    ) {
                      setEmpPic({
                        preview: URL.createObjectURL(e.target.files[0]),
                        raw: e.target.files[0],
                      });
                    } else {
                      alert("Please select only JPEG, JPG, PNG");
                    }
                  }
                }}
                id="contained-button-file"
                type="file"
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  Upload Image
                </Button>
              </label>
              {err === 7 && (
                <div style={{ color: "red" }}>Please select a file.</div>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                onClick={onSubmit}
                variant="contained"
                color="primary"
                disabled={disablebutton}
              >
                {disablebutton ? "Please wait.." : "Update Employe"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
};

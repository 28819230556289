
import {Grid,Container, Typography} from '@material-ui/core';


export const Footer =()=>{
    return (<div className="main-footer">
         <Grid 
         container
         direction="row"
         justifyContent="center"
         alignItems="center"
         >
             <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography style={{textAlign:"center"}}>
               <p> Arawinz Soft Solutions Pvt Ltd. © Est. 2018</p>
                </Typography>
             </Grid>
         </Grid>
    </div>);
}
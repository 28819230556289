import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import { useState } from "react";

import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";

const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Feedback = () => {
  const [projname, setProjName] = useState("");
  const [name, setName] = useState("");

  const [subj, setSubj] = useState("");
  const [msg, setMsg] = useState("");

  const [err, setErr] = useState(0);
  const [disablebutton, setDisablebutton] = useState(false);

  const [snackBar, setSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("success");
  const [snackBarType, setSnackBarType] = useState("Invalid");

  const openSnkbar = (type, msg) => {
    setSnackBarMsg(msg);
    setSnackBarType(type);
    setSnackBar(true);
  };

  const onSubmit = () => {
    setErr(0);
    if (projname == "") {
      setErr(1);
      openSnkbar("error", "Please Enter Your Project / Institute Name....");
    } else if (name == "") {
      setErr(2);
      openSnkbar("error", "Please Enter Your name with Designation....");
    } else if (subj == "") {
      setErr(4);
      openSnkbar("error", "Please Enter Your Subject....");
    } else if (msg == "") {
      setErr(5);
      openSnkbar("error", "Please Enter Your Message....");
    } else {
      openSnkbar("warning", "Please wait.....");
      setDisablebutton(true);
      const formData = new FormData();
      formData.append("name", projname);
      formData.append("profname", name);

      formData.append("sub", subj);
      formData.append("msg", msg);
      Axios.post("/api/addtest", formData, {
        headers: { headKey: headKey },
      }).then((res) => {
        if (res.data.status) {
          setDisablebutton(false);
          openSnkbar("success", res.data.msg);
        } else {
          setDisablebutton(false);
          openSnkbar("error", "Something went wrong... try later.");
        }
      });
    }
  };

  return (
    <Container maxWidth="sm">
      <Box boxShadow={3} p={3} m={4} style={{ border: "2px solid #333333" }}>
        <Snackbar
          open={snackBar}
          autoHideDuration={4000}
          onClose={() => {
            setSnackBar(false);
          }}
        >
          <Alert
            onClose={() => {
              setSnackBar(false);
            }}
            severity={snackBarType}
            style={{
              color: snackBarType === "warning" ? "black" : "white",
            }}
          >
            {snackBarMsg}
          </Alert>
        </Snackbar>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h2
              style={{
                textDecoration: "underline",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Give your Feedback
            </h2>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="filled-required"
              label="Project / Institute Name"
              variant="filled"
              error={err === 1 && true}
              value={projname}
              size="small"
              onChange={(e) => {
                setProjName(e.target.value);
              }}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="filled-required"
              label="Your Name with Designation"
              variant="filled"
              error={err === 2 && true}
              value={name}
              size="small"
              onChange={(e) => {
                setName(e.target.value);
              }}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="filled-required"
              label="Subject"
              variant="filled"
              error={err === 4 && true}
              value={subj}
              size="small"
              onChange={(e) => {
                setSubj(e.target.value);
              }}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="filled-required"
              label="Message"
              variant="filled"
              error={err === 5 && true}
              value={msg}
              size="small"
              multiline
              rows={4}
              onChange={(e) => {
                setMsg(e.target.value);
              }}
              required
              fullWidth
            />
          </Grid>
          <br />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              disabled={disablebutton}
            >
              {disablebutton ? "Please wait.." : "Submit Feedback"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Snackbar,
  Switch,
  MenuItem,
} from "@material-ui/core";
import { useState } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import CKEditor from "react-ckeditor-component";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let greenColor = {
  color: "green",
  fontWeight: "bold",
};

let blackColor = {
  color: "black",
};
export const AddClient = () => {
  const [name, setname] = useState("");
  const [url, setUrl] = useState("");
  const [clientPic, setClientPic] = useState({ raw: "" });
  const [priority, setPriority] = useState();
  const [status, setStatus] = useState(true);
  const [err, setErr] = useState(0);
  const [disablebutton, setDisablebutton] = useState(false);
  const [content, setContent] = useState("");
  const [snackBar, setSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("success");
  const [snackBarType, setSnackBarType] = useState("Invalid");
  const navigate = useNavigate();
  console.log("addclient");
  const openSnkbar = (type, msg) => {
    setSnackBarMsg(msg);
    setSnackBarType(type);
    setSnackBar(true);
  };

  const handleChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
  };

  const onSubmit = () => {
    setErr(0);
    if (url == "") {
      setErr(1);
      openSnkbar("error", "Please Enter Url....");
    } else if (priority == "") {
      setErr(2);
      openSnkbar("error", "Please Select Priority....");
    } else if (clientPic.raw == "") {
      setErr(3);
      openSnkbar("error", "Please Select a Client Image....");
    }else if (name == "") {
      setErr(4);
      openSnkbar("error", "Please Enter Client name....");
    } else {
      openSnkbar("warning", "Please wait.....");
      setDisablebutton(true);
      console.log(priority);
      console.log(status);
      console.log(clientPic.raw);
      const formData = new FormData();
      if (clientPic.raw !== "") {
        formData.append("imgurl", clientPic.raw);
      }
      formData.append("siteurl", url);
      formData.append("priority", priority);
      formData.append("name",name)
      if (status) {
        formData.append("status", "1");
      } else {
        formData.append("status", "0");
      }
      Axios.post("/api/add_client", formData, {
        headers: { headKey: headKey },
      }).then((res) => {
        // console.log(res.data,"Chethan")
        if (res.data.status) {
          setDisablebutton(false);
          openSnkbar("success", res.data.msg);
          navigate("/dash/allclients");
        } else {
          setDisablebutton(false);
          openSnkbar("error", "Something went wrong... try later.");
        }
      });
    }
  };
  return (
    <Container>
      <Helmet>
        <title>Add Client - Admin | Arawinz Soft Solutions</title>
        <meta
          name="description"
          content="Arawinz Soft Solutions is one of the finest Software Development company. We provide services in Web Development-Full Stack, Web Designing (Static & Responsive), Mobile App Development, Social Media Marketing, SEO, Data Analytics,  CRM, ERP, Mobile Gaming and Artificial Intelligence."
        />
        <meta
          name="keywords"
          content=" Testimonials Top Web Development company in Gachibowli, SEO companies in Hyderabad, Best Application development company in India, Top 5 development companies in Vizag, Digital and Social Media Marketing, Data Analytics company in Hitech city, Graphics Design and Gaming company in Jubilee hills, Best Artificial Intelligence company in Hyderabad, Top gaming organization near me, Available ERP &amp; CRM developers Madhapur"
        ></meta>
      </Helmet>
      <Box boxShadow={3} p={3} m={4} style={{ border: "2px solid #333333" }}>
        <Snackbar
          open={snackBar}
          autoHideDuration={4000}
          onClose={() => {
            setSnackBar(false);
          }}
        >
          <Alert
            onClose={() => {
              setSnackBar(false);
            }}
            severity={snackBarType}
            style={{
              color: snackBarType === "warning" ? "black" : "white",
            }}
          >
            {snackBarMsg}
          </Alert>
        </Snackbar>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h2
              style={{
                textDecoration: "underline",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Add Client
            </h2>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <CKEditor
              activeClass="p10"
              content={content}
              config={{
                toolbar: [
                  {
                    name: "document",
                    items: [
                      "Source",
                      "-",
                      "NewPage",
                      "Preview",
                      "-",
                      "Templates",
                    ],
                  },
                  {
                    name: "clipboard",
                    items: [
                      "Cut",
                      "Copy",
                      "Paste",
                      "PasteText",
                      "PasteFromWord",
                      "-",
                      "Undo",
                      "Redo",
                    ],
                  },
                  {
                    name: "styles",
                    items: ["Styles", "Format", "Font", "FontSize"],
                  },
                  {
                    name: "basicstyles",
                    items: [
                      "Bold",
                      "underline",
                      "Italic",
                      "Strike",
                      "Subscript",
                      "Superscript",
                      "-",
                      "RemoveFormat",
                    ],
                  },
                  {
                    name: "colors",
                    items: ["fontColor", "fontBackgroundColor"],
                  },
                  { name: "links", items: ["Link", "Unlink", "Anchor"] },
                  {
                    name: "insert",
                    items: ["Image", "Table", "HorizontalRule", "SpecialChar"],
                  },
                  { name: "tools", items: ["Maximize"] },
                  { name: "others", items: ["-"] },
                  { name: "about", items: ["About"] },
                ],
                height: 300,
                skin: "moono-lisa",
                // extraPlugins: 'colorbutton,colordialog',
                //   removeButtons: 'PasteFromWord'
              }}
              events={{
                change: handleChange,
              }}
            /> 
          </Grid>*/}
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="filled-required"
              label="Url"
              variant="filled"
              error={err === 1 && true}
              value={url}
              size="small"
              onChange={(e) => {
                setUrl(e.target.value);
              }}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              select
              id="filled-required"
              label="Priority"
              variant="filled"
              error={err === 2 && true}
              value={priority}
              size="small"
              onChange={(e) => {
                setPriority(e.target.value);
              }}
              required
              fullWidth
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Switch
              checked={status}
              onChange={() => {
                setStatus(!status);
              }}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            ></Switch>
            Status - <span style={status ? greenColor : blackColor}>Show</span>{" "}
            / <span style={!status ? greenColor : blackColor}>Hide</span>
          </Grid>
          <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
            {clientPic.raw != "" && (
              <img width="250px" height="250px" src={clientPic.preview} />
            )}
            <br />
            <br />

            <input
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => {
                if (e.target.files.length != 0) {
                  var type = e.target.files[0].type;

                  if (
                    type === "image/jpeg" ||
                    type === "image/jpg" ||
                    type === "image/png"
                  ) {
                    setClientPic({
                      preview: URL.createObjectURL(e.target.files[0]),
                      raw: e.target.files[0],
                    });
                  } else {
                    alert("Please select only JPEG, JPG, PNG");
                  }
                  console.log(clientPic)
                }
              }}
              id="contained-button-file"
              type="file"
            />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                Upload Image
              </Button>
            </label>
            {err === 3 && (
              <div style={{ color: "red" }}>Please select a file.</div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="filled-required"
              label="Client Name"
              variant="filled"
              error={err === 4 && true}
              value={name}
              size="small"
              onChange={(e) => {
                setname(e.target.value);
              }}
              required
              fullWidth
            />
          </Grid>
          <br />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              disabled={disablebutton}
            >
              {disablebutton ? "Please wait.." : "Add Client"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

import {
    Container,
    Grid,
    Box,
    TextField,
    Button,
    Snackbar,
    Switch,
  } from "@material-ui/core";
  import { useState, useEffect } from "react";
  import TextareaAutosize from "@material-ui/core/TextareaAutosize";
  import MuiAlert from "@material-ui/lab/Alert";
  import Axios from "axios";
  import { useNavigate } from "react-router-dom";
  import { Loading } from "./loading";
  import { Helmet } from "react-helmet";
  import { useParams } from "react-router-dom";
  const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  let greenColor = {
    color: "green",
    fontWeight: "bold",
  };
  
  let blackColor = {
    color: "black",
  };
  export const UpdateBanner = () => {
    let { id } = useParams();
    const [isloading, setIsLoading] = useState(true);
    const [subtitle, setSubtitle] = useState("");
    const [banner, setBanner] = useState({ raw: "" });
    const [status, setStatus] = useState(true);
    const [oldImage, setOldImage] = useState("");
    const [err, setErr] = useState(0);
    const [disablebutton, setDisablebutton] = useState(false);
  
    const [snackBar, setSnackBar] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState("success");
    const [snackBarType, setSnackBarType] = useState("Invalid");
    const navigate=useNavigate()
    const openSnkbar = (type, msg) => {
      setSnackBarMsg(msg);
      setSnackBarType(type);
      setSnackBar(true);
    };
    useEffect(() => {
      getData();
    }, []);
    const getData = () => {
      const formData = new FormData();
      formData.append("idd", id);
      Axios.post("/api/select_single_banner", formData, {
        headers: { headKey: headKey },
      }).then((res) => {
        if (res.data.status) {
          
          setSubtitle(res.data.data.sub_title);
          if (res.data.data.banner_url !== null) {
            setOldImage(res.data.data.banner_url);
          }
          if (res.data.data.serv_status === 1) {
            setStatus(true);
          } else {
            setStatus(false);
          }
          setIsLoading(false);
        }
      });
    };
    const onSubmit = () => {
      setErr(0);
      if (subtitle == "") {
        setErr(1);
        openSnkbar("error", "Please Enter Employee subtitle....");
      } else if (banner.raw == "" && oldImage == "") {
        setErr(2);
        openSnkbar("error", "Please Select a Employee Image....");
      } else {
        openSnkbar("warning", "Please wait.....");
        setDisablebutton(true);
        const formData = new FormData();
        if (banner.raw !== "") {
          formData.append("bannerurl", banner.raw);
        }
        formData.append("idd", id);
        formData.append("subtitle", subtitle);
        formData.append("oldimage", oldImage);
        if (status) {
          formData.append("status", "1");
        } else {
          formData.append("status", "0");
        }
        Axios.post("/api/update_banner", formData, {
          headers: { headKey: headKey },
        }).then((res) => {
          if (res.data.status) {
            setDisablebutton(false);
            setBanner({ raw: "" });
            openSnkbar("success", res.data.msg);
            setIsLoading(true);
            navigate("/dash/banners")
          } else {
            setDisablebutton(false);
            openSnkbar("error", "Something went wrong... try later.");
          }
        });
      }
    };
    return (
      <Container>
        <Helmet>
          <title>Edit Service - Admin | Arawinz Soft Solutions</title>
          <meta
            name="description"
            content="Arawinz Soft Solutions is one of the finest Software Development company. We provide services in Web Development-Full Stack, Web Designing (Static & Responsive), Mobile App Development, Social Media Marketing, SEO, Data Analytics,  CRM, ERP, Mobile Gaming and Artificial Intelligence."
          />
          <meta
            name="keywords"
            content=" Testimonials Top Web Development company in Gachibowli, SEO companies in Hyderabad, Best Application development company in India, Top 5 development companies in Vizag, Digital and Social Media Marketing, Data Analytics company in Hitech city, Graphics Design and Gaming company in Jubilee hills, Best Artificial Intelligence company in Hyderabad, Top gaming organization near me, Available ERP &amp; CRM developers Madhapur"
          ></meta>
        </Helmet>
        {isloading ? (
          <Loading />
        ) : (
          <Box boxShadow={3} p={3} m={4} style={{ border: "2px solid #333333" }}>
            <Snackbar
              open={snackBar}
              autoHideDuration={4000}
              onClose={() => {
                setSnackBar(false);
              }}
            >
              <Alert
                onClose={() => {
                  setSnackBar(false);
                }}
                severity={snackBarType}
                style={{
                  color: snackBarType === "warning" ? "black" : "white",
                }}
              >
                {snackBarMsg}
              </Alert>
            </Snackbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={4}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <h2
                  style={{
                    textDecoration: "underline",
                    marginBottom: "0px",
                    textAlign: "center",
                  }}
                >
                  Edit Banner
                </h2>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="filled-required"
                  label="subtitle"
                  variant="filled"
                  error={err === 1 && true}
                  value={subtitle}
                  size="small"
                  onChange={(e) => {
                    setSubtitle(e.target.value);
                  }}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Switch
                  checked={status}
                  onChange={() => {
                    setStatus(!status);
                  }}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                ></Switch>
                Status -{" "}
                <span style={status ? greenColor : blackColor}>Show</span> /{" "}
                <span style={!status ? greenColor : blackColor}>Hide</span>
              </Grid>
              <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                {oldImage != "" && (
                  <img
                    width="250px"
                    height="250px"
                    src={"/static/banners/" + oldImage}
                  />
                )}
                {banner.raw != "" && (
                  <img
                    width="250px"
                    height="250px"
                    src={banner.preview}
                    style={{ padding: "4%" }}
                  />
                )}
                <br />
                <br />
  
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    if (e.target.files.length != 0) {
                      var type = e.target.files[0].type;
  
                      if (
                        type === "image/jpeg" ||
                        type === "image/jpg" ||
                        type === "image/png"
                      ) {
                        setBanner({
                          preview: URL.createObjectURL(e.target.files[0]),
                          raw: e.target.files[0],
                        });
                      } else {
                        alert("Please select only JPEG, JPG, PNG");
                      }
                    }
                  }}
                  id="contained-button-file"
                  type="file"
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload Image
                  </Button>
                </label>
                {err === 2 && (
                  <div style={{ color: "red" }}>Please select a file.</div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button
                  onClick={onSubmit}
                  variant="contained"
                  color="primary"
                  disabled={disablebutton}
                >
                  {disablebutton ? "Please wait.." : "Update Banner"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    );
  };
  
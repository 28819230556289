import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  useTheme,
  Slide,
  Typography,
  Paper,
  Tabs,
  Tab,
  makeStyles,
} from "@material-ui/core";
import { useState, useEffect } from "react";

import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import { Helmet } from "react-helmet-async";
import { Loading } from "./loading";
import playstore from "../images/google-play logo.png";
import appstore from "../images/app store logo.png";
import web from "../images/web logo.png";
import LanguageIcon from "@material-ui/icons/Language";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";

const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";

const useStyles = makeStyles({
  cardshadow: {
    "&:hover": {
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    },
  },
});

export const Products = () => {
  const theme = useTheme();

  const classes = useStyles();
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [isloading, setIsloading] = useState(true);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = () => {
    const anchor = document.querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    getMetaData();
  }, []);

  const getMetaData = () => {
    const formData = new FormData();
    formData.append("page", 3);
    Axios.post("/api/getmetadata", formData, {
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.data.status) {
        setMetaTitle(res.data.data.meta_title);
        setMetaKeywords(res.data.data.meta_keywords);
        setMetaDesc(res.data.data.meta_desc);
        setIsloading(false);
        handleClick();
      }
    });
  };

  const jumbotroncss = {
    backgroundImage:
      'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url("/static/products.jpg")',
    opacity: 0.8,
    backgroundAttachment: "fixed",
    color: "white",
    minHeight: "250px",
    //marginTop: "0px",
    marginBottom: "0px",
    borderRadius:"0px"
  };

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
        <meta name="keywords" content={metaKeywords}></meta>
      </Helmet>
      <div className="jumbotron text-center" style={jumbotroncss}>
        <Paper style={{ opacity: "0.8" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h2"
              style={{
                color: theme.palette.cream.main,
                fontWeight: 500,
                WebkitTextStrokeWidth: 2,
                letterSpacing: 8,
                WebkitTextStrokeColor: theme.palette.primary.main,
              }}
            >
              Our
            </Typography>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Typography
              variant="h2"
              style={{
                color: theme.palette.primary.main,
                fontWeight: 500,
                letterSpacing: 8,
              }}
            >
              Products
            </Typography>
          </Grid>
          <Typography variant="h6" style={{color:theme.palette.primary.main,letterSpacing:1
          }}>
            Explore Our Innovative Solutions
          </Typography>
          <br />
        </Paper>
      </div>
      {isloading ? (
        <Loading />
      ) : (
        <Box
        boxShadow={3}
        p={3}
       
        style={{ backgroundColor: theme.palette.cream.main }}
        className={classes.cardshadow}
      >
        <Container maxWidth="lg">
        <br/>
          <Slide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              {...(true ? { timeout: 1000 } : {})}
            >
              <div class="two">
                <h1 style={{color:"#4c004c"}}>Showcasing Innovation: Our Product Portfolio
                  <span>Dive into Our Array of Revolutionary IT Products and Solutions</span>
                </h1>
              </div>
 
            </Slide>
            <br />
            <br />
            <br />
            
          <Slide
            direction="up"
            in={true}
            mountOnEnter
            unmountOnExit
            {...(true ? { timeout: 1000 } : {})}
          >
           
              <div className="article_desc">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="ESAY" {...a11yProps(0)} className="article_desc"/>
                <Tab label="ARAFACEFINDER" {...a11yProps(1)} className="article_desc"/>
                <Tab label="AttireQ" {...a11yProps(2)} className="article_desc"/>
              </Tabs>
              <br />
              <div role="tabpanel" hidden={value !== 0}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="stretch"
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                        <Typography
                          variant="h4"
                          style={{
                            color: theme.palette.secondary.main,
                            letterSpacing: 2,
                          }}
                        >
                          <b>ESAY</b> - <b style={{ fontSize: 45 }}>E</b>
                          ngineering <b style={{ fontSize: 45 }}>S</b>ources{" "}
                          <b style={{ fontSize: 45 }}>A</b>round{" "}
                          <b style={{ fontSize: 45 }}>Y</b>ou
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        className="text-center"
                      >
                        <img
                          src="/static/products/Esay.png"
                          style={{ width: "40%", height: "40%", padding: "2%" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      "Engineering Sources Around You" (ESAY) offers an ideal platform for anyone interested in entering the construction industry, simplifying their journey significantly. It serves as a hub where users can find contact details for a variety of service providers, suppliers, engineers, and labor forces. Additionally, it provides crucial information needed by professionals in the construction field.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <h6
                      className="display1"
                      style={{ color: theme.palette.secondary.main }}
                    >
                      {" "}
                      <Typography variant="body1" gutterBottom>
                        <b>Available in:</b>
                      </Typography>
                    </h6>
                    <Grid item xs={12}>
                      <span style={{ padding: "2%" }}>
                        <a href="https://esay.in" target="_blank">
                          {/* <LanguageIcon /> */}
                          <img src="/static/products/web_logo.png" height="7%" width="7%"/>
                          {/* <img src={web} height="7%" width="7%" /> */}
                        </a>
                      </span>
                      <span style={{ padding: "2%" }}>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.esay.arawinzsoft"
                          target="_blank"
                        >
                          <img src="/static/products/google_play.png" height="13%" width="13%" />
                          {/* <AndroidIcon style={{ color: "green" }} /> */}
                        </a>
                      </span>
                      <span style={{ padding: "2%" }}>
                        <a
                          href="https://apps.apple.com/us/app/esay/id1490576735"
                          target="_blank"
                        >
                          <img src="/static/products/app_store.png" height="11%" width="11%" />
                          {/* <AppleIcon color="primary" /> */}
                        </a>
                      </span>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>
                      <span style={{ color: theme.palette.secondary.main }}>
                        <b>Our Services:</b>
                      </span>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                        <b>Contacts:</b>
                      </span>
                      &nbsp;&nbsp;ESAY offers access to a wide range of service providers, suppliers, engineers, and workforce contacts.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                        <b>Engineering Info:</b>
                      </span>
                      &nbsp;&nbsp;The Engineering Info Section offers comprehensive information beneficial for Consultants, Builders, Contractors, Engineers, and Students aiming to join the construction industry. This information encapsulates the core knowledge from four years of engineering study, proving valuable for individuals in both government and private sectors. The content in the Engineering Info section is sourced from a team of experienced engineers, boasting over 35 years of field experience in both governmental and private sectors, and is regularly updated to ensure relevance and accuracy.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>
                      <span
                        style={{
                          color: theme.palette.secondary.main,
                          textAlign: "left",
                          
                        }}
                      >
                        <b>Trends:</b>
                      </span>
                      &nbsp;&nbsp;Information about the most recent practices and trends in the construction industry is accessible.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                        <b>Conversions:</b>
                      </span>
                      &nbsp;&nbsp;ESAY offers a conversion calculator that covers various units including length, area, weight, volume, weight per unit length, stress, velocity, discharge, moment, power, pressure, and temperature. This tool is beneficial for both field engineers and engineering students.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                        <b>Careers:</b>
                      </span>
                      &nbsp;&nbsp;ESAY offers employment prospects for engineers, catering to both fresh graduates and experienced professionals in civil, mechanical, and electrical fields.
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div role="tabpanel" hidden={value !== 1}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="stretch"
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                        <img
                          src="/static/products/AraFaceFinder_title.png"
                          style={{ width: "40%", height: "40%", padding: "2%" }}
                        />
                        {/* <Typography
                          variant="h4"
                          style={{ color: theme.palette.secondary.main }}
                        >
                          EYEDENTT
                        </Typography> */}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        className="text-center"
                      >
                        <img
                          src="/static/products/arafacefinder_logo.png"
                          style={{ width: "40%", height: "40%", padding: "2%" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}></Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      component="p"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      ArafaceFinder, developed by Arawinz Soft Solutions Pvt Ltd, is an intelligent attendance application equipped with facial recognition capabilities. Its versatility makes it suitable for a wide range of industries, including Factories, IT Companies, Mines, Schools, Hotels, Restaurants, and Colleges..
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}>
                      <span style={{ color: theme.palette.secondary.main }}>
                        <b>Let's take a closer look at how the application operates:</b>
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}>
                      <span style={{ color: theme.palette.secondary.main }}>
                        <b>Organization Dashboard:</b>
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                        
                      </span>
                      1. To begin using the application, organizations can sign up through our platform.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                      </span>
                      2. Within the Organization's Dashboard, they have the ability to create a list of available locations where the application will be utilized.

                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                      </span>
                      For instance, a restaurant may be available in four different locations, while a school or college might have three campuses.

                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                     
                      </span>
                     3. The dashboard empowers organizations to define various user roles within their hierarchy.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                      </span>
                      For example, these roles may entail permissions such as adding/editing users, managing locations, configuring shifts, monitoring the attendance system, and reviewing reports on users, shifts, and attendance.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                        
                      </span>
                      4. Additionally, organizations can establish different types of shifts for their employees.

                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                        
                      </span>
                      For instance, they can set shift types as regular or contract, specify contract durations (from date to date), and designate working days of the week for each shift type.

                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                        
                      </span>
                      5. Users can be seamlessly added to the application, with each user being assigned a specific user role as defined in Step 3.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                      </span>
                      6. Subsequently, shifts will be assigned to the users based on their designated location and shift type.

                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                      </span>
                      Users will be assigned to specific locations and shifts based on the information provided during their creation in the system.

                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                        <b>Monitoring Application:</b>
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                     
                      </span>
                      This system application facilitates attendance marking at designated locations. The recorded attendance data is then transmitted either to the Local instance or the cloud server, depending on the chosen version (Online or Offline).

                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                      
                      </span>
                      The monitoring application fetches the list of users assigned for the particular day, ensuring accurate and efficient attendance tracking.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                        <b>The application comes in two versions: </b>
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                        <b>1. Online Version:</b>
                      </span>
                      &nbsp;&nbsp;In this edition, the Organization's Dashboard accesses cloud storage to store data.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                        <b>2. Offline Version:</b>
                      </span>
                      &nbsp;&nbsp;For this variant, the Organization's Dashboard is deployed on the organization's server, allowing monitoring applications to retrieve user data from the locally deployed instance on the same day.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                      
                      </span>
                      Both versions offer effective and reliable attendance management solutions for organizations of diverse sizes and industries.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                        <b>Check out our website for further information:</b>
                      </span>&nbsp;&nbsp;
                      <a href="https://www.arafacefinder.com" target="_blank">
                      www.arafacefinder.com
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div role="tabpanel" hidden={value !== 2}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="stretch"
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{
                        color: theme.palette.secondary.main,
                        letterSpacing: 2,
                      }}
                    >
                      <b style={{ fontSize: 45 }}>A</b>ttire
                      <b style={{ fontSize: 45 }}>Q</b>
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      A distinctive fashion platform offering clothing and accessories for women, men, and children.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "left", color: "#000000" }}
                    >
                      <span style={{ color: theme.palette.secondary.main }}>
                        <b>Status:</b>
                      </span>
                      &nbsp;&nbsp;Development on this portal has recently commenced and it will soon be accessible on the Web, Android, and iOS platforms.
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              </div>
            
          </Slide>
          <Slide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              {...(true ? { timeout: 1000 } : {})}
            >
          <div >
            <br/>
            <br/>
            <br/>
            <blockquote className="article_desc">
            At Arawinz, our mission is to closely align with industry needs and develop products tailored to these requirements. An example of this approach is ESAY, a solution specifically designed for the construction industry. Following ESAY, we've recently introduced AraFaceFinder, a unique and easily customizable facial recognition application for smart attendance tracking.
              <span>-&nbsp;Chethan. P. S. S, Managing Director and Chief Architect</span>
            </blockquote>
            <br/>
            <br/>
          </div>
          </Slide>
        </Container>
        </Box>
      )}
      
    </div>
  );
};

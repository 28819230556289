import { Button,Box } from "@material-ui/core";
import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { Loading } from "./loading";

import { UserContext } from "./UserContext";
import { Helmet } from "react-helmet";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";

const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";
const $ = require("jquery");
export const AllBanners=()=>{
    let navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    useEffect(() => {
        getData();
      }, []);
    
      const getData = () => {
        Axios.get("/api/select_banner", {
          headers: { headKey: headKey },
        }).then((res) => {
          if (res.status === 200) {
            setData(res.data.data);
            setLoading(false);
            $(document).ready(function () {
              $("#table_id").DataTable();
            });
          } else {
            setUser(null);
    
            navigate("/");
          }
        });
      };
      const deActivate = (id) => {
        console.log(id)
        const formdata = new FormData();
        formdata.append("idd", id);
        Axios.post("/api/banner_status", formdata,{
          headers: { headKey: headKey },
        }).then((res) => {
          if (res.status === 200) {
            console.log(res.data.status);
            getData()
          } else {
            console.log("failed");
          }
        });
      };
      return (
        <div>
          {loading ? (
            <Loading />
          ) : (
            <div>
              <Helmet>
                <title>All Services - Admin | Arawinz Soft Solutions</title>
                <meta
                  name="description"
                  content="Arawinz Soft Solutions is one of the finest Software Development company. We provide services in Web Development-Full Stack, Web Designing (Static & Responsive), Mobile App Development, Social Media Marketing, SEO, Data Analytics,  CRM, ERP, Mobile Gaming and Artificial Intelligence."
                />
                <meta
                  name="keywords"
                  content=" Testimonials Top Web Development company in Gachibowli, SEO companies in Hyderabad, Best Application development company in India, Top 5 development companies in Vizag, Digital and Social Media Marketing, Data Analytics company in Hitech city, Graphics Design and Gaming company in Jubilee hills, Best Artificial Intelligence company in Hyderabad, Top gaming organization near me, Available ERP &amp; CRM developers Madhapur"
                ></meta>
              </Helmet>
              <div style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  component={Link}
                  color="primary"
                  to={"/dash/addbanner"}
                >
                  Add Banner
                </Button>
              </div>
              <br />
              <Box sx={{ width: "95%" }}>
              <table id="table_id" className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>SUBTITLE</th>
                    <th >IMAGE</th>
                     <th>STATUS</th>
                    <th>ADDED DATE</th>
                    <th>EDIT</th>
                    <th>DEACTIVATE</th>
                  </tr>
                </thead>
                <tbody>
                      {data?.length >0&&(data||[]).map((da) => {
                    return (
                      <tr>
                        <td>{da.id}</td>
                        <td>{da.sub_title}</td>
                        <td>
                          <img
                            src={"/static/banners/" + da.banner_url}
                            style={{ height: "150px" }}
                          />
                        </td>
                        <td>{da.status === 1 ? "Active" : "InActive"}</td>
                        <td>
                          {moment(
                            new Date(da.added_date + "0530 (India Standart Time)")
                          ).format("DD-MM-YYYY ")}
                        </td>
                        <td>
                          <Button
                            variant="contained"
                            component={Link}
                            color="primary"
                            to={"/dash/updatebanner/" + da.id}
                            fullWidth
                          >
                            EDIT
                          </Button>
                        </td>
                        <td>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => {
                            console.log(da.id)
                            deActivate(da.id)}}
                        >
                           {da.status === 1 ? "DEACTIVATE" : "ACTIVATE"}
                        </Button>
                      </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <th>ID</th>
                    <th>SUBTITLE</th>
                    <th>IMAGE</th>
                    <th>STATUS</th>
                    <th>ADDED DATE</th>
                    <th>EDIT</th>
                    <th>DEACTIVATE</th>
                  </tr>
                </tfoot>
              </table></Box>
            </div>
          )}
        </div>
      );
}
import { useState, useContext } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
  useTheme,
} from "@material-ui/core";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

import { UserContext } from "./UserContext";

import MuiAlert from "@material-ui/lab/Alert";

const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Login = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [disableButton, setDisableButton] = useState(false);
  const [uname, setUname] = useState("");
  const [pass, setPass] = useState("");
  const [otp, setOtp] = useState("");
  const [err, setErr] = useState(0);

  const [snackBar, setSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("success");
  const [snackBarType, setSnackBarType] = useState("Invalid");

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const openSnkbar = (type, msg) => {
    setSnackBarMsg(msg);
    setSnackBarType(type);
    setSnackBar(true);
  };

  const login = (event) => {
    event.preventDefault();
    if (uname === "") {
      openSnkbar("error", "Please Enter Admin Username.");
      setErr(1);
    } else if (pass === "") {
      openSnkbar("error", "Please Enter Admin Password.");
      setErr(2);
    } else {
      setDisableButton(true);
      setErr(0);
      const formData = new FormData();
      formData.append("uname", uname);
      formData.append("pass", pass);
      openSnkbar("warning", "Logging in, Please waiting....");
      Axios.post("/api/addlogin", formData, {
        headers: { headKey: headKey },
      }).then((res) => {
        if (res.data.status) {
          openSnkbar("success", res.data.msg);
          setDisableButton(false);
          setOpen(true);
        } else {
          setDisableButton(false);
          openSnkbar("error", res.data.msg);
        }
      });
    }
  };

  const submitOtp = () => {
    if (otp === "") {
      openSnkbar("error", "Please Enter OTP.");
      setErr(3);
    } else {
      setErr(0);
      openSnkbar("warning", "Validating OTP, Please waiting....");
      const formData = new FormData();
      formData.append("uname", uname);
      formData.append("pass", pass);
      formData.append("aotp", otp);
      Axios.post("/api/addlogin", formData, {
        headers: { headKey: headKey },
      }).then((res) => {
        if (res.data.status) {
          setOpen(false);
          setUser(res.data.userdata);

          openSnkbar("success", res.data.msg);
          navigate("/dash");
        } else {
          openSnkbar("error", res.data.msg);
        }
      });
    }
  };

  return (
    <Container maxWidth="sm" style={{ height: "70vh" }}>
      <Box boxShadow={3} p={3} m={4} style={{ border: "2px solid #333333" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
        >
          <Snackbar
            open={snackBar}
            autoHideDuration={4000}
            onClose={() => {
              setSnackBar(false);
            }}
          >
            <Alert
              onClose={() => {
                setSnackBar(false);
              }}
              severity={snackBarType}
              style={{
                color: snackBarType === "warning" ? "black" : "white",
              }}
            >
              {snackBarMsg}
            </Alert>
          </Snackbar>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Enter OTP"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please Enter One Time Password
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <TextField
                id="outlined-basic"
                label="OTP"
                variant="outlined"
                error={err === 3 && true}
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
                required
                fullWidth
              />
              <br /> <br />
              <Button onClick={submitOtp} color="primary" autoFocus>
                SUBMIT
              </Button>
            </DialogActions>
          </Dialog>
          
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h2
              style={{
                textDecoration: "underline",
                marginBottom: "0px",
                textAlign: "center",
                color: theme.palette.primary.main,
              }}
            >
              ADMIN LOGIN
            </h2>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <form onSubmit={login}>
              <TextField
                id="outlined-basic"
                label="Username"
                variant="outlined"
                error={err === 1 && true}
                value={uname}
                onChange={(e) => {
                  setUname(e.target.value);
                }}
                required
                fullWidth
              />
              <br /> <br />
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                type="password"
                error={err === 2 && true}
                value={pass}
                onChange={(e) => {
                  setPass(e.target.value);
                }}
                required
                fullWidth
              />
              <br /> <br />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={disableButton}
                alignItems="center"
              >
                LOGIN
              </Button>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

import {Container,Grid,Box,TextField,Button,Snackbar,Select,FormControl,InputLabel,MenuItem} from '@material-ui/core';
import {useState,useEffect } from 'react';

import MuiAlert from '@material-ui/lab/Alert';
import Axios from 'axios';
import {Loading} from './loading';
import {Helmet} from "react-helmet";
import {useParams} from 'react-router-dom';

const headKey="qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let greenColor={
    color:"green",
    fontWeight: 'bold'
   };
  
   let blackColor={
    color:"black"
   };

export const UpdateMetaData =()=>{

    let {id} = useParams();

    const [isloading, setIsLoading] = useState(true);

   
    const [title,setTitle] = useState("");
    const [keywords,setKeywords] = useState("");
    const [metadesc,setMetaDesc] = useState("");
    const [page,setPage] = useState("");

    const [err,setErr] = useState(0);
    const [disablebutton,setDisablebutton] = useState(false);

    const [snackBar, setSnackBar] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState("success");
    const [snackBarType, setSnackBarType] = useState("Invalid");

    const openSnkbar = (type, msg) => {
        setSnackBarMsg(msg);
        setSnackBarType(type);
        setSnackBar(true);
      };


      useEffect(()=>{
        getData();
     },[]);

     const getData = ()=>{
        const formData = new FormData();
        formData.append('idd',id);
        Axios.post("/api/getmetadataadmin", formData,{   
           headers: {headKey:headKey},
        }).then((res) => {
        if(res.data.status){
            
            

           setTitle(res.data.data.meta_title);
           setKeywords(res.data.data.meta_keywords);
           setMetaDesc(res.data.data.meta_desc);
           setPage(res.data.data.meta_page);
            
           setIsLoading(false);
        } 
        });  
    }


    const onSubmit = () =>{
        setErr(0);
        if(title==''){
            setErr(4);
            openSnkbar('error','Please Enter Meta Title....');
        }else if(keywords==''){
            setErr(5);
            openSnkbar('error','Please Enter Meta Keywords....');
        }else if(metadesc==''){
            setErr(6);
            openSnkbar('error','Please Enter Meta Description....');
        }else if(page==''){
            setErr(7);
            openSnkbar('error','Please Select for Page....');
        }else{
            openSnkbar("warning","Please wait.....");
            setDisablebutton(true);
            const formData=new FormData();
             
            formData.append('metaid',id);
            formData.append('metapage',page);
             
            formData.append('metatitle',title);
            
            formData.append('metakey',keywords);
            formData.append('metadesc',metadesc);
             
            Axios.post("/api/updatemetadata", formData,{   
                    headers: {headKey:headKey},
                }).then((res) => {
                if(res.data.status){
                    setDisablebutton(false);
                    
                    openSnkbar("success", res.data.msg);
                    setIsLoading(true);
                    getData();
                }else{
                    setDisablebutton(false);
                    openSnkbar("error", "Something went wrong... try later.");
                }
            });
        }
    }  

    return ( <Container   
    >
      <Helmet>
                    <title>Edit Metadata - Admin | Arawinz Soft Solutions</title>
                    <meta name="description" content="Arawinz Soft Solutions is one of the finest Software Development company. We provide services in Web Development-Full Stack, Web Designing (Static & Responsive), Mobile App Development, Social Media Marketing, SEO, Data Analytics,  CRM, ERP, Mobile Gaming and Artificial Intelligence." />
                    <meta name="keywords" content=" Testimonials Top Web Development company in Gachibowli, SEO companies in Hyderabad, Best Application development company in India, Top 5 development companies in Vizag, Digital and Social Media Marketing, Data Analytics company in Hitech city, Graphics Design and Gaming company in Jubilee hills, Best Artificial Intelligence company in Hyderabad, Top gaming organization near me, Available ERP &amp; CRM developers Madhapur"></meta>
                </Helmet>
        {isloading?(<Loading />):(
       <Box  boxShadow={3} p={3} m={4} style={{border:"2px solid #333333" }}>
       <Snackbar
         open={snackBar}
         autoHideDuration={4000}
         onClose={() => {
           setSnackBar(false);
         }}
       >
         <Alert
           onClose={() => {
             setSnackBar(false);
           }}
           severity={snackBarType}
           style={{
             color: snackBarType === "warning" ? "black" : "white",
           }}
         >
           {snackBarMsg}
         </Alert>
       </Snackbar>
           <Grid
           container
           direction="row"
           justifyContent="space-between"
           alignItems="flex-start"
           spacing={4}>
              
               <Grid  item xs={12} sm={12} md={12} lg={12} xl={12}    >        
            <h2 style={{textDecoration:"underline",marginBottom:"0px",textAlign:"center" }}  >
               Edit MetaData</h2>
             
          </Grid>
           
           
           
           
          <Grid  item xs={12} sm={12}  md={6} lg={6} xl={6}    >        
          <TextField id="filled-required" label="Title" variant="filled" 
                        error={err===4 && true}
                        value={title}
                        size="small" 
                        onChange={(e)=>{
                            setTitle(e.target.value);
                        }}
                        required fullWidth  />
          </Grid>
          <Grid  item xs={12} sm={12}  md={6} lg={6} xl={6}    >        
          <TextField id="filled-required" label="Keywords" variant="filled" 
                        error={err===5 && true}
                        value={keywords}
                        size="small" 
                        multiline
                        rows={4}
                        onChange={(e)=>{
                            setKeywords(e.target.value);
                        }}
                        required fullWidth  />
          </Grid>
          <Grid  item xs={12} sm={12}  md={6} lg={6} xl={6}    >        
          <TextField id="filled-required" label="Description" variant="filled" 
                        error={err===6 && true}
                        value={metadesc}
                        size="small" 
                        multiline
                        rows={4}
                        onChange={(e)=>{
                            setMetaDesc(e.target.value);
                        }}
                        required fullWidth  />
          </Grid>
          <Grid  item xs={12} sm={12} md={6} lg={6} xl={6}    >
          <FormControl
               
              style={{ width: "100%"  }}
            >
          <InputLabel id="demo-mutiple-name-label">For Page</InputLabel>
            <Select
             
            labelId="demo-mutiple-name-label"
            id="demo-mutiple-name"
             error={err===7 && true}
            value={page}
            onChange={(e)=>{
                setPage(e.target.value);
            }}
            required
            autoWidth
            >
                        <MenuItem value={""}>Select for which Page...</MenuItem>
                        
                        <MenuItem value={1}>Home</MenuItem>
                        <MenuItem value={2}>Services</MenuItem>
                        <MenuItem value={3}>Products</MenuItem>
                        <MenuItem value={4}>Our Works</MenuItem>
                        <MenuItem value={5}>Contact Us</MenuItem>
                        <MenuItem value={6}>About Us</MenuItem>
                        <MenuItem value={7}>Whats New</MenuItem>
                        <MenuItem value={8}>Our Team</MenuItem>
            </Select>
             </FormControl>
          </Grid>
          <br/>
          <Grid  item xs={12} sm={12}  md={12} lg={12} xl={12}    >  
          <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          disabled={disablebutton}
          >
              {disablebutton?"Please wait..":"Update MetaData"}
              
          </Button>
          </Grid>
          </Grid>
               </Box>)}
               </Container>);

}
import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';

export const EditorCkMain = ({ desc, setDescr }) => {
    const editor = useRef(null);

    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        height: 500,
        toolbarAdaptive: false,
        toolbarSticky: false,
        buttons: [
            'source', '|',
            'bold', 'italic', 'underline', 'strikethrough', '|',
            'superscript', 'subscript', '|',
            'ul', 'ol', '|',
            'outdent', 'indent', '|',
            'font', 'fontsize', 'brush', 'paragraph', '|',
            'image', 'video', 'table', 'link', '|',
            'align', 'undo', 'redo', '|',
            'hr', 'eraser', 'copyformat', '|',
            'symbol', 'fullsize', 'print', 'about'
        ]
    };

    return (
        <div style={{ backgroundColor: 'white' }}>
            <JoditEditor
                ref={editor}
                value={desc}
                config={config}
                onBlur={newContent => setDescr(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => {}}
            />
        </div>
    );
};

 
 
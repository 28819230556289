import {
    Container,
    Grid,
    Box,
    TextField,
    Button,
    Snackbar,
    Slide,
    FormLabel,
    useTheme,
    Typography,
    Paper,
    makeStyles,
  } from "@material-ui/core";
  import { useState, useEffect, useRef } from "react";
  
  import MuiAlert from "@material-ui/lab/Alert";
  import Axios from "axios";
  import { Helmet } from "react-helmet-async";
  import SendIcon from "@material-ui/icons/Send";
  import { Loading } from "./loading";
  import PlaceIcon from "@material-ui/icons/Place";
  import EmailIcon from "@material-ui/icons/Email";
  import FacebookIcon from "@material-ui/icons/Facebook";
  import InstagramIcon from "@material-ui/icons/Instagram";
  import LinkedInIcon from "@material-ui/icons/LinkedIn";
  import PhoneIcon from '@material-ui/icons/Phone';
  
  const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";
  
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  const useStyles = makeStyles({
    nounderline: {
      "&:hover": { textDecoration: "none" },
    },
    cardshadow: {
      "&:hover": {
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      },
    },
  });
  
  export const StudentDetails = () => {
  
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const mobileRef = useRef(null);
    const regNoRef = useRef(null);
    const branchRef = useRef(null);
  
    const theme = useTheme();
    const classes = useStyles();
    const [metaTitle, setMetaTitle] = useState("Student Enrollment - Submit Your Details | Arawinz Python Full Stack & Data Science Programs");
    const [metaDesc, setMetaDesc] = useState("");
    const [metaKeywords, setMetaKeywords] = useState("");
    const [isloading, setIsloading] = useState(false);
    //const [checked, setChecked] =  useState(true);
    
    const [regNo, setRegNo] = useState("");
    const [branch, setBranch] = useState(""); 
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mob, setMob] = useState("");
  
    const [err, setErr] = useState(0);
    const [disablebutton, setDisablebutton] = useState(false);
  
    const [snackBar, setSnackBar] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState("success");
    const [snackBarType, setSnackBarType] = useState("Invalid");
  
    const openSnkbar = (type, msg) => {
      setSnackBarMsg(msg);
      setSnackBarType(type);
      setSnackBar(true);
    };
  
    const handleClick = () => {
      const anchor = document.querySelector("#back-to-top-anchor");
  
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };
  
    useEffect(() => {
    //   getMetaData();
    }, []);
  
    // const getMetaData = () => {
    //   const formData = new FormData();
    //   formData.append("page", 5);
    //   Axios.post("/api/getmetadata", formData, {
    //     headers: { headKey: headKey },
    //   }).then((res) => {
    //     if (res.data.status) {
    //       setMetaTitle(res.data.data.meta_title);
    //       setMetaKeywords(res.data.data.meta_keywords);
    //       setMetaDesc(res.data.data.meta_desc);
    //       setIsloading(false);
    //       handleClick();
    //     }
    //   });
    // };
  
    const onSubmit = () => {
      setErr(0);
      if (regNo.trim() == "" || /\s/.test(regNo)) {
        setErr(1);
         
        if (regNoRef.current) {
            regNoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        openSnkbar("error", "Please Enter Valid Student's Reg no....");
      } else if (name == "") {
        setErr(2);
         
        if (nameRef.current) {
            nameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        openSnkbar("error", "Please Enter Your Full Name....");
      } else if (branch == "") {
        setErr(3);
         
        if (branchRef.current) {
            branchRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        openSnkbar("error", "Please Enter Branch Name...");
      }   else {
        openSnkbar("warning", "Please wait.....");
        setDisablebutton(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("regno", regNo.trim());
        formData.append("branch", branch);
        formData.append("email", email);
        formData.append("mob", mob);
        
        Axios.post("/api/submit_student_details", formData, {
          headers: { headKey: headKey },
        }).then((res) => {
          if (res.data.status) {
            setDisablebutton(false);
            openSnkbar("success", res.data.msg);
          } else {
            setDisablebutton(false);
            openSnkbar("error", res.data.msg);
          }
        });
      }
    };
  
    const jumbotroncss = {
      backgroundImage:
        'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url("/static/contact.jpg")',
      opacity: 0.8,
      backgroundAttachment: "fixed",
      color: "white",
      minHeight: "250px",
      //marginTop: "0px",
      marginBottom: "0px",
      borderRadius:"0px"
    };
  
    return (
      <div>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDesc} />
          <meta name="keywords" content={metaKeywords}></meta>
        </Helmet>
        <div className="jumbotron text-center" style={jumbotroncss}>
          <Paper style={{ opacity: "0.8" }}>
          <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
            <Typography
              variant="h2"
              style={{
                color: theme.palette.cream.main,
                fontWeight: 500,
                WebkitTextStrokeWidth: 2,
                letterSpacing: 8,
                WebkitTextStrokeColor: theme.palette.primary.main,
              }}
            >
                 </Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Typography
                variant="h2"
                style={{
                  color: theme.palette.primary.main,
                  fontWeight: 500,
                  letterSpacing: 8,
                }}
              > 
            </Typography>
            </Grid>
            <Typography variant="h6" style={{color:theme.palette.primary.main,letterSpacing:1
            }}>
              Accelerate Your Career with Arawinz Python Full Stack and Data Science Training – <b>Tailored for Shivani College Students!</b>
            </Typography>
            <br />
          </Paper>
        </div>
        {isloading ? (
          <Loading />
        ) : (
          <Box
            boxShadow={3}
            
            style={{ backgroundColor: theme.palette.cream.main }}
          >
             <Container maxWidth="lg">
             <br/>
             {/* <Slide
                direction="right"
                in={true}
                mountOnEnter
                unmountOnExit
                {...(true ? { timeout: 1000 } : {})}
              >
                <div class="two">
                  <h1 style={{color:"#4c004c"}}>Get in Touch: Connect with Arawinz
                    <span>We're Here to Listen, Assist, and Partner with You</span>
                  </h1>
                </div>
   
              </Slide> */}
              <br/>
              <br/>
              <br/>
              <Box style={{
                        // border: "1px groove #4c004c",
                        backgroundColor: "#4c004c",
                      }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                // spacing={6}
              >
                 
                 
                <Grid item xs={12} sm={12} >
                  <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <Box
                      // boxShadow={3}
                      p={3}
                      style={{
                        // border: "1px groove #4c004c",
                        backgroundColor: "white",
                      }}
                      // className={classes.cardshadow}
                    >
                      <Snackbar
                        open={snackBar}
                        autoHideDuration={4000}
                        onClose={() => {
                          setSnackBar(false);
                        }}
                      >
                        <Alert
                          onClose={() => {
                            setSnackBar(false);
                          }}
                          severity={snackBarType}
                          style={{
                            color: snackBarType === "warning" ? "black" : "white",
                          }}
                        >
                          {snackBarMsg}
                        </Alert>
                      </Snackbar>
                      
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={4}
                      >
                        <Grid item xs={12} sm={8} md={12} lg={12} xl={12}>
                          <Typography
                            variant="h4"
                            gutterBottom
                            style={{
                              textAlign: "center",
                              color: theme.palette.secondary.main,
                            }}
                          >
                           Student Enrollment

                          </Typography>
  
                          <FormLabel
                          className="article_desc"
                            style={{
                              lineHeight: "1.5",
                              textAlign: "left",
                              color: "#000000",
                            }}
                          >
                            Please Fill in the Basic Details Below.
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                          <TextField
                            ref={regNoRef} 
                            id="regno"
                            label="Student Reg No"
                            // variant="filled"
                            error={err === 1 && true}
                            value={regNo}
                            size="small"
                            onChange={(e) => {
                              setRegNo(e.target.value);
                            }}
                            required
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                          <TextField
                            ref={nameRef} 
                            id="name"
                            label="Full Name"
                            // variant="filled"
                            error={err === 2 && true}
                            value={name}
                            size="small"
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            required
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                          <TextField
                            ref={branchRef} 
                            id="branch"
                            label="Branch"
                            // variant="filled"
                            error={err === 3 && true}
                            value={branch}
                            size="small"
                            onChange={(e) => {
                              setBranch(e.target.value);
                            }}
                            required
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            id="email"
                            label="Email (Optional)"
                            ref={emailRef}
                            // variant="filled"
                           
                            value={email}
                            size="small"
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                             
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            id="mobile"
                            label="Mobile (Optional)"
                            ref={mobileRef}
                            // variant="filled"
                            
                            // type="number"
                            value={mob}
                            size="small"
                            onChange={(e) => {
                              setMob(e.target.value);
                            }}
                             
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      
                     
                        
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="text-center"
                        >
                          <Button
                            onClick={onSubmit}
                            variant="contained"
                            color="primary"
                            disabled={disablebutton}
                            style={{width:"60%"}}
                          >
                            <SendIcon /> &nbsp;&nbsp;
                            {disablebutton ? "Please wait.." : "Submit Details"}
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <br/>
                        </Grid>
                        
                      </Grid>
                      </Box>
                     
                  </Slide>
                </Grid>
                {/* <Grid item xs={12} sm={4} md={5} lg={5} xl={5}>
                  <Slide
                    direction="up"
                    in={true}
                    mountOnEnter
                    unmountOnExit
                    {...(true ? { timeout: 1000 } : {})}
                  >
                    <Box
                     
                      p={3}
                      style={{
                        
                        backgroundColor: "#4c004c",
                      }}
                       
                    >
                      <Grid
                        container      
                        direction="column"
                        justifyContent="space-around"
                        alignItems="stretch"
                        spacing={3}
                        className="article_desc"
                      >
                         
                        
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                           
                          <Typography variant="body1" gutterBottom
                            style={{ textAlign: "left", color: "#ffffff" }}
                          >
                            <PlaceIcon /> &nbsp;&nbsp;3rd floor, A-BLOCK, Sandil, Jayabheri Enclave,
                            Gachibowli, Hyderabad, Telangana 500032
                          </Typography>{" "}
                        </Grid>
                         
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                           
                          <Typography variant="body1" gutterBottom style={{ textAlign: "left", color: "#ffffff" }}>
                          <EmailIcon />&nbsp;&nbsp;contact@arawinz.com and arawinzsoft@gmail.com  
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                           
                         
                        </Grid>
                         
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign:"right"}}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item>
                          <a href="https://www.linkedin.com/company/arawinz-soft-solutions"
                                target="_blank"
                              >
                              <span style={{ width: '50px', height: '50px', backgroundColor: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                <LinkedInIcon
                                  style={{ color:"#4c004c", fontSize:"30px" }}
                                />
                              </span>
                          </a>
                          </Grid>
                          <Grid item>
                          <a href="https://www.facebook.com/Arawinz-Soft-Solutions-100197325544506/"
                                    target="_blank"
                                  >
                                  <span style={{ width: '50px', height: '50px', backgroundColor: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                    <FacebookIcon
                                      style={{ color:"#4c004c", fontSize:"30px" }}
                                    />
                                  </span> 
                            </a>
                          </Grid>
                          <Grid item>
                          <a href="https://instagram.com/arawinzsoftsolutions/"
                                    target="_blank"
                                  >
                                  <span style={{ width: '50px', height: '50px', backgroundColor: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                    <InstagramIcon
                                      style={{ color:"#4c004c", fontSize:"30px" }}
                                    />
                                  </span> 
                            </a>
                          </Grid>
                        </Grid>
                        
                        </Grid>
                         
                      </Grid>
                    </Box>
                  </Slide>
                </Grid> */}
                <Grid item xs={12}>
                
                            </Grid>
              </Grid>
              </Box>
              <Slide
                direction="right"
                in={true}
                mountOnEnter
                unmountOnExit
                {...(true ? { timeout: 1000 } : {})}
              >
            <div >
              <br/>
              <br/><br/>
              <br/>
              <blockquote className="article_desc">
              At Arawinz, we are committed to empowering professionals through our Corporate Training Programs, designed to meet the specific needs of your organization. We focus on delivering comprehensive, practical, and cutting-edge training solutions that enhance skills, foster innovation, and drive success. Our goal is to equip your team with the knowledge and tools they need to excel in a dynamic business environment.
                <span>-&nbsp;Arawinz Team</span>
              </blockquote>
              <br/>
              <br/>
            </div>
            </Slide>
            </Container>
          </Box>
        )}
        
      </div>
    );
  };
  
import {
  Container,
  Grid,
  Box,
  TextField,
  makeStyles,
  useTheme,
  Slide,
  Typography,
  Paper,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import { Helmet } from "react-helmet-async";

import { Loading } from "./loading";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";

const useStyles = makeStyles({
  cardshadow: {
    "&:hover": {
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    },
  },
});

export const AboutUs = () => {
  const theme = useTheme();

  const classes = useStyles();
  const [data, setData] = useState();
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [isloading, setIsloading] = useState(true);

  const handleClick = () => {
    const anchor = document.querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    getMetaData();
  }, []);

  const getMetaData = () => {
    const formData = new FormData();
    formData.append("page", 6);
    Axios.post("/api/getmetadata", formData, {
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.data.status) {
        setMetaTitle(res.data.data.meta_title);
        setMetaKeywords(res.data.data.meta_keywords);
        setMetaDesc(res.data.data.meta_desc);
        setIsloading(false);
        handleClick();
      }
    });
    Axios.post("/api/select_aboutus", {
      headers: { headKey: headKey },
    }).then((res) => {
      console.log("hit");
      console.log(res.data.status);
      if (res.data.status) {
        console.log(res.data.status);
        setData(ReactHtmlParser(res.data.data.description));
      }
    });
  };

  const jumbotroncss = {
    backgroundImage:
      'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url("/static/about.jpg")',
    opacity: 0.8,
    backgroundAttachment: "fixed",
    color: "white",
    minHeight: "250px",
    //marginTop: "0px",
    marginBottom: "0px",borderRadius:"0px"
  };

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
        <meta name="keywords" content={metaKeywords}></meta>
      </Helmet>
      <div className="jumbotron text-center" style={jumbotroncss}>
        <Paper style={{ opacity: "0.8" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h2"
              style={{
                color: theme.palette.cream.main,
                fontWeight: 500,
                WebkitTextStrokeWidth: 2,
                letterSpacing: 8,
                WebkitTextStrokeColor: theme.palette.primary.main,
              }}
            >
              About{" "}
            </Typography>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Typography
              variant="h2"
              style={{
                color: theme.palette.primary.main,
                fontWeight: 500,
                letterSpacing: 8,
              }}
            >
              Us
            </Typography>
          </Grid>
          {/* <Typography
            variant="h5"
            style={{ color: theme.palette.primary.main, letterSpacing: 1 }}
          >
            Idea <ArrowForwardIosIcon /> Strategy <ArrowForwardIosIcon />{" "}
            Execution
          </Typography> */}
          <Typography variant="h6" style={{color:theme.palette.primary.main,letterSpacing:1
          }}>
Crafting Excellence Through Unique IT Services</Typography>
          <br />
        </Paper>
      </div>
     
      {isloading ? (
        <Loading />
      ) : (
        <Box
          boxShadow={3}
          p={3}
           
          style={{ backgroundColor: theme.palette.cream.main }}
        >
        <Container maxWidth="lg">
          
          <br/>
          <Slide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              {...(true ? { timeout: 1000 } : {})}
            >
              <div class="two">
                <h1 style={{color:"#4c004c"}}>Discover Arawinz: Our Story and Vision
                  <span>Learn About Our Journey, Values, and the Drive Behind Our Innovations</span>
                </h1>
              </div>
 
            </Slide>
            <br />
            <br />
          <Slide
            direction="down"
            in={true}
            mountOnEnter
            unmountOnExit
            {...(true ? { timeout: 1000 } : {})}
          >
            <div  className="article_desc">
            {/* <Box
              boxShadow={3}
              p={3}
              m={4}
              style={{ backgroundColor: theme.palette.cream.main ,alignItems:"justify"}}
              className={classes.cardshadow}
            > */}
              {data}
              </div>
            {/* </Box> */}
          </Slide>
          <Slide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              {...(true ? { timeout: 1000 } : {})}
            >
          <div >
            <br/>
            <br/>
            <br/>
            <blockquote className="article_desc">
            At Arawinz, our ethos is rooted in the seamless fusion of creativity and commitment, driving us to provide outstanding results. Our path is defined by an unwavering dedication to surpassing expectations and an earnest endeavor to grasp and cater to our clients' unique requirements. As a collective, we transcend mere collaboration – we are visionaries shaping tomorrow, piecing together a more intelligent and interconnected reality.
              <span>-&nbsp;Dr. P. Srinivas Rao, CEO</span>
            </blockquote>
            <br/>
            <br/>
          </div>
          </Slide>
        </Container>
        
        </Box>
      )}
   
    </div>
  );
};

import {
  Box,
  IconButton,
  Typography,
  Container,
  Button,
  Grid,
  Dialog,
  DialogContent,
  TextField,
  MenuItem,
  Card,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import react, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid } from '@material-ui/data-grid';
import { Helmet } from "react-helmet";
import EditIcon from '@material-ui/icons/Edit';
// var momentt = require("moment-timezone");
export const AllArticles = () => {
  let history = useNavigate();
  const [data, setData] = useState("");

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getdata();
  }, []);
  const handleClick = () => {
    const anchor = document.querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const getdata = async () => {
    setLoading(true);
    const formdata = new FormData();
    await axios.post("/api/get_all_articles_bk", formdata).then((res) => {
      if (res.data.status === true) {
        setData(res.data.data);
        setLoading(false);
        handleClick();
      }
    });
  };
  const columns = [
    { field: 'article_id', headerName: 'ID', width: 100 },
    { field: 'article_title', headerName: 'Title', width: 200 },
    { field: 'article_status', headerName: 'Status', width: 150 , valueGetter: (params) => {
      if (params.row.article_status === 1) {
        return "Active";
      } else {
        return "In-Active";
      }
    },},
    { field: 'article_published_date', headerName: 'Published Date', width: 200 },
    { field: 'article_images', headerName: 'Image', width: 150,renderCell: (params) => <img src={`/static/articles/${params.value.split(",")[0]}`} width="300" height="100" style={{aspectRatio:1/1}} />, headerName: "Image", },
    { field: 'article_added_date', headerName: 'Added Date', width: 200 },
    { field: 'article_updated_date', headerName: 'Updated Date', width: 200 },

    {
      field: "actions",
      headerName: "Edit",
      type: "actions",
      width: 100,
      renderCell: (row) => (
        <IconButton>
          <EditIcon
            onClick={() => {
              history(`/dash/edit_articles/${row.row.article_id}`);
            }}
          />
        </IconButton>
      ),
    },
  ]


  return (
    <div>
          {loading ? (
            <p style={{textAlign:'center'}}>Fetching Please wait...</p>
          ) : (
            <Container maxWidth="lg">
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              
              >

                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="h4" >
                    My Articles
                  </Typography>
                </Grid>
               <Grid item xs={12} style={{ textAlign: "center" }}> 
               <IconButton>
                      <EditIcon
                        onClick={() => {
                          history(`/dash/add_article`);
                        }}
                      />
                    </IconButton>
                </Grid>
                  <div style={{ height: 400, width: '100%' }}>
      <DataGrid
          rows={data}
        columns={columns}
        getRowId={(row) => row.article_id}
        pageSize={10}
      />
    </div>
              </Grid>
            </Container>
          )}
    </div>
  );
};

import {Box, Button} from '@material-ui/core';
import {useEffect,useState} from 'react';
import {    Link } from 'react-router-dom';
import Axios from 'axios';
import {Loading} from './loading';
import {Helmet} from "react-helmet";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';

const headKey="qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";
const $ = require("jquery");

export const EnrolledStudents = () =>{
    const [loading, setLoading] = useState(true);
    const [data,setData] = useState();
    

    useEffect(()=>{
        getData();
     },[]);

    const getData=()=>{
        Axios.post("/api/get_all_students",{   
            headers: {headKey:headKey},
         }).then((res) => {
            
            setData(res.data.data);
            
            setLoading(false);
            $(document).ready( function () {
              $('#table_id').DataTable({
                "bDestroy": true,
                scrollX:1700,
                scrollY:500,
              });
          } );
          })
      }

    return (<div>
            {loading?(<Loading/>):(<div>
                <Helmet>
                    <title>Enrolled Students Data - Admin | Arawinz Soft Solutions</title>
                    <meta name="description" content="Arawinz Soft Solutions is one of the finest Software Development company. We provide services in Web Development-Full Stack, Web Designing (Static & Responsive), Mobile App Development, Social Media Marketing, SEO, Data Analytics,  CRM, ERP, Mobile Gaming and Artificial Intelligence." />
                    <meta name="keywords" content=" Testimonials Top Web Development company in Gachibowli, SEO companies in Hyderabad, Best Application development company in India, Top 5 development companies in Vizag, Digital and Social Media Marketing, Data Analytics company in Hitech city, Graphics Design and Gaming company in Jubilee hills, Best Artificial Intelligence company in Hyderabad, Top gaming organization near me, Available ERP &amp; CRM developers Madhapur"></meta>
                </Helmet>
                <br/>
                <Box sx={{width:"95%"}}>

                    <h3 className='text-center'>Total Enrolled Students: {data.length}</h3>
                    <br/>
                <table id="table_id" className="table table-striped">
                
                    <thead>
                        <tr>
                        <th>STUDENT ID</th>
                        <th>REG NO</th>
                        <th>NAME</th>
                        <th>COLLEGE</th>
                        <th>BRANCH</th>
                        <th>EMAIL</th>
                        <th>MOBILE</th>
                        <th>ENROLLED DATE</th>
                        </tr>
                    </thead>
                    <tbody>
                      
                        {data.map((da)=>{
                            return (<tr>
                                <td>{da.student_id}</td>
                                <td>{da.student_regno}</td>
                                <td>{da.student_name}</td>
                                <td>{da.student_college}</td>
                                <td>{da.student_branch}</td>
                                <td>{da.student_email}</td>
                                <td>{da.student_mobile}</td> 
                                <td>{da.submitted_datetime}</td>   
                                
                            </tr>);
                        })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <th>STUDENT ID</th>
                        <th>REG NO</th>
                        <th>NAME</th>
                        <th>COLLEGE</th>
                        <th>BRANCH</th>
                        <th>EMAIL</th>
                        <th>MOBILE</th>
                        <th>ENROLLED DATE</th>
                        </tr>
                    </tfoot>
                </table></Box>
            </div>)}
    </div>);
}
import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Snackbar,
  Switch,
  MenuItem
} from "@material-ui/core";
import { useState, useEffect } from "react";
import CKEditor from "react-ckeditor-component";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { Loading } from "./loading";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import {EditorCkMain} from './CkEditor';
const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let greenColor = {
  color: "green",
  fontWeight: "bold",
};

let blackColor = {
  color: "black",
};

export const UpdateService = () => {
  let { id } = useParams();

  const [isloading, setIsLoading] = useState(true);

  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [servPic, setServPic] = useState({ raw: "" });
  const [priority, setPriority] = useState("15");
  const [status, setStatus] = useState(true);
  const [oldImage, setOldImage] = useState("");

  const [title, setTitle] = useState("");
  const [keywords, setKeywords] = useState("");
  const [metadesc, setMetaDesc] = useState("");
  const [content, setContent] = useState("");
  const [err, setErr] = useState(0);
  const [disablebutton, setDisablebutton] = useState(false);

  const [snackBar, setSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("success");
  const [snackBarType, setSnackBarType] = useState("Invalid");
  const navigate = useNavigate();
  const openSnkbar = (type, msg) => {
    setSnackBarMsg(msg);
    setSnackBarType(type);
    setSnackBar(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const formData = new FormData();
    formData.append("id", id);
    Axios.post("/api/getsingleservice", formData, {
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.data.status) {
        setName(res.data.data.serv_name);
        setContent(res.data.data.serv_desc);
        if (res.data.data.serv_image !== null) {
          setOldImage(res.data.data.serv_image);
        }
        setPriority(res.data.data.serv_priority)
        setTitle(res.data.data.serv_title);
        setKeywords(res.data.data.serv_keywords);
        setMetaDesc(res.data.data.serv_metadesc);
        if (res.data.data.serv_status === 1) {
          setStatus(true);
        } else {
          setStatus(false);
        }
        setIsLoading(false);
      }
    });
  };
  const handleChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
  };
  const onSubmit = () => {
    setErr(0);
    if (name == "") {
      setErr(1);
      openSnkbar("error", "Please Enter Service Name....");
    } else if (content == "") {
      setErr(2);
      openSnkbar("error", "Please Enter Service Description....");
    } else if (servPic.raw == "" && oldImage == "") {
      setErr(3);
      openSnkbar("error", "Please Select a service Image....");
    } else if (title == "") {
      setErr(4);
      openSnkbar("error", "Please Enter Meta Title....");
    } else if (keywords == "") {
      setErr(5);
      openSnkbar("error", "Please Enter Meta Keywords....");
    } else if (metadesc == "") {
      setErr(6);
      openSnkbar("error", "Please Enter Meta Description....");
    } else {
      openSnkbar("warning", "Please wait.....");
      setDisablebutton(true);
      const formData = new FormData();
      if (servPic.raw !== "") {
        formData.append("image", servPic.raw);
      }
      formData.append("idd", id);
      formData.append("name", name);
      formData.append("desc", content);
      formData.append("servpriority", priority);
      formData.append("title", title);
      // formData.append("oldimage", oldImage);
      formData.append("keywords", keywords);
      formData.append("metadesc", metadesc);
      // if (status) {
      //   formData.append("status", "1");
      // } else {
      //   formData.append("status", "0");
      // }
      Axios.post("/api/updateservice", formData, {
        headers: { headKey: headKey },
      }).then((res) => {
        if (res.data.status) {
          setDisablebutton(false);
          setServPic({ raw: "" });
          openSnkbar("success", res.data.msg);
          setIsLoading(true);
          navigate("/dash/allservices");
        } else {
          setDisablebutton(false);
          openSnkbar("error", "Something went wrong... try later.");
        }
      });
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Edit Service - Admin | Arawinz Soft Solutions</title>
        <meta
          name="description"
          content="Arawinz Soft Solutions is one of the finest Software Development company. We provide services in Web Development-Full Stack, Web Designing (Static & Responsive), Mobile App Development, Social Media Marketing, SEO, Data Analytics,  CRM, ERP, Mobile Gaming and Artificial Intelligence."
        />
        <meta
          name="keywords"
          content=" Testimonials Top Web Development company in Gachibowli, SEO companies in Hyderabad, Best Application development company in India, Top 5 development companies in Vizag, Digital and Social Media Marketing, Data Analytics company in Hitech city, Graphics Design and Gaming company in Jubilee hills, Best Artificial Intelligence company in Hyderabad, Top gaming organization near me, Available ERP &amp; CRM developers Madhapur"
        ></meta>
      </Helmet>
      {isloading ? (
        <Loading />
      ) : (
        <Box boxShadow={3} p={3} m={4} style={{ border: "2px solid #333333" }}>
          <Snackbar
            open={snackBar}
            autoHideDuration={4000}
            onClose={() => {
              setSnackBar(false);
            }}
          >
            <Alert
              onClose={() => {
                setSnackBar(false);
              }}
              severity={snackBarType}
              style={{
                color: snackBarType === "warning" ? "black" : "white",
              }}
            >
              {snackBarMsg}
            </Alert>
          </Snackbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={4}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <h2
                style={{
                  textDecoration: "underline",
                  marginBottom: "0px",
                  textAlign: "center",
                }}
              >
                Edit Service
              </h2>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                id="filled-required"
                label="Name"
                variant="filled"
                error={err === 1 && true}
                value={name}
                size="small"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <EditorCkMain
                              desc={content}
                              setDescr={(e) => setContent(e)}
                            /> 
              
              {/* <CKEditor
                activeClass="p10"
                content={content}
                config={{
                  toolbar: [
                    {
                      name: "document",
                      items: [
                        "Source",
                        "-",
                        "NewPage",
                        "Preview",
                        "-",
                        "Templates",
                      ],
                    },
                    {
                      name: "clipboard",
                      items: [
                        "Cut",
                        "Copy",
                        "Paste",
                        "PasteText",
                        "PasteFromWord",
                        "-",
                        "Undo",
                        "Redo",
                      ],
                    },
                    {
                      name: "styles",
                      items: ["Styles", "Format", "Font", "FontSize"],
                    },
                    {
                      name: "basicstyles",
                      items: [
                        "Bold",
                        "underline",
                        "Italic",
                        "Strike",
                        "Subscript",
                        "Superscript",
                        "-",
                        "RemoveFormat",
                      ],
                    },
                    {
                      name: "colors",
                      items: ["fontColor", "fontBackgroundColor"],
                    },
                    { name: "links", items: ["Link", "Unlink", "Anchor"] },
                    {
                      name: "insert",
                      items: [
                        "Image",
                        "Table",
                        "HorizontalRule",
                        "SpecialChar",
                      ],
                    },
                    { name: "tools", items: ["Maximize"] },
                    { name: "others", items: ["-"] },
                    { name: "about", items: ["About"] },
                  ],
                  height: 300,
                  width: 800,
                }}
                events={{
                  change: handleChange,
                }}
              /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              select
              id="filled-required"
              label="Priority"
              variant="filled"
              // error={err === 6 && true}
              value={priority}
              size="small"
              onChange={(e) => {
                setPriority(e.target.value);
              }}
              required
              fullWidth
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
              <MenuItem value="7">7</MenuItem>
              <MenuItem value="8">8</MenuItem>
              <MenuItem value="9">9</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="11">11</MenuItem>
              <MenuItem value="12">12</MenuItem>
              <MenuItem value="13">13</MenuItem>
              <MenuItem value="14">14</MenuItem>
              <MenuItem value="15">15</MenuItem>
            </TextField>
          </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Switch
                checked={status}
                onChange={() => {
                  setStatus(!status);
                }}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              ></Switch>
              Status -{" "}
              <span style={status ? greenColor : blackColor}>Show</span> /{" "}
              <span style={!status ? greenColor : blackColor}>Hide</span>
            </Grid>
            <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
              {oldImage != "" && (
                <img
                  width="250px"
                  height="250px"
                  src={"/static/services/" + oldImage}
                />
              )}
              {servPic.raw != "" && (
                <img
                  width="250px"
                  height="250px"
                  src={servPic.preview}
                  style={{ padding: "4%" }}
                />
              )}
              <br />
              <br />

              <input
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e.target.files.length != 0) {
                    var type = e.target.files[0].type;

                    if (
                      type === "image/jpeg" ||
                      type === "image/jpg" ||
                      type === "image/png"
                    ) {
                      setServPic({
                        preview: URL.createObjectURL(e.target.files[0]),
                        raw: e.target.files[0],
                      });
                    } else {
                      alert("Please select only JPEG, JPG, PNG");
                    }
                  }
                }}
                id="contained-button-file"
                type="file"
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  Upload Image
                </Button>
              </label>
              {err === 3 && (
                <div style={{ color: "red" }}>Please select a file.</div>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <h2
                style={{
                  textDecoration: "underline",
                  marginBottom: "0px",
                  textAlign: "center",
                }}
              >
                Meta Details
              </h2>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                id="filled-required"
                label="Title"
                variant="filled"
                error={err === 4 && true}
                value={title}
                size="small"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                id="filled-required"
                label="Keywords"
                variant="filled"
                error={err === 5 && true}
                value={keywords}
                size="small"
                multiline
                rows={4}
                onChange={(e) => {
                  setKeywords(e.target.value);
                }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                id="filled-required"
                label="Description"
                variant="filled"
                error={err === 6 && true}
                value={metadesc}
                size="small"
                multiline
                rows={4}
                onChange={(e) => {
                  setMetaDesc(e.target.value);
                }}
                required
                fullWidth
              />
            </Grid>
            <br />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                onClick={onSubmit}
                variant="contained"
                color="primary"
                disabled={disablebutton}
              >
                {disablebutton ? "Please wait.." : "Update Service"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
};

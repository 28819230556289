import {
    Container,
    Grid,
    Box,
    ButtonBase,
    makeStyles,
    Card,
    CardMedia,
    Hidden,
    useTheme,
    Slide,
    Typography,
    Paper,
    useMediaQuery
  } from "@material-ui/core";
  import { useState, useEffect } from "react";
  import parse from "react-html-parser";
  import MuiAlert from "@material-ui/lab/Alert";
  import Axios from "axios";
  import {Link} from 'react-router-dom';
  import { Helmet } from "react-helmet-async";
  
  import { Loading } from "./loading";
  
  import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
  
  const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";
  
  const useStyles = makeStyles({
    cardshadow: {
      "&:hover": {
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      },
    },
  });
  
  export const WhatsNew = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [metaTitle, setMetaTitle] = useState("");
    const [metaDesc, setMetaDesc] = useState("");
    const [metaKeywords, setMetaKeywords] = useState("");
    const [isloading, setIsloading] = useState(true);
  
    const handleClick = () => {
      const anchor = document.querySelector("#back-to-top-anchor");
  
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };
  
    useEffect(() => {
      getMetaData();
    }, []);
  
    const getMetaData = async() => {
      const formData = new FormData();
      formData.append("page", 7);
      await Axios.post("/api/getmetadata", formData, {
        headers: { headKey: headKey },
      }).then((res) => {
        if (res.data.status) {
          setMetaTitle(res.data.data.meta_title);
          setMetaKeywords(res.data.data.meta_keywords);
          setMetaDesc(res.data.data.meta_desc);
          
          handleClick();
        }
      });
     

      await  Axios
      .post(
        "/api/get_all_articles",
        {
            headers: { headKey: headKey },
          }
      )
      .then((res) => {
        if (res.data.status === true) {
          setData(res.data.data);
        //   setLoading(false);
          console.log(res.data.data);
          setIsloading(false);
        }
      });
    };
    const createSlug = (title) => {
        return title
            .toLowerCase() // Convert to lowercase
            .replace(/[^\w ]+/g, '') // Remove all non-word chars
            .replace(/ +/g, '-'); // Replace spaces with hyphens
    };

    const jumbotroncss = {
      backgroundImage:
        'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url("/static/whats_new.jpg")',
      opacity: 0.8,
      backgroundAttachment: "fixed",
      color: "white",
      minHeight: "250px",
      //marginTop: "0px",
      marginBottom: "0px",
      backgroundPosition: "center", // This centers the image
      backgroundRepeat: "no-repeat", // This prevents the image from tiling
      backgroundSize: "cover", // This makes the image cover the entire area; use "contain" to fit the image within the area without cropping
      borderRadius:"0px"
    };
  
    return (
      <div>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDesc} />
          <meta name="keywords" content={metaKeywords}></meta>
        </Helmet>
        <div className="jumbotron text-center" style={jumbotroncss}>
          <Paper style={{ opacity: "0.8" }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h2"
                style={{
                  color: theme.palette.cream.main,
                  fontWeight: 500,
                  WebkitTextStrokeWidth: 2,
                  letterSpacing: 8,
                  WebkitTextStrokeColor: theme.palette.primary.main,
                }}
              >
                Arawinz{" "}
              </Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Typography
                variant="h2"
                style={{
                  color: theme.palette.primary.main,
                  fontWeight: 500,
                  letterSpacing: 8,
                }}
              >
                Insights
              </Typography>
            </Grid>
            {/* <Typography
              variant="h5"
              style={{ color: theme.palette.primary.main, letterSpacing: 1 }}
            >
              Idea <ArrowForwardIosIcon /> Strategy <ArrowForwardIosIcon />{" "}
              Execution
            </Typography> */}
            <Typography variant="h6" style={{color:theme.palette.primary.main,letterSpacing:1
            }}>
  Tech Updates & Media Highlights</Typography>
            <br />
          </Paper>
        </div>
       
        {isloading ? (
          <Loading />
        ) : (
          <Box
            boxShadow={3}
            p={3}
             
            style={{ backgroundColor: theme.palette.cream.main }}
          >
          <Container maxWidth="lg">
            
            <br/>
            <Slide
                direction="right"
                in={true}
                mountOnEnter
                unmountOnExit
                {...(true ? { timeout: 1000 } : {})}
              >
                <div class="two">
                  <h1 style={{color:"#4c004c"}}>What's New at Arawinz
                    <span>Discover the Latest in IT Innovations and Media Spotlights</span>
                  </h1>
                </div>
   
              </Slide>
              <br />
              <br />
              <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={4}
                    >
                      {data.length !== 0 ?(<div>
                        <Grid item xs={12}>
                        {data.map((i) => {
                         let slug = createSlug(i.article_title);
                        return <ButtonBase
                        component={Link} className="article_card"
                        to={`/article-details/${slug}/${i.article_id}`}
                      > <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                         spacing={2}
                         
                        style={{margin:"0px"}}
                        >
                          {i.article_images.split(",").length ===1?(
                        <Grid item xs={12}>
                          <Box
                              style={{
                                width: "100%",
                                // paddingBottom: "70%",
                                height: "300px",  
                                 position: "relative",
                              }}
                            >
                            <img 
                                src={`/static/articles/${i.article_images.split(",")[0]
                            }`}
                          
                                alt={data.article_title}
                                style={{ width: "100%", height: "100%", cursor: "pointer",objectFit: "cover" }}
                            />
                        </Box>
                        </Grid>
                        ):(
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                          >
                           <Grid item xs={12} sm={12} md={6}> 
                           <Box
                              style={{
                                width: "100%",
                                // paddingBottom: "70%",
                                height: "300px",  
                                 position: "relative",
                                 
                              }}
                            >
                                      <img 
                                          src={`/static/articles/${i.article_images.split(",")[0]
                                      }`}
                                      
                                          alt={i.article_title}
                                          style={{ width: "100%", height: "100%", cursor: "pointer",objectFit: "cover" }}
                                      />
                            </Box>        
                           </Grid>
                           <Hidden smDown>
                            <Grid item  md={6}  > 
                            {  i?.article_images.split(",").length >3 ? <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                              >
                                {  i?.article_images.split(",")
                              .slice(1, 5)
                              .map((j,  index) => (
                                <>
                               { index!== 3 && <Grid item   md={6}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "150px",  
                                      // paddingBottom: "70%",
                                      position: "relative",
                                    }}
                                  >
 
                                    <img
                                      src={`/static/articles/${j}`}
                                      
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        cursor: "pointer",
                                      }}
                                    />
                                </Box>
                               
                                </Grid>}
                                
                                { index=== 3 && <Grid item   md={6}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "150px",  
                                       position: "relative",
                                      cursor: "pointer",
                                    }}
                                    
                                  >
                                    
                                    <img
                                      src={`/static/articles/${j}`}
                                      
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        
                                        opacity:i.article_images.split(",").length > 5 ?"0.5":"1.0"   
                                      }}
                                    />
                                   {i?.article_images.split(",").length > 5 && <Typography
                                    variant="h2"
                                    style={{
                                      color: "#4c004c",
                                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                                      position: 'absolute', 
                                      top: '50%',           
                                      left: '50%',          
                                      transform: 'translate(-50%, -50%)',  
                                    }}
                                  >
                                    +
                                    {i?.article_images.split(",").length - 5}
                                  </Typography> }
                                </Box>
                               
                                </Grid>}
                                
                                </>
                              ))}

                              
                            </Grid>:<Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                              >
                                {  i?.article_images.split(",")
                              .slice(1, 3)
                              .map((j,  index) => (
                                <>
                               <Grid item   md={12}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "150px",  
                                      // paddingBottom: "70%",
                                      position: "relative",
                                    }}
                                  >
 
                                    <img
                                      src={`/static/articles/${j}`}
                                      
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        cursor: "pointer",
                                      }}
                                    />
                                </Box>
                               
                                </Grid> 
                                 
                                
                                </>
                              ))}

                              
                            </Grid>}
                            
                            </Grid>
                            </Hidden>             
                          </Grid>
                        </Grid>
                        )} 
                            <Grid item xs={12}>
                        {/* <div style={{   display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img 
                                src={`/static/articles/${i.article_images.split(",")[i.article_images.split(",").length-1]
                            }`}
                                alt={i.article_title}
                                style={{ width: "100%", height: "300px", objectFit: "contain" }}
                            />
                        </div> */}
                        </Grid>
                        <Grid item xs={12}>
                            <div class="article_title">
                                <h1 style={{color:"#4c004c"}}>{i.article_title}
                                    <span style={{marginTop:"5px"}}><b>Posted Date: </b> {i.article_published_date_text}</span>
                                </h1>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                        <p className="article_desc">{parse(
                                    i.article_desc
                                      .slice(0, 350)
                                      .concat(["..."])

                        )}</p>
                        </Grid>
                        <Grid item xs={12} style={{borderBottom:"2px Solid black"}}>

                        </Grid>
                        <Grid item xs={12}  >

                        </Grid>
                        </Grid></ButtonBase>
                      })}
                        </Grid>
                      </div>):(<div>
                        <Grid item xs={12}>
                            <p>No Articles Avaiable..</p>
                        </Grid>
                      </div>)}            
                    </Grid>
               
            <Slide
                direction="right"
                in={true}
                mountOnEnter
                unmountOnExit
                {...(true ? { timeout: 1000 } : {})}
              >
            <div >
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <blockquote className="article_desc">
              At Arawinz Soft Solutions, we are not just keeping pace with the ever-evolving digital landscape; we are at the forefront, crafting the future. Each update, every innovation, is a testament to our journey towards excellence. Our 'What's New' page is a showcase of this relentless pursuit – a window into how we're transforming ideas into cutting-edge solutions. It's where creativity meets technology, ensuring we're always a step ahead in delivering exceptional value to our clients. Join us here in witnessing the dawn of new possibilities and the unfolding of IT breakthroughs.
                <span>-&nbsp;Dr. P. Srinivas Rao, CEO</span>
              </blockquote>
              <br/>
              <br/>
            </div>
            </Slide>
          </Container>
          
          </Box>
        )}
     
      </div>
    );
  };
  
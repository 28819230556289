import {
  Box,
  IconButton,
  Typography,
  Container,
  Button,
  Grid,
  Dialog,
  DialogContent,
  TextField,
  MenuItem,
  Card,
  CardMedia,
  Snackbar,
} from "@material-ui/core";
import moment from 'moment';
import react, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import CKEditor from "react-ckeditor-component";
import {EditorCkMain} from './CkEditor';
import { Helmet } from "react-helmet";
import { Alert } from "@material-ui/lab";
 export const EditArticle = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [articles, setArticles] = useState([]);
  const [artical, setArtical] = useState([]);
  const [disablebutton, setDisablebutton] = useState(false);
  const [oldImages, setOldImages] = useState([]);
  const [imageids, setImageIds] = useState("");

  const [date, setDate] = useState("");
  const [status, setStatus] = useState(1);
  const history = useNavigate();

  // const [id, setId] = useState("")
  let id = useParams();

  const [item, setItem] = useState({
    category: "",
    title: "",
  });
  const [desc, setDesc] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [images, setImages] = useState([]);
  const [uploadwait, setUploadWait] = useState(false);
  const [err, setErr] = useState(0);
  const [snackBar, setSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("success");
  const [snackBarType, setSnackBarType] = useState("Invalid");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getdata1();
  }, []);

   const getdata1 = async () => {
    const formdata = new FormData();
    formdata.append("article_id", id.id);
    await axios.post("/api/get_single_article", formdata).then(function (res) {
       if (res.data.status === true) {
         
        setTitle(res.data.data.article_title);
        setContent(res.data.data.article_desc);

        setStatus(res.data.data.article_status);
        console.log(res.data.data.article_published_date,"chethan");

        // Assuming res.data.data.article_published_date = "15-11-2023 11:01:00"
var inputDateString = res.data.data.article_published_date;

// Specify the input format of the date string
var inputFormat = "DD-MM-YYYY HH:mm:ss";

// Parse the date string with the specified input format and then format it to the desired output format
var dateTime = moment(inputDateString, inputFormat).format("YYYY-MM-DDTHH:mm");
console.log(dateTime,"Sunny");
        // var dateTime = String(
        //   momentt(res.data.data.article_published_date).format("YYYY-MM-DDTHH:mm")
        // );
        
        setDate(dateTime);
        setImageIds(res.data.data.article_images);
          setOldImages(res.data.data.article_images?.split(','))
        // setOldImages(res.data.data.article_images?.split(",").filter((image) => image.trim() !== ""));
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const itemChange = (e) => {
    setItem({ ...item, [e.target.name]: e.target.value });
  };

  const imageChange = async (e) => {
    if (e.target.files.length != 0) {
      // console.log(oldImages.split(",").length)
      // if (oldImages.split(",").length + images.length + e.target.files.length <= 3) {
       if (oldImages.length + images.length + e.target.files.length <= 10) {
        var arr = [];

        for (var i = 0; i < e.target.files.length; i++) {
          var type = e.target.files[i].type;

          if (
            type === "image/jpeg" ||
            type === "image/jpg" ||
            type === "image/png"
          ) {
            const image = e.target.files[i];
            arr.push({ raw: image, preview: URL.createObjectURL(image) });
          } else {
            alert("Please select only JPEG, JPG, PNG Images..");
          }
        }
         setImages([...images, ...arr]);
      } else {
        alert("Maximum Image limit is 5.");
      }
    }
    e.target.value = "";
  };
 
  const openSnkbar = (type, msg) => {
    setSnackBarMsg(msg);
    setSnackBarType(type);
    setSnackBar(true);
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    setErr(0);
    if (title == "") {
      setErr(1);
      openSnkbar("error", "Please Enter Article Title....");
    }
    else if (date == "") {
      setErr(2);
      openSnkbar("error", "Please Enter Published Date....");
    }
    else if (oldImages.length === 0) {
      setErr(3);
      openSnkbar("error", "Please Select  Article Image....");
    }
    else if (content == "") {
      setErr(4);
      openSnkbar("error", "Please Enter Article Description....");
    }
    else {
      openSnkbar("warning", "Please wait.....");
      setDisablebutton(true);

      const formData = new FormData();
      for (let i = 0; i < images.length; i++) {
        formData.append("article_images", images[i].raw);
      }
      formData.append("article_id", id.id);
      formData.append("article_title", title);
      formData.append("article_desc", content);

      formData.append("article_published_date", date);

      formData.append("article_status", status);

      formData.append("article_images", imageids);
      await axios.post("/api/update_article", formData).then(function (res) {
        if (res.data.status === true) {
          // /deletecatimage
          setDisablebutton(false);

          openSnkbar("success", res.data.msg);

          history("/dash/allarticles");
        } else {
          setDisablebutton(false);

          openSnkbar("error", "Something went wrong... try later.");
        }
      });
    };
  }

  const handleChange = (evt, editor) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
  };
  const delete_image = async (image) => {
    // e.preventDefault();

    //console.log(id)
    //console.log(image)
    const formdata = new FormData();
    formdata.append("article_images", imageids);

    formdata.append("image_to_delete", image);
    formdata.append("article_id", id.id);
    await axios.post("/api/delete_image", formdata).then(function (res) {
      if (res.data.status === true) {
        openSnkbar("success", res.data.msg);
        getdata1();
      } else {
        openSnkbar("error", res.data.msg);
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>Edit Article | Buy or Sell or Rent Property Online</title>
      </Helmet>
      <Grid container justifyContent="center" alignItems="flex-start">
        
        <Grid item xs={12} sm={10} p={2}>
          {loading ? (
            <p style={{ textAlign: 'center' }}>Fetching Please Wait... </p>
          ) : (
            <>
              <Container maxWidth="lg">
                <Box >
                  <Snackbar
                    open={snackBar}
                    autoHideDuration={4000}
                    onClose={() => {
                      setSnackBar(false);
                    }}
                  >
                    <Alert
                      onClose={() => {
                        setSnackBar(false);
                      }}
                      severity={snackBarType}
                      style={{
                        color: snackBarType === "warning" ? "black" : "white",
                      }}
                    >
                      {snackBarMsg}
                    </Alert>
                  </Snackbar>


                 
                    <Box
                      style={{
                        padding: '20px',
                        textAlign: "center",
                        border: "1px solid #060847",
                      }}
                    >
                   
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="flex-start"
                          spacing={4}
                        >
                          <Grid item xs={12} >
                            <h2
                              style={{
                                textDecoration: "underline",
                                marginBottom: "0px",
                                textAlign: "center",
                              }}
                            >
                              Update Article
                            </h2>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                              id="filled-required"
                              label="Title"
                              variant="filled"
                              error={err === 1 && true}
                              value={title}
                              size="small"
                              onChange={(e) => {
                                setTitle(e.target.value);
                              }}
                              required
                              fullWidth
                            />
                          </Grid>


                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                              variant="filled"
                              id="datetime-local"
                              label="Published Date *"
                              type="datetime-local"
                              fullWidth={true}
                              size="small"
                              value={date}
                              name="date"
                              placeholder="Published Date *"
                              dateFormat="yyyy-mm-dd"
                              error={err === 2 && true}
                              onChange={(e) => setDate(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                              id="filled-required"
                              label="Status"
                              variant="filled"
                              value={status}
                              size="small"
                              onChange={(e) => {
                                setStatus(e.target.value);
                              }}
                              required
                              fullWidth
                              select
                            >
                              <MenuItem value={1}>Active</MenuItem>
                              <MenuItem value={2}>In-Active</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Button
                              variant="contained"
                              onChange={imageChange}
                              fullWidth
                              error={err == 5 && true}
                              color="primary" component="label"
                            >
                              upload image *
                              <input hidden type="file" multiple />
                            </Button>
                          </Grid>
                          {uploadwait ? (
                            <span style={{ color: "white" }}>
                              <br />
                              <br />
                              Upload Images...
                            </span>
                          ) : (
                            <>
                              {images?.length !== 0 ? (
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={2}
                                  >
                                    {images.map((i) => {
                                      return (
                                        <Grid item>
                                          <Card>
                                            <CardMedia
                                              style={{
                                                height: 250,
                                                width: 250,
                                              }}
                                              image={i.preview}
                                              title={i.preview}
                                            />
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              component="span"
                                              style={{
                                                border: "1px solid black",

                                              }}
                                              fullWidth
                                              onClick={() => {
                                                setImages(
                                                  images.filter(
                                                    (item) =>
                                                      item.preview !==
                                                      i.preview
                                                  )
                                                );
                                              }}
                                            >
                                              DELETE IMAGE
                                            </Button>
                                          </Card>
                                        </Grid>
                                      );
                                    })}
                                  </Grid>
                                </Grid>
                              ) : (
                                ""
                              )}
                            </>
                          )}

                          {oldImages?.length > 0 && (
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                              >
                                {oldImages.map((image) => {
                                  return (
                                    <Grid item>
                                      <Card
                                        style={{
                                          border: "1px solid black",
                                          color: "#060847",
                                          "&:hover": { color: "#060847" },
                                        }}
                                      >
                                        <CardMedia
                                          style={{ height: 250, width: 250 }}
                                          image={`/static/articles/${image}`}
                                        />

                                        {oldImages.length !== 1 && (
                                          <Button
                                            variant="contained"
                                            component="span"
                                            style={{
                                              border: "1px solid black",
                                            }}
                                            fullWidth
                                            onClick={() => {
                                              delete_image(image);
                                            }}
                                          >
                                            DELETE IMAGE
                                          </Button>
                                        )}
                                      </Card>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          )}
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <EditorCkMain
                              desc={content}
                              setDescr={(e) => setContent(e)}
                            /> 
                            {/* <CKEditor
                              activeClass="p10"
                              content={content}
                              events={{
                                change: handleChange,
                              }}
                              config={{
                                toolbar: [
                                  {
                                    name: "document",
                                    items: [
                                      "Source",
                                      "-",
                                      "NewPage",
                                      "Preview",
                                      "-",
                                      "Templates",
                                    ],
                                  },
                                  {
                                    name: "clipboard",
                                    items: [
                                      "Cut",
                                      "Copy",
                                      "Paste",
                                      "PasteText",
                                      "PasteFromWord",
                                      "-",
                                      "Undo",
                                      "Redo",
                                    ],
                                  },
                                  {
                                    name: "styles",
                                    items: [
                                      "Styles",
                                      "Format",
                                      "Font",
                                      "FontSize",
                                    ],
                                  },
                                  {
                                    name: "basicstyles",
                                    items: [
                                      "Bold",
                                      "Underline",
                                      "Italic",
                                      "Strike",
                                      "Subscript",
                                      "Superscript",
                                      "-",
                                      "RemoveFormat",
                                    ],
                                  },
                                  {
                                    name: "colors",
                                    items: [
                                      "fontColor",
                                      "fontBackgroundColor",
                                    ],
                                  },
                                  {
                                    name: "links",
                                    items: ["Link", "Unlink", "Anchor"],
                                  },
                                  {
                                    name: "insert",
                                    items: [
                                      "Image",
                                      "Table",
                                      "HorizontalRule",
                                      "SpecialChar",
                                    ],
                                  },
                                  { name: "tools", items: ["Maximize"] },
                                  { name: "others", items: ["-"] },
                                  { name: "about", items: ["About"] },
                                ],
                                height: 300,
                                width: '100%',
                              }}
                            /> */}
                          </Grid>






                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button
                              onClick={onSubmit}
                              variant="contained"
                              color="primary"
                              disabled={disablebutton}
                            >
                              {disablebutton
                                ? "Please wait.."
                                : "Update Article"}
                            </Button>
                          </Grid>
                        </Grid>
                    
                     
                    </Box>
             
                </Box>
              </Container>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

import {
  Container,
  Grid,
  Slide,
  Paper,
  Box,
  useTheme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useState, useEffect } from "react";

import Axios from "axios";
import { Helmet } from "react-helmet-async";
import { Loading } from "./loading";

import LanguageIcon from "@material-ui/icons/Language";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";

const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";

const useStyles = makeStyles({
  cardshadow: {
    "&:hover": {
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    },
  },
});

export const OurWorks = () => {
  const theme = useTheme();

  const classes = useStyles();

  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [isloading, setIsloading] = useState(true);

  const handleClick = () => {
    const anchor = document.querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    getMetaData();
  }, []);

  const getMetaData = () => {
    const formData = new FormData();
    formData.append("page", 4);
    Axios.post("/api/getmetadata", formData, {
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.data.status) {
        setMetaTitle(res.data.data.meta_title);
        setMetaKeywords(res.data.data.meta_keywords);
        setMetaDesc(res.data.data.meta_desc);
        setIsloading(false);
        handleClick();
      }
    });
  };

  const jumbotroncss = {
    backgroundImage:
      'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url("/static/works.jpg")',
    opacity: 0.8,
    backgroundAttachment: "fixed",
    color: "white",
    minHeight: "250px",
    //marginTop: "0px",
    marginBottom: "8px",
  };

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
        <meta name="keywords" content={metaKeywords}></meta>
      </Helmet>
      <div className="jumbotron text-center" style={jumbotroncss}>
        <Paper style={{ opacity: "0.5" }}>
          <Typography
            variant="h2"
            style={{ color: theme.palette.secondary.main }}
          >
            Our Works
          </Typography>

          <br />
        </Paper>
      </div>
      {isloading ? (
        <Loading />
      ) : (
        <Box
          boxShadow={3}
          p={3}
          m={4}
          style={{ backgroundColor: theme.palette.cream.main }}
        >
          <Container maxWidth="lg">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={5}
            >
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <Slide
                  direction="left"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  {...(true ? { timeout: 1000 } : {})}
                >
                  <Paper
                    className={classes.cardshadow}
                    style={{
                      padding: "3%",
                      border: "1px groove #4c004c",
                      backgroundColor: theme.palette.cream.main,
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-around"
                      alignItems="stretch"
                    >
                      <Grid
                        item
                        xs={12}
                        className="text-center"
                        style={{ borderBottom: " 2px groove #4c004c" }}
                      >
                        <img
                          src="/static/works/wahasantha.png"
                          style={{ width: "50%", height: "50%", padding: "2%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <br />
                        <Typography
                          variant="h5"
                          style={{ color: theme.palette.secondary.main }}
                          gutterBottom
                        >
                          WahaSantha
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <h6 className="display1">
                          <u style={{ color: theme.palette.secondary.main }}>
                            Category:
                          </u>{" "}
                          E-commerce
                        </h6>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          style={{ textAlign: "justifyContent" }}
                          gutterBottom
                        >
                          &nbsp;&nbsp;WahaSantha is a place where a variety of
                          needs are fulfilled from a child's to an adult, we
                          provide quality service ranging from handmade toys to
                          handlooms as well as essential food items such as
                          spices, dry fruits, pickles, and nonvegetarian items
                          like fish, dried prawns and much more.
                        </Typography>
                        <br />
                      </Grid>
                      <Grid item xs={12}>
                        <h6
                          className="display1"
                          style={{ color: theme.palette.secondary.main }}
                        >
                          <u>Available in:</u>
                        </h6>
                        <Grid item xs={12}>
                          <span style={{ padding: "2%" }}>
                            <a href="https://wahasantha.com" target="_blank">
                              <LanguageIcon />
                            </a>
                          </span>
                          <span style={{ padding: "2%" }}>
                            <a
                              href="https://play.google.com/store/apps/details?id=com.arawinz.wahasantha"
                              target="_blank"
                            >
                              <AndroidIcon style={{ color: "green" }} />
                            </a>
                          </span>
                          <span style={{ padding: "2%" }}>
                            <a
                              href="https://apps.apple.com/us/app/wahasantha/id1561768630"
                              target="_blank"
                            >
                              <AppleIcon color="primary" />
                            </a>
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Slide>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <Slide
                  direction="left"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  {...(true ? { timeout: 1000 } : {})}
                >
                  <Paper
                    className={classes.cardshadow}
                    style={{
                      padding: "3%",
                      border: "1px groove #4c004c",
                      backgroundColor: theme.palette.cream.main,
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-around"
                      alignItems="stretch"
                    >
                      <Grid
                        item
                        xs={12}
                        className="text-center"
                        style={{ borderBottom: " 2px groove #4c004c" }}
                      >
                        <img
                          src="/static/works/earnon.png"
                          style={{ width: "50%", height: "50%", padding: "2%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <br />
                        <Typography
                          variant="h5"
                          style={{ color: theme.palette.secondary.main }}
                          gutterBottom
                        >
                          EarnOn
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <h6 className="display1">
                          <u style={{ color: theme.palette.secondary.main }}>
                            Category:
                          </u>{" "}
                          Consulting
                        </h6>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          style={{ textAlign: "justifyContent" }}
                          gutterBottom
                        >
                          &nbsp;&nbsp;EarnOn enables you to earn Referral Money
                          by referring your friends, colleagues, relatives, or
                          known contacts who require a Job, Housing, Financial
                          Services, etc. <br /> &nbsp;&nbsp;The user can apply
                          himself/herself for a Job or choose real estate or
                          choose an Investment plan which is available in the
                          EarnOn Application. After giving the user's details,
                          the EarnOn backend team will initiate the process.
                        </Typography>
                        <br />
                      </Grid>

                      <Grid item xs={12}>
                        <h6
                          className="display1"
                          style={{ color: theme.palette.secondary.main }}
                        >
                          <u>Available in:</u>
                        </h6>
                        <Grid item xs={12}>
                          <span style={{ padding: "2%" }}>
                            <a href="https://earnon.org" target="_blank">
                              <LanguageIcon />
                            </a>
                          </span>
                          <span style={{ padding: "2%" }}>
                            <a
                              href="https://play.google.com/store/apps/details?id=com.appp.earnon"
                              target="_blank"
                            >
                              <AndroidIcon style={{ color: "green" }} />
                            </a>
                          </span>
                          <span style={{ padding: "2%" }}>
                            <a
                              href="https://apps.apple.com/us/app/earnon/id1561003242"
                              target="_blank"
                            >
                              <AppleIcon color="primary" />
                            </a>
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Slide>
              </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <Slide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              {...(true ? { timeout: 2000 } : {})}
            >
              <Typography
                variant="h3"
                style={{ color: theme.palette.secondary.main }}
                gutterBottom
              >
                Other Works:
              </Typography>
            </Slide>

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={5}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Slide
                  direction="bottom"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  {...(true ? { timeout: 2100 } : {})}
                >
                  <Paper
                    className={classes.cardshadow}
                    style={{
                      padding: "3%",
                      border: "1px groove #4c004c",
                      backgroundColor: theme.palette.cream.main,
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-around"
                      alignItems="stretch"
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          style={{ color: theme.palette.secondary.main }}
                          gutterBottom
                        >
                          IRISHEALTHCAREINDIA
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <h6 className="display1">
                          <u style={{ color: theme.palette.secondary.main }}>
                            Category:
                          </u>{" "}
                          Health & Medical
                        </h6>
                      </Grid>
                      <Grid item xs={12}>
                        <p style={{ textAlign: "justifyContent" }}>
                          &nbsp;&nbsp;An Online Consultation Portal for a Group
                          of General Physicians in Hyderabad.
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <h6 className="display1">
                          <u style={{ color: theme.palette.secondary.main }}>
                            Status:
                          </u>{" "}
                          Currently Inactive
                        </h6>
                      </Grid>
                    </Grid>
                  </Paper>
                </Slide>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Slide
                  direction="bottom"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  {...(true ? { timeout: 2100 } : {})}
                >
                  <Paper
                    className={classes.cardshadow}
                    style={{
                      padding: "3%",
                      border: "1px groove #4c004c",
                      backgroundColor: theme.palette.cream.main,
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-around"
                      alignItems="stretch"
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          style={{ color: theme.palette.secondary.main }}
                          gutterBottom
                        >
                          Differently Abled Special Recruitment Drive 2020-21
                          SPSR Nellore Dist
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <h6 className="display1">
                          <u style={{ color: theme.palette.secondary.main }}>
                            Category:
                          </u>{" "}
                          Goverment Project
                        </h6>
                      </Grid>
                      <Grid item xs={12}>
                        <p style={{ textAlign: "justifyContent" }}>
                          &nbsp;&nbsp;A Job Application portal for Differently
                          Abled in Nellore Region.
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <h6 className="display1">
                          <u style={{ color: theme.palette.secondary.main }}>
                            Status:
                          </u>{" "}
                          Was Active from Feb 2021 to March 2021{" "}
                        </h6>
                      </Grid>
                    </Grid>
                  </Paper>
                </Slide>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
      <br />
      <br />
    </div>
  );
};

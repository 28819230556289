import {
  Container,
  Grid,
  Box,
  Button,
  Slide,
  Paper,
  Typography,
  useTheme,
  Snackbar,
  makeStyles,IconButton
} from "@material-ui/core";
import { useState, useEffect } from "react";
import parse from "html-react-parser";
import Axios from "axios";
import LinkIcon from '@material-ui/icons/Link';
import { Helmet } from "react-helmet-async";

import { Loading } from "./loading";

import PhoneIcon from "@material-ui/icons/Phone";

import { Link, useParams } from "react-router-dom";

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
 // RedditShareButton,
 // RedditIcon,
  XIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
 // ViberShareButton,
  WhatsappShareButton,
  WhatsappIcon,RedditIcon, RedditShareButton
  
} from "react-share";
import MuiAlert from "@material-ui/lab/Alert";

const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";

const useStyles = makeStyles({
  cardshadow: {
    "&:hover": {
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    },
  },
});


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export const ServiceDetails = () => {
  const classes = useStyles();
  const theme = useTheme();
  let { id } = useParams();
  const url=window.location.href;
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [isloading, setIsloading] = useState(true);
  const [service, setService] = useState(true);


  const [snackBar, setSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("success");
  const [snackBarType, setSnackBarType] = useState("Invalid");

  const openSnkbar = (type, msg) => {
    setSnackBarMsg(msg);
    setSnackBarType(type);
    setSnackBar(true);
  };


  const handleClick = () => {
    const anchor = document.querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    getMetaData();
  }, []);

  const getMetaData = () => {
    const formData = new FormData();
    formData.append("id", id);

    Axios.post("/api/getsingleservice", formData, {
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.data.status) {
        setMetaTitle(res.data.data.serv_title);
        setMetaKeywords(res.data.data.serv_keywords);
        setMetaDesc(res.data.data.serv_metadesc);
        setService(res.data.data);
        setIsloading(false);
        handleClick();
      }
    });
  };

  const jumbotroncss = {
    backgroundImage:
      'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url("/static/services/' +
      service.serv_image +
      '")',
    opacity: 0.8,
    backgroundAttachment: "fixed",
    color: "white",
    minHeight: "250px",
    //marginTop: "0px",
    marginBottom: "0px",
    borderRadius:"0px"
    
  };

  return (
    <div>
      {" "}
      {service && !isloading ? (
        <div>
          <Helmet>
            <title>{metaTitle + " | Arawinz Soft Solutions"}</title>
            <meta name="description" content={metaDesc} />
            <meta name="keywords" content={metaKeywords}></meta>
          </Helmet>
          <div className="jumbotron text-center" style={jumbotroncss}>
            <Paper style={{ opacity: "0.8" }}>
              <Grid
                container
                spacing={30}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {" "}
                {service.serv_name.split(" ").length === 1 ? (
                  <>
                    <Typography
                      variant="h2"
                      style={{
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                        letterSpacing: 4,
                      }}
                    >
                      {service.serv_name.charAt(0)}
                    </Typography>
                    <Typography
                      variant="h2"
                      style={{
                        color: "white",
                        fontWeight: 500,
                        WebkitTextStrokeWidth: 2,
                        letterSpacing: 4,
                        WebkitTextStrokeColor: theme.palette.primary.main,
                      }}
                    >
                      {service.serv_name.slice(1)}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      variant="h2"
                      style={{
                        color: "white",
                        fontWeight: 500,
                        WebkitTextStrokeWidth: 2,
                        letterSpacing: 4,
                        WebkitTextStrokeColor: theme.palette.primary.main,
                      }}
                    >
                      {service.serv_name.split(" ")[0]}
                    </Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Typography
                      variant="h2"
                      style={{
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                        letterSpacing: 4,
                      }}
                    >
                      {service.serv_name.split(" ").slice(1).join(" ")}
                    </Typography>
                    {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Typography
                      variant="h2"
                      style={{
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                        letterSpacing: 4,
                      }}
                    >
                      {service.serv_name.split(" ")[2]}
                    </Typography> */}
                  </>
                )}
              </Grid>
              <br />
            </Paper>
          </div>
          
          {isloading ? (
            <Loading />
          ) : (
            
              <Box
                boxShadow={3}
                p={3}
               
                style={{ backgroundColor: theme.palette.cream.main }}
                className={classes.cardshadow}
              >
                <br />
          <br />
                <Container maxWidth="lg"> 
                <Snackbar
                      open={snackBar}
                      autoHideDuration={4000}
                      onClose={() => {
                        setSnackBar(false);
                      }}
                    >
                      <Alert
                        onClose={() => {
                          setSnackBar(false);
                        }}
                        severity={snackBarType}
                        style={{
                          color: snackBarType === "warning" ? "black" : "white",
                        }}
                      >
                        {snackBarMsg}
                      </Alert>
                    </Snackbar>
                {service && (
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={5}
                  >
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ width: "100%" }}
                    >
                      <Slide
                        direction="up"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                        {...(true ? { timeout: 1000 } : {})}
                      >
                        <div class="service_details_title">
                          <h1>{service.serv_name}</h1>
                        </div>
                        {/* <Typography
                          variant="h3"
                          style={{ color: theme.palette.secondary.main }}
                        >
                          :
                        </Typography> */}
                      </Slide>
                    </Grid>
                    <Grid item xs={12} style={{width:"100%", textAlign:'right',letterSpacing: '3px',textTransform: 'uppercase'}}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="body1" gutterBottom  style={{color:theme.palette.secondary.main   }}>
                                      <b>Tell Others About This Service:  </b>
                        <FacebookShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <FacebookIcon size={30} round={true}/>
                        </FacebookShareButton>      
                        <LinkedinShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <LinkedinIcon size={30} round={true}/>
                        </LinkedinShareButton>   
                        <TwitterShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <XIcon size={30} round={true}/>
                        </TwitterShareButton>    
                         
                        <WhatsappShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <WhatsappIcon size={30} round={true}/>
                        </WhatsappShareButton>
                        <RedditShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <RedditIcon size={30} round={true}/>
                        </RedditShareButton>
                        <TelegramShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <TelegramIcon size={30} round={true}/>
                        </TelegramShareButton>
                        <EmailShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <EmailIcon size={30} round={true}/>
                        </EmailShareButton>
                        {/* <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(url)
                              .then(() => openSnkbar("success", "Link copied to clipboard!") )
                              .catch(() => {

                              });
                          }}
                          aria-label="copy link"
                        >
                        <LinkIcon fontSize="medium"/>
                        </IconButton> */}
                        </Typography>
                        
                     </Grid>
                    </Grid>
                    
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Slide
                        direction="up"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                        {...(true ? { timeout: 1000 } : {})}
                      >
                        <Typography
                          variant="body1"

                          className="article_desc"
                          style={{ textAlign: "left" }}
                          gutterBottom
                        >
                          {parse(service.serv_desc)}
                        </Typography>
                      </Slide>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Slide
                  direction="up"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  {...(true ? { timeout: 1000 } : {})}
                >
                   <div className="twelve">
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography style={{letterSpacing: "4px",padding:"5px"}}>Tell us your Requirement...</Typography> 
                      
                      <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        to={"/get-in-touch"}
                        className="linkstyle"
                        style={{margin:"10px",textAlign:"center"}}
                      >
                        <PhoneIcon />
                        &nbsp;&nbsp;<Typography style={{letterSpacing: "4px"}}>Contact Us</Typography>
                      </Button>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                    </Grid>
                    </div>
                </Slide>
                    </Grid>
                  </Grid>
                )}
                </Container>
                <br />
          <br />
              </Box>
            
          )}
         
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

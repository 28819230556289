import {Container,Grid,Box,Button,makeStyles,useTheme,Slide,Typography,Paper} from '@material-ui/core';
import {useState,useEffect } from 'react';

import {Link} from 'react-router-dom';
import ReplayIcon from '@material-ui/icons/Replay';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import Axios from 'axios';
import { Helmet  } from 'react-helmet-async';

import {Loading} from './loading';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';


const headKey="qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";

const useStyles = makeStyles({
   
  cardshadow: {
    "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
  },
});

export const Default =()=>{

    const theme = useTheme();

    const classes = useStyles();
 console.log("default")

    const [metaTitle,setMetaTitle] = useState("");
    const [metaDesc,setMetaDesc] = useState("");
    const [metaKeywords,setMetaKeywords] = useState("");
    const [isloading,setIsloading] = useState(true);
     

    
   
      const handleClick = ( ) => {
        const anchor = (document).querySelector('#back-to-top-anchor');
    
        if (anchor) {
          anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      };

      useEffect(()=>{
        getMetaData();
     },[]);

     const getMetaData = ()=>{
      const formData = new FormData();
      formData.append('page',6);
      Axios.post("/api/getmetadata", formData,{   
         headers: {headKey:headKey},
      }).then((res) => {
        if(res.data.status){
          setMetaTitle(res.data.data.meta_title);
          setMetaKeywords(res.data.data.meta_keywords);
          setMetaDesc(res.data.data.meta_desc);
          setIsloading(false);
          handleClick();
           
        } 
      });  
     }

 

    const jumbotroncss={
      backgroundImage: 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url("/static/lost.jpg")',
      opacity: 0.8,
      backgroundAttachment: 'fixed',
      color: 'white',
      minHeight: "250px",
      //marginTop: "0px",
      marginBottom: "8px",
    };


    return ( <div>
      <Helmet>
                    <title>404 | Are you lost ? | Arawinz soft solutions</title>
                    <meta name="description" content={metaDesc} />
                    <meta name="keywords" content={metaKeywords}></meta>
                </Helmet>
      <div className="jumbotron text-center" style={jumbotroncss} >
      <Paper style={{opacity:"0.5"}}>
           
           <Typography variant="h2" style={{color:theme.palette.secondary.main}}>
           Are you Lost ?
           </Typography>
            
           <br/>
           </Paper>
             
             
      </div><br/>
      {isloading?(<Loading />):(
       <Container maxWidth="lg">
         <Slide direction="down" in={true} mountOnEnter unmountOnExit {...(true ? { timeout: 1000 } : {})}>
       <Box   boxShadow={3} p={3} m={4} style={{backgroundColor:theme.palette.cream.main }}  className={classes.cardshadow}  >
       <Typography variant="h6" className="text-center" style={{fontWeight:"normal"}} >
       &nbsp;&nbsp;  You must be Lost Buddy....... <SentimentDissatisfiedIcon />
       </Typography>
        
         
         
        <br />
        <br />
        <Slide direction="up" in={true} mountOnEnter unmountOnExit {...(true ? { timeout: 1400 } : {})}>
                        <Paper style={{padding:"20px",margin:"0px",border:"1px groove #4c004c"}} className={classes.cardshadow}>                    <p style={{textAlign:"center"}}>
                            <Button   color="primary" variant="contained" component={Link} to={"/"}  className="linkstyle">
                             Go to Homepage&nbsp;&nbsp;<ReplayIcon />
                                </Button></p>
                                </Paper>

                    </Slide>
         
       </Box>
       </Slide>
         
         
        </Container>)}
        <br />
        <br />
        </div>
   );
}
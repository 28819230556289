import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Snackbar,
  Switch,
  MenuItem,
  Card,
  CardMedia,
} from "@material-ui/core";
import { useState } from "react";
import CKEditor from "react-ckeditor-component";
import MuiAlert from "@material-ui/lab/Alert";

import Axios from "axios";
import { Helmet } from "react-helmet";
import Alert from "@material-ui/lab/Alert";
import { useNavigate } from "react-router-dom";
import {EditorCkMain} from './CkEditor';


export const AddArticle = () => {
  const [title, setTitle] = useState("")
  const [content, setContent] = useState("");
  const [articles, setArticles] = useState([])
  const [artical, setArtical] = useState([])
  const [err, setErr] = useState(0);
  const [disablebutton, setDisablebutton] = useState(false);
  const [snackBar, setSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("success");
  const [snackBarType, setSnackBarType] = useState("Invalid");
  const [date, setDate] = useState("")
  const [status, setStatus] = useState(1)
    const navigate = useNavigate()
  const openSnkbar = (type, msg) => {
    setSnackBarMsg(msg);
    setSnackBarType(type);
    setSnackBar(true);
  };


  const handleChange = (evt, editor) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
  };

  
  const onSubmit = () => {
    setErr(0);
    if (title == "") {
      setErr(1);
      openSnkbar("error", "Please Enter Article Title....");
    } 
    else if (date == "") {
      setErr(2);
      openSnkbar("error", "Please Enter Published Date....");
    }
   else if (articles.length == 0) {
      setErr(3);
      openSnkbar("error", "Please Select  Article Image....");
    } 
    else if (content == "") {
      setErr(4);
      openSnkbar("error", "Please Enter Article Description....");
    } 
    else {
      openSnkbar("warning", "Please wait.....");
      setDisablebutton(true);
      const formData = new FormData();
      for (let i = 0; i < articles.length; i++) {
        console.log(articles[i].raw)
        formData.append("article_images", articles[i].raw)
      }
      formData.append("article_title", title);
      formData.append("article_desc", content);

      formData.append("article_published_date", date);

      formData.append("article_status", status);
      console.log(artical)


      console.log(artical)
      Axios.post("/api/add_article", formData).then((res) => {
        if (res.data.status) {
          setDisablebutton(false);
          openSnkbar("success", res.data.msg);
          navigate("/dash/allarticles");
        } else {
          setDisablebutton(false);
          openSnkbar("error", "Something went wrong... try later.");
        }
      });
    }
  }
  return (
    <Container  maxWidth="lg">

      <Box boxShadow={3} p={3} m={4} style={{ border: "2px solid #333333" }}>
        <Snackbar
          open={snackBar}
          autoHideDuration={4000}
          onClose={() => {
            setSnackBar(false);
          }}
        >
          <Alert
            onClose={() => {
              setSnackBar(false);
            }}
            severity={snackBarType}
            style={{
              color: snackBarType === "warning" ? "black" : "white",
            }}
          >
            {snackBarMsg}
          </Alert>
        </Snackbar>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h2
              style={{
                textDecoration: "underline",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Add Article
            </h2>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="filled-required"
              label="Title"
              variant="filled"
              error={err === 1 && true}
              value={title}
              size="small"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
            
            <TextField variant="filled"
             id="datetime-local"
              label="Published Date *"
              type="datetime-local"
              fullWidth={true}
              size="small"
              value={date}
              name="date"
              placeholder="Published Date *"
              dateFormat="yyyy-mm-dd"
              error={err === 2 && true}
              onChange={(e) => setDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}

            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
            <TextField
              id="filled-required"
              label="Status"
              variant="filled"
              value={status}
              size="small"
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              required
              fullWidth
              select
            >
              <MenuItem value={1}>Active</MenuItem>
              <MenuItem value={2}>In-Active</MenuItem>

            </TextField>
          </Grid>
          <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
          <input
              accept="image/*"
              multiple
              style={{ display: "none" }}
              onChange={(e) => {
                if (e.target.files.length != 0) {
                  const files = e.target.files;
                  const fileArray = [];

                  for (let i = 0; i < files.length; i++) {
                    const image = files[i];
                    var type = files.type
                    fileArray.push({ raw: image, url: URL.createObjectURL(image) });
                    setArticles([...articles, ...fileArray]);
                  }
                }
              }}
              id="contained-button-file"
              type="file"
            />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span" fullWidth>
                Upload Image
              </Button>
            </label>
            <br />
            <br />
            {err === 3 && (
              <div style={{ color: "red" }}>Please select a file.</div>
            )}
          
          

       
          </Grid>
          {articles.length != 0 && articles.map((article) => (
               <Grid item md={4} lg={3} xl={3} sm={6} xs={6}>
              <Card >
                              <CardMedia
                                  style={{ width:"250px", height:"250px" }}
                                image={article.url}
                                title={article.preview}
                              />
                              <Button variant="outlined" color="primary" component="span" style={{ border: "1px solid black",color:"#060847","&:hover":{color:"#060847"}  }} fullWidth onClick={() => {

setArticles(articles.filter(item => item.url !== article.url));
                               
                              }}>
                                DELETE IMAGE
                              </Button>
                            </Card>
              </Grid>
            ))}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <EditorCkMain
                              desc={content}
                              setDescr={(e) => setContent(e)}
                            /> 
          {/* <CKEditor  activeClass="p10"  content={content}  events={{
              change: handleChange,
            }}
            config={{
              toolbar: [
                {
                  name: "document",
                  items: [
                    "Source",
                    "-",
                    "NewPage",
                    "Preview",
                    "-",
                    "Templates",
                  ],
                },
                {
                  name: "clipboard",
                  items: [
                    "Cut",
                    "Copy",
                    "Paste",
                    "PasteText",
                    "PasteFromWord",
                    "-",
                    "Undo",
                    "Redo",
                  ],
                },
                {
                  name: "styles",
                  items: ["Styles", "Format", "Font", "FontSize"],
                },
                {
                  name: "basicstyles",
                  items: [
                    "Bold",
                    "Underline",
                    "Italic",
                    "Strike",
                    "Subscript",
                    "Superscript",
                    "-",
                    "RemoveFormat",
                  ],
                },
                {
                  name: "colors",
                  items: ["fontColor", "fontBackgroundColor"],
                },
                { name: "links", items: ["Link", "Unlink", "Anchor"] },
                {
                  name: "insert",
                  items: ["Image", "Table", "HorizontalRule", "SpecialChar"],
                },
                { name: "tools", items: ["Maximize"] },
                { name: "others", items: ["-"] },
                { name: "about", items: ["About"] },
              ],
              // height: 300,
              // width:800,
            }}
            /> */}
          
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign:"center"}} >
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              disabled={disablebutton}
            >
              {disablebutton ? "Please wait.." : "Add Article"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )

}
import { Box, Button } from "@material-ui/core";
import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { Loading } from "./loading";

import { UserContext } from "./UserContext";
import { Helmet } from "react-helmet";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";

const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";
const $ = require("jquery");
export const AllClients = () => {
  let navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  useEffect(() => {
    getData();
  }, []);

  const getData = (id) => {
    Axios.get("/api/select_clients", {
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.status === 200) {
        console.log(res.data.data);
        setData(res.data.data);
        setLoading(false);
        $(document).ready(function () {
          $("#table_id").DataTable({
            "bDestroy": true,
            scrollX:1700,
            scrollY:500,
          });
        });
      } else {
        setUser(null);

        navigate("/");
      }
    });
  };
  const deActivate = (id) => {
    console.log(id)
    const formdata = new FormData();
    formdata.append("idd", id);
    Axios.post("/api/client_status", formdata,{
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.status === 200) {
        console.log(res.data.status);
        getData()
      } else {
        console.log("failed");
      }
    });
  };
  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Helmet>
            <title>All Clients - Admin | Arawinz Soft Solutions</title>
            <meta
              name="description"
              content="Arawinz Soft Solutions is one of the finest Software Development company. We provide services in Web Development-Full Stack, Web Designing (Static & Responsive), Mobile App Development, Social Media Marketing, SEO, Data Analytics,  CRM, ERP, Mobile Gaming and Artificial Intelligence."
            />
            <meta
              name="keywords"
              content=" Testimonials Top Web Development company in Gachibowli, SEO companies in Hyderabad, Best Application development company in India, Top 5 development companies in Vizag, Digital and Social Media Marketing, Data Analytics company in Hitech city, Graphics Design and Gaming company in Jubilee hills, Best Artificial Intelligence company in Hyderabad, Top gaming organization near me, Available ERP &amp; CRM developers Madhapur"
            ></meta>
          </Helmet>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              component={Link}
              color="primary"
              to={"/dash/addclient"}
            >
              Add Client
            </Button>
          </div>
          <br />
          {/* <Box sx={{width:"95%"}}> */}
          <table id="table_id" className="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th width="20%">IMAGE</th>
                <th width="15%">URL</th>
                <th>STATUS</th>
                <th>PRIORITY</th>
                <th width="15%">ADDED DATE</th>
                <th>EDIT</th>
                <th>DEACTIVATE</th>
              </tr>
            </thead>
            <tbody>
              {data?.length > 0 &&
                (data || []).map((da) => {
                  return (
                    <tr>
                      <td>{da.id}</td>
                      <td>{da.name}</td>
                      <td>
                        <img
                          src={"/static/clients/" + da.image_url}
                          style={{ height: "150px" }}
                        />
                      </td>
                      <td>{da.site_url}</td>
                      <td>{da.status === 1 ? "Active" : "InActive"}</td>
                      <td>{da.priority}</td>
                      <td>
                          { da.added_date  }
                        </td>
                      <td>
                        <Button
                          variant="contained"
                          component={Link}
                          color="primary"
                          to={"/dash/updateclient/" + da.id}
                          fullWidth
                        >
                          EDIT
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => {
                            console.log(da.id)
                            deActivate(da.id)}}
                        >
                        {da.status === 1 ? "DEACTIVATE" : "ACTIVATE"}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tfoot>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th width="20%">IMAGE</th>
                <th width="15%">URL</th>
                <th>STATUS</th>
                <th>PRIORITY</th>
                <th width="15%">ADDED DATE</th>
                <th>EDIT</th>
                <th>DEACTIVATE</th>
              </tr>
            </tfoot>
          </table>
          {/* </Box> */}
        </div>
      )}
    </div>
  );
};

import {Container,Grid,Box,TextField,Button,Snackbar, Switch,MenuItem} from '@material-ui/core';
import {useState,useEffect } from 'react';

import MuiAlert from '@material-ui/lab/Alert';
import Axios from 'axios';
import {Loading} from './loading';
import {Helmet} from "react-helmet";
import {useParams} from 'react-router-dom';

const headKey="qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


let greenColor={
    color:"green",
    fontWeight: 'bold'
   };
  
   let blackColor={
    color:"black"
   };


export const EditFeedback =()=>{

    let {id} = useParams();

    const [projname,setProjName] = useState("");
    const [name,setName] = useState("");
    const [priority, setPriority] = useState("15");
    const [oldImage,setOldImage] = useState('');

    const [subj,setSubj] = useState("");
    const [msg,setMsg] = useState("");
    const [status,setStatus] = useState("");
    const [testPic, setTestPic] = useState({  raw: "" });


    const [err,setErr] = useState(0);
    const [disablebutton,setDisablebutton] = useState(false);

    const [snackBar, setSnackBar] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState("success");
    const [snackBarType, setSnackBarType] = useState("Invalid");

    const [isloading, setIsLoading] = useState(true);

 useEffect(()=>{
    getData();
 },[]);

 const getData = ()=>{
     const formData = new FormData();
     formData.append('id',id);
     Axios.post("/api/getsingletestms", formData,{   
        headers: {headKey:headKey},
     }).then((res) => {
     if(res.data.status){
        
       setProjName(res.data.data.test_name);
       setName(res.data.data.test_prof);
       setSubj(res.data.data.test_sub);
       setMsg(res.data.data.test_msg);
       setPriority(res.data.data.test_priority);
       if(res.data.data.test_image===null){
            setOldImage("");
       }else{
        setOldImage(res.data.data.test_image);
       }
        
       if(res.data.data.test_status===1){
         setStatus(true);
       }else{
         setStatus(false);
       }
        
       setIsLoading(false);
    } 
});  
 }
  

    const openSnkbar = (type, msg) => {
        setSnackBarMsg(msg);
        setSnackBarType(type);
        setSnackBar(true);
      };
   

    const onSubmit=()=>{
        setErr(0);
        if(projname==''){
            setErr(1);
            openSnkbar('error','Please Enter Your Project / Institute Name....');
        }else if(name==''){
            setErr(2);
            openSnkbar('error','Please Enter Your name with Designation....');
        } else if(subj==''){
            setErr(4);
            openSnkbar('error','Please Enter Your Subject....');
        }else if(msg==''){
            setErr(5);
            openSnkbar('error','Please Enter Your Message....');
        } else if(oldImage=='' && testPic.raw==''){
            setErr(3);
            openSnkbar('error','Please Select a Image....');
        }else{
            openSnkbar("warning","Please wait.....");
            setDisablebutton(true);
            const formData=new FormData();

            if(testPic.raw!==''){
                formData.append("testPic",testPic.raw);
            }
            formData.append('id',id);
            formData.append('oldimage',oldImage);
            formData.append('name',projname);
            formData.append('profname',name);
            formData.append('priority',priority);
             
            formData.append('sub',subj);
            formData.append('msg',msg);
            if(status){
                formData.append('status','1');
            }else{
                formData.append('status','0');
            }
            Axios.post("/api/updatetest", formData,{   
                headers: {headKey:headKey},
            }).then((res) => {
            if(res.data.status){
              setDisablebutton(false);
              openSnkbar("success", res.data.msg);
              setTestPic({  raw: "" });
              setIsLoading(true);
              getData();
            }else{
              setDisablebutton(false);
              openSnkbar("error", "Something went wrong... try later.");
            }
        });  
        }
    }

    return ( <Container   
     ><Helmet>
     <title>Edit Feedback - Admin | Arawinz Soft Solutions</title>
     <meta name="description" content="Arawinz Soft Solutions is one of the finest Software Development company. We provide services in Web Development-Full Stack, Web Designing (Static & Responsive), Mobile App Development, Social Media Marketing, SEO, Data Analytics,  CRM, ERP, Mobile Gaming and Artificial Intelligence." />
     <meta name="keywords" content=" Testimonials Top Web Development company in Gachibowli, SEO companies in Hyderabad, Best Application development company in India, Top 5 development companies in Vizag, Digital and Social Media Marketing, Data Analytics company in Hitech city, Graphics Design and Gaming company in Jubilee hills, Best Artificial Intelligence company in Hyderabad, Top gaming organization near me, Available ERP &amp; CRM developers Madhapur"></meta>
 </Helmet>
     
     {isloading ?<Loading/> :(
        <Box  boxShadow={3} p={3} m={4} style={{border:"2px solid #333333" }}>
        <Snackbar
          open={snackBar}
          autoHideDuration={4000}
          onClose={() => {
            setSnackBar(false);
          }}
        >
          <Alert
            onClose={() => {
              setSnackBar(false);
            }}
            severity={snackBarType}
            style={{
              color: snackBarType === "warning" ? "black" : "white",
            }}
          >
            {snackBarMsg}
          </Alert>
        </Snackbar>
            <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={4}>
                <Grid  item xs={12} sm={12} md={12} lg={12} xl={12}    >        
            <h2 style={{textDecoration:"underline",marginBottom:"0px",textAlign:"center" }}  >
               Edit / Approve Feedback</h2>
             
          </Grid>
          <Grid  item xs={12} sm={12}  md={6} lg={6} xl={6}    >        
          <TextField id="filled-required" label="Project / Institute Name" variant="filled" 
                        error={err===1 && true}
                        value={projname}
                        size="small" 
                        onChange={(e)=>{
                            setProjName(e.target.value);
                        }}
                        required fullWidth  />
          </Grid>
          <Grid  item xs={12} sm={12} md={6} lg={6} xl={6} >        
          <TextField id="filled-required" label="Your Name with Designation" variant="filled" 
                        error={err===2 && true}
                        value={name}
                        size="small" 
                        onChange={(e)=>{
                            setName(e.target.value);
                        }}
                        required fullWidth  />
          </Grid>
           
          <Grid  item xs={12} sm={12}  md={6} lg={6} xl={6}>        
          <TextField id="filled-required" label="Subject" variant="filled" 
                        error={err===4 && true}
                        value={subj}
                        size="small" 
                        onChange={(e)=>{
                            setSubj(e.target.value);
                        }}
                        required fullWidth  />
          </Grid>
          <Grid  item xs={12} sm={12}  md={6} lg={6} xl={6} >        
          <TextField id="filled-required" label="Message" variant="filled" 
                        error={err===5 && true}
                        value={msg}
                        size="small" 
                        multiline
                        rows={4}
                        onChange={(e)=>{
                            setMsg(e.target.value);
                        }}
                        required fullWidth  />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              select
              id="filled-required"
              label="Priority"
              variant="filled"
              // error={err === 6 && true}
              value={priority}
              size="small"
              onChange={(e) => {
                setPriority(e.target.value);
              }}
              required
              fullWidth
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
              <MenuItem value="7">7</MenuItem>
              <MenuItem value="8">8</MenuItem>
              <MenuItem value="9">9</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="11">11</MenuItem>
              <MenuItem value="12">12</MenuItem>
              <MenuItem value="13">13</MenuItem>
              <MenuItem value="14">14</MenuItem>
              <MenuItem value="15">15</MenuItem>
            </TextField>
          </Grid>
          <Grid
                item  md={6} lg={6} xl={6} sm={12} xs={12} 
              >
                  {oldImage!="" && (
                  <img width="250px" height="250px" src={"/static/testms/"+oldImage} />
                )}
                {testPic.raw!="" && (
                  <img width="250px" height="250px" src={testPic.preview} style={{padding:"4%"}}/>
                )}
                <br />
                {oldImage!="" && (<span>To replace this Image, Upload new Image<br/></span>)}
                 
                 
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e)=>{
                    if(e.target.files.length!=0){
                   
                      var type = e.target.files[0].type;
                       
                      if ( type === "image/jpeg" || type === "image/jpg" || type === "image/png") {
                          
                        setTestPic({
                          preview: URL.createObjectURL(e.target.files[0]),
                          raw: e.target.files[0],
                        });
                      } else {
                        alert("Please select only JPEG, JPG, PNG");
                      }
                     
                  }}
                }
                  id="contained-button-file"
                  type="file"
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload Image
                  </Button>
                   
                </label>
                {err===3 && (
                  <div style={{ color: "red" }}>Please select a file.</div>
                )}
                 
                
              </Grid>
              <Grid  item xs={12} sm={12}  md={6} lg={6} xl={6} >        
              <Switch
              checked={status}
              onChange={()=>{
                  setStatus(!status);
              }}
              color="primary"
              inputProps={{'aria-label':"primary checkbox"}}
              >

              </Switch>Status - <span  style={status?greenColor:blackColor}>Show</span> /  <span  style={!status?greenColor:blackColor}>Hide</span>
          </Grid>
          <br/>
          <Grid  item xs={12} sm={12}  md={6} lg={6} xl={6}    >  
          <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          disabled={disablebutton}
          >
              {disablebutton?"Please wait..":"Update Feedback"}
              
          </Button>
          </Grid>
          
            </Grid>
        </Box>)}
        </Container>
   );
}
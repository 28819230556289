import { useState,useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Snackbar,
  Switch,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import CKEditor from 'react-ckeditor-component';
import Axios from "axios";

import {EditorCkMain} from './CkEditor';
const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";

export const UpdateAboutUs = () => {
  const [content, setContent] = useState("");
  const [disablebutton, setDisablebutton] = useState(false);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    Axios.get("/api/select_aboutus", {
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.status === 200) {
        console.log(res.data.data);
        setContent(res.data.data.description);
      }
    });
  };

  const updateSitemap = async() =>{
    Axios.get("/api/update-sitemap" ).then((res) => {
      if (res.data.status) {
        console.log("Success");
      } else {
        console.log("Failed");
      }
    });
  }
  const onSubmit = async () => {
    const formdata = new FormData();
    formdata.append("description",content)
    Axios.post("/api/update_aboutus", formdata, {
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.data.status) {
        setDisablebutton(false);
      } else {
        setDisablebutton(false);
      }
    });
  };
  const handleChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
  };
  return (
    <Container>
      <Helmet>
        <title>About Us - Admin | Arawinz Soft Solutions</title>
        <meta
          name="description"
          content="Arawinz Soft Solutions is one of the finest Software Development company. We provide services in Web Development-Full Stack, Web Designing (Static & Responsive), Mobile App Development, Social Media Marketing, SEO, Data Analytics,  CRM, ERP, Mobile Gaming and Artificial Intelligence."
        />
        <meta
          name="keywords"
          content=" Testimonials Top Web Development company in Gachibowli, SEO companies in Hyderabad, Best Application development company in India, Top 5 development companies in Vizag, Digital and Social Media Marketing, Data Analytics company in Hitech city, Graphics Design and Gaming company in Jubilee hills, Best Artificial Intelligence company in Hyderabad, Top gaming organization near me, Available ERP &amp; CRM developers Madhapur"
        ></meta>
      </Helmet>
      <Box boxShadow={3} p={3} m={4} style={{ border: "2px solid #333333" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h2
              style={{
                textDecoration: "underline",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Edit About Us
            </h2>
          </Grid>
            
          <Grid item xs={12} >
            
          <EditorCkMain
                              desc={content}
                              setDescr={(e) => setContent(e)}
                            /> 
            {/* <CKEditor
              activeClass="p10"
              content={content}
              placeholder=""
              config={{
                toolbar: [
                  {
                    name: "document",
                    items: [
                      "Source",
                      "-",
                      "NewPage",
                      "Preview",
                      "-",
                      "Templates",
                    ],
                  },
                  {
                    name: "clipboard",
                    items: [
                      "Cut",
                      "Copy",
                      "Paste",
                      "PasteText",
                      "PasteFromWord",
                      "-",
                      "Undo",
                      "Redo",
                    ],
                  },
                  {
                    name: "styles",
                    items: ["Styles", "Format", "Font", "FontSize"],
                  },
                  { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat' ] },
                  { name: "colors", items: ["TextColor", "BGColor"] },
                  { name: "links", items: ["Link", "Unlink", "Anchor"] },
                  {
                    name: "insert",
                    items: ["Image", "Table", "HorizontalRule", "SpecialChar"],
                  },
                  { name: "tools", items: ["Maximize"] },
                  { name: "others", items: ["-"] },
                  { name: "about", items: ["About"] },
                ],
                height: 200,
                width:800,
              }}
              events={{
                change: handleChange,
              }}
            /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              disabled={disablebutton}
            >
              {disablebutton ? "Please wait.." : "Update About Us"}
            </Button>
          </Grid>
          <Grid item xs={12} >
              <hr/>
          </Grid>

          <Grid item xs={12} style={{textAlign:"center"}}>
              <Button style={{width:"60%"}} variant="contained"
              color="primary" 
              onClick={updateSitemap}
              >
                  Update Sitemap
              </Button>
          </Grid>

          <Grid item xs={12} >
              <hr/>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

import {
    Container,
    Grid,
    Box,
    TextField,
    makeStyles,
    useTheme,
    Slide,
    Typography,
    Paper,
  } from "@material-ui/core";
  import { useState, useEffect } from "react";
  import ReactHtmlParser from "react-html-parser";
  import MuiAlert from "@material-ui/lab/Alert";
  import Axios from "axios";
  import { Helmet } from "react-helmet-async";
  
  import { Loading } from "./loading";
  
  import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
  
  const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";
  
  const useStyles = makeStyles({
    cardshadow: {
      "&:hover": {
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      },
    },
  });
  
  export const OurTeam = () => {
    const theme = useTheme();
  
    const classes = useStyles();
    const [team1, setTeam1] = useState([]);
    const [team2, setTeam2] = useState([]);
    const [metaTitle, setMetaTitle] = useState("");
    const [metaDesc, setMetaDesc] = useState("");
    const [metaKeywords, setMetaKeywords] = useState("");
    const [isloading, setIsloading] = useState(true);
  
    const handleClick = () => {
      const anchor = document.querySelector("#back-to-top-anchor");
  
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };
  
    useEffect(() => {
      getMetaData();
    }, []);
  
    const getMetaData = async () => {
      const formData = new FormData();
      formData.append("page", 8);
      await Axios.post("/api/getmetadata", formData, {
        headers: { headKey: headKey },
      }).then((res) => {
        if (res.data.status) {
          setMetaTitle(res.data.data.meta_title);
          setMetaKeywords(res.data.data.meta_keywords);
          setMetaDesc(res.data.data.meta_desc);
          
          
        }
      });
      await  Axios.post("/api/get_all_team", {
        headers: { headKey: headKey },
      }).then((res) => {
        
        if (res.data.status) {
          setTeam1(res.data.team1);
          setTeam2(res.data.team2);
          setIsloading(false);
          handleClick();
          // console.log(res.data.status);
          // setData(ReactHtmlParser(res.data.data.description));
        }
      });
    };
  
    const jumbotroncss = {
      backgroundImage:
        'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url("/static/our_team.jpg")',
      opacity: 0.8,
      backgroundAttachment: "fixed",
      color: "white",
      minHeight: "250px",
      //marginTop: "0px",
      marginBottom: "0px",
      backgroundPosition: "center", // This centers the image
      backgroundRepeat: "no-repeat", // This prevents the image from tiling
      backgroundSize: "cover", // This makes the image cover the entire area; use "contain" to fit the image within the area without cropping
      
      borderRadius:"0px"
    };
  
    return (
      <div>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDesc} />
          <meta name="keywords" content={metaKeywords}></meta>
        </Helmet>
        <div className="jumbotron text-center" style={jumbotroncss}>
          <Paper style={{ opacity: "0.8" }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h2"
                style={{
                  color: theme.palette.cream.main,
                  fontWeight: 500,
                  WebkitTextStrokeWidth: 2,
                  letterSpacing: 8,
                  WebkitTextStrokeColor: theme.palette.primary.main,
                }}
              >
                Our{" "}
              </Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Typography
                variant="h2"
                style={{
                  color: theme.palette.primary.main,
                  fontWeight: 500,
                  letterSpacing: 8,
                }}
              >
                Team
              </Typography>
            </Grid>
            {/* <Typography
              variant="h5"
              style={{ color: theme.palette.primary.main, letterSpacing: 1 }}
            >
              Idea <ArrowForwardIosIcon /> Strategy <ArrowForwardIosIcon />{" "}
              Execution
            </Typography> */}
            <Typography variant="h6" style={{color:theme.palette.primary.main,letterSpacing:1
            }}>
  The Brains Behind Our Breakthroughs</Typography>
            <br />
          </Paper>
        </div>
       
        {isloading ? (
          <Loading />
        ) : (
          <Box
            boxShadow={3}
            p={3}
             
            style={{ backgroundColor: theme.palette.cream.main }}
          >
          <Container maxWidth="lg">
            
            <br/>
            <Slide
                direction="right"
                in={true}
                mountOnEnter
                unmountOnExit
                {...(true ? { timeout: 1000 } : {})}
              >
                <div class="two">
                  <h1 style={{color:"#4c004c"}}>Leading the Way: Meet Our Core Leadership Team
                    <span>Get to Know Our Leaders, Their Expertise, and the Vision That Guides Us</span>
                  </h1>
                </div>
   
              </Slide>
              <br />
              <br />
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={5}
              >
               {team1.map((team, key) => {
                  
                  return (<Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <div className="teamcard">
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="stretch"
                      spacing={2}
                    >
                      <Grid item xs={12}>
                      <div className="team_image_box" style={{
                            width: "100%",
                            height: "300px" ,
                            position: "relative"  
                          }}>
                           <img src={"/static/team/"+team.image} alt={team.name} className="team_image" style={{ width: "100%", height: "100%", objectFit: "contain",padding:"10px"  }} />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" className="team-name">{team.name}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" className="team-desig">{team.designation}</Typography> 
                      </Grid>
                      <Grid item xs={12}  style={{marginTop:"10px"}}>
                        <Typography variant="body2" className="article_desc">{team.description}</Typography>
                      </Grid>
                    </Grid>
                    </div>
                  </Grid>);
               })}  
              </Grid>
              <br/><br/><br/><br/><br/><br/>
              <Slide
                direction="right"
                in={true}
                mountOnEnter
                unmountOnExit
                {...(true ? { timeout: 1000 } : {})}
              >
                <div class="two">
                  <h1 style={{color:"#4c004c"}}>Innovation Artisans: Our Core Developers
                    <span>Discover the Talented Developers Behind Our Cutting-Edge Solutions and Technologies</span>
                  </h1>
                </div>
   
              </Slide>
              <br />
              <br />
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={5}
              >
               {team2.map((team, key) => {
                  
                  return (<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <div className="teamcard">
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="stretch"
                      spacing={2}
                    >
                      <Grid item xs={12}>
                      <div className="team_image_box" style={{
                            width: "100%",
                            height: "300px" ,
                            position: "relative"  
                          }}>
                           <img src={"/static/team/"+team.image} alt={team.name} className="team_image" style={{ width: "100%", height: "100%", objectFit: "contain",padding:"10px"  }} />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" className="team-name"> {team.name} </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" className="team-desig">{team.designation}</Typography> 
                      </Grid>
                      <Grid item xs={12} style={{marginTop:"10px"}} >
                        <Typography  variant="body2" className="article_desc">{team.description}</Typography>
                      </Grid>
                    </Grid>
                    </div>
                  </Grid>);
               })}  
              </Grid>
            <Slide
                direction="right"
                in={true}
                mountOnEnter
                unmountOnExit
                {...(true ? { timeout: 1000 } : {})}
              >
            <div >
              <br/>
              <br/>
              <br/>
              <blockquote className="article_desc">
              At Arawinz, our ethos is rooted in the seamless fusion of creativity and commitment, driving us to provide outstanding results. Our path is defined by an unwavering dedication to surpassing expectations and an earnest endeavor to grasp and cater to our clients' unique requirements. As a collective, we transcend mere collaboration – we are visionaries shaping tomorrow, piecing together a more intelligent and interconnected reality.
                <span>-&nbsp;Dr. P. Srinivas Rao, CEO</span>
              </blockquote>
              <br/>
              <br/>
            </div>
            </Slide>
          </Container>
          
          </Box>
        )}
     
      </div>
    );
  };
  
import {Box, Button} from '@material-ui/core';
import {useEffect,useState} from 'react';
import {    Link } from 'react-router-dom';
import Axios from 'axios';
import {Loading} from './loading';

import {Helmet} from "react-helmet";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';

const headKey="qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";
const $ = require("jquery");

export const AllTestimonials = () =>{
    const [loading, setLoading] = useState(true);
    const [data,setData] = useState();
    

    useEffect(()=>{
        getData();
     },[]);

    const getData=()=>{
        Axios.get("/api/gettestms",{   
            headers: {headKey:headKey},
         }).then((res) => {
            
            setData(res.data.data);
             
            setLoading(false);
            $(document).ready( function () {
              $('#table_id').DataTable({
                "bDestroy": true,
                scrollX:1700,
                scrollY:500,
              });
          } );
          })
      }

    return (<div>
            {loading?(<Loading/>):(<div>
                <Helmet>
                    <title>All Testimonials - Admin | Arawinz Soft Solutions</title>
                    <meta name="description" content="Arawinz Soft Solutions is one of the finest Software Development company. We provide services in Web Development-Full Stack, Web Designing (Static & Responsive), Mobile App Development, Social Media Marketing, SEO, Data Analytics,  CRM, ERP, Mobile Gaming and Artificial Intelligence." />
                    <meta name="keywords" content=" Testimonials Top Web Development company in Gachibowli, SEO companies in Hyderabad, Best Application development company in India, Top 5 development companies in Vizag, Digital and Social Media Marketing, Data Analytics company in Hitech city, Graphics Design and Gaming company in Jubilee hills, Best Artificial Intelligence company in Hyderabad, Top gaming organization near me, Available ERP &amp; CRM developers Madhapur"></meta>
                </Helmet>
                 
                <br/>
                <Box sx={{width:"95%"}}>
                <table id="table_id" className="table table-striped">
                    <thead>
                        <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        
                        <th>IMAGE</th>
                        <th>PRIORITY</th>
                        <th>STATUS</th>
                        <th>ADDED DATE</th>
                        <th>EDIT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((da)=>{
                            return (<tr>
                                <td>{da.test_id}</td>
                                <td>{da.test_name} - {da.test_prof}</td>
                                <td> {da.test_image!==null?(<img src={"/static/testms/"+da.test_image} style={{height:"150px"}}/>):"No Image"}</td>
                                <td>{da.test_priority}</td>
                                <td>{da.test_status===1?"Active":"InActive"}</td>
                                <td>{ da.test_date }</td>   
                                <td><Button variant="contained" component={Link}   color="primary" to={"/dash/editfeedback/"+da.test_id} fullWidth>EDIT</Button></td> 
                            </tr>);
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>IMAGE</th>
                        <th>PRIORITY</th>
                        <th>STATUS</th>
                        <th>ADDED DATE</th>
                        <th>EDIT</th>
                        </tr>
                    </tfoot>
                </table></Box>
                
            </div>)}
    </div>);
}
import {
  Container,
  Grid,
  Box,
  ButtonBase,
  Button,
  useTheme,
  Slide,
  Typography,
  Paper,
  Avatar,
  Card,
  CardMedia,
  CardActions,
  Grow,
  makeStyles,
  useMediaQuery
} from "@material-ui/core";
import { useState, useEffect, useLayoutEffect } from "react";

import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import { Helmet } from "react-helmet-async";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { Loading } from "./loading";
import { Link } from "react-router-dom";
import PhoneIcon from "@material-ui/icons/Phone";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import  InstagramEmbed from './instaEmbedded'

const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";

const useStyles = makeStyles({
  nounderline: {
    "&:hover": { textDecoration: "none" },
  },
  cardshadow: {
    "&:hover": {
      // borderRadius:"10px",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    },
  },
});

export const Home = () => {
  const theme = useTheme();

  const classes = useStyles();

  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [isloading, setIsloading] = useState(true);

  const [tests, setTests] = useState();
  const [videoTests, setVideoTests] = useState();
  const [cars, setCars] = useState();
  const [services, setServices] = useState();
  const [clients, setClients] = useState();
  const [size, setSize] = useState(0);
  const [active, setActive] = useState(0);

 

  // Define breakpoints
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  const getBackgroundColor = (key ) => {
   // Determine the number of items per row based on the active breakpoint
   const itemsPerRow = isXl ? 4 : isLg ? 4 : isMd ? 3 : isSm ? 3 : 2;
   const rowNumber = Math.floor(key / itemsPerRow);
   const positionInRow = key % itemsPerRow;
   const isEvenRow = rowNumber % 2 === 0;
   const isEvenPosition = positionInRow % 2 === 0;
   // Alternate the starting color based on the row
   if (isEvenRow) {
     return isEvenPosition ? 'rgba(76, 0, 76, 0.1)' : '#fff';
   } else {
     return isEvenPosition ? '#fff' : 'rgba(76, 0, 76, 0.1)';
   }
  };


  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const handleClick = () => {
    const anchor = document.querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    getMetaData();
  }, []);

  const getMetaData = () => {
    const formData = new FormData();
    formData.append("page", 1);
    Axios.post("/api/getmetadata", formData, {
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.data.status) {
        setMetaTitle(res.data.data.meta_title);
        setMetaKeywords(res.data.data.meta_keywords);
        setMetaDesc(res.data.data.meta_desc);

        handleClick();
      }
    });

    Axios.get("/api/gethomedata", {
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.data.status) {
        setTests(res.data.tests);

        setCars(res.data.slides);
        setServices(res.data.services);
        setClients(res.data.clients);
        setVideoTests(res.data.videotests)
        setIsloading(false);
      }
    });
  };

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
        <meta name="keywords" content={metaKeywords}></meta>
      </Helmet>

      {isloading ? (
        <Loading />
      ) : (
        <Box
          boxShadow={3}
          style={{ backgroundColor: theme.palette.cream.main }}
        >
          {cars && (
            <Carousel
              animation="slide"
              timeout={1200}
              autoPlay={true}
              showIndicators={true}
              showArrows={false}
              centerMode={true}
              centerSlidePercentage={size < 960 ? 100 : 50}
              infiniteLoop
              stopOnHover
              swipeable
              showThumbs={false}
              transitionTime={1200}
              statusFormatter={(current, total) => {
                setActive(current - 1);
                return ``;
              }}
            >
              {cars.map((item, i) => (
                <div
                  id={`${i === active && `carousel_image_id_active`}`}
                  className="MuiPaper-elevation1"
                  style={{
                    margin: "5px",
                    //  paddingRight:"5px",
                    borderRadius: "5px",
                    filter: "blur(5px)",
                  }}
                >
                  <img src={item.slide} style={{ borderRadius: "5px" }} />
                  <Typography
                    variant="h1"
                    style={{ opacity: "0.7", fontSize: "18px" }}
                    className="legend"
                  >
                    {item.text}
                  </Typography>
                </div>
              ))}
            </Carousel>
          )}
          <br />
          <Container maxWidth="lg"> 
          <div  >
            <br />
            <Slide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              {...(true ? { timeout: 1000 } : {})}
            >
              <div class="two">
                <h1 style={{color:"#4c004c"}}>Our Expertise: Tailored IT Solutions
                  <span>Delivering Excellence in Information Technology</span>
                </h1>
              </div>
 
            </Slide>
            <br />
            <br />
             
            {services && (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={5}
                className="text-center"
              >
                {services.map((service, key) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className="article_desc">
                      <Grow
                        in={true}
                        {...(true ? { timeout: 500 * (key + 1) } : {})}
                      >
                        <ButtonBase
                          className={classes.nounderline}
                          component={Link}
                          to={
                            "/service-details/" +
                            service.serv_name.split(" ").join("-") +
                            "/" +
                            service.serv_id
                          }
                        >
                          <Card
                            className={classes.cardshadow}
                            style={{
                              width: "250px",
                              border: "1px groove #4c004c",
                              height:"265px"
                            }}
                          >
                            <CardMedia
                              style={{ height: 200 }}
                              image={"/static/services/" + service.serv_image}
                            /> <CardActions>
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              {" "}
                              {/* <ArrowRightIcon />{" "} */}
                              <Typography
                                style={{
                                  textAlign:"center",
                                  color: theme.palette.secondary.main,
                                }}
                              >
                                {service.serv_name}
                              </Typography>
                            </Grid>
                            </CardActions>
                          </Card>
                        </ButtonBase>
                      </Grow>
                    </Grid>
                  );
                })}
              </Grid>
            )}

            <br />
            <br />
          </div>
          <br />
          <div  >
            <br/>
          <Slide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              {...(true ? { timeout: 1000 } : {})}
            >
              <div class="two">
                <h1 style={{color:"#4c004c"}}>Client Testimonials: Hear Their Stories
                  <span>Discover How Our Solutions Make a Difference</span>
                </h1>
              </div>
 
            </Slide>
            <br />
            <br />
      
            {/* <Container maxWidth="md"> */}
              {tests && tests.length != 0 && (
                <Slide
                  direction="up"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  {...(true ? { timeout: 1000 } : {})}
                >
                  <Carousel
                    animation="slide"
                    timeout={4000}
                    autoPlay={true}
                    showIndicators={true}
                    showArrows={false}
                    centerMode={true}
                    centerSlidePercentage={100}
                    infiniteLoop
                    stopOnHover
                    swipeable
                    showThumbs={false}
                    transitionTime={1200}
                    statusFormatter={(current, total) => ``}
                  >
                    {tests.map((item, i) => (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            padding: 25,
                            backgroundColor:"rgb(242, 240, 240)",
                            boxShadow: "none"
                            // border: "1px groove #4c004c",
                          }}
                          className="article_desc"
                          // className={classes.cardshadow}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={12} sm={4} md={4} lg={2} xl={2}>
                              <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Avatar
                                  alt={item.test_name}
                                  src={"/static/testms/" + item.test_image}
                                  style={{
                                    width: theme.spacing(10),
                                    height: theme.spacing(10),
                                  }}
                                />
                                <Typography
                                  variant="h5"
                                  style={{
                                    color: theme.palette.secondary.main,
                                    margin: 13,
                                  }}
                                  gutterBottom
                                >
                                  {item.test_name}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} lg={10} xl={10}>
                              <Typography
                                style={{
                                  textAlign: "left",
                                  color: "#000000",
                                }}
                              >
                               <b>{item.test_sub}</b> - “
                                <span style={{ fontWeight: "normal" }}>
                                  {item.test_msg}
                                </span>
                                ”
                              </Typography>
                              <hr />
                              <Typography
                                style={{ textAlign: "right", color: "#000000" }}
                              >
                                <h6>{item.test_prof}</h6>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    ))}
                  </Carousel>
                </Slide>
              )}
            {/* </Container> */}
          </div>
          <br />
          <br />
          
          <Slide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              {...(true ? { timeout: 1000 } : {})}
            >
              <div class="two">
                <h1 style={{color:"#4c004c"}}>Video Testimonials: Clients in Their Own Words
                  <span>Witness the Journey and Success of Our Clients</span>
                </h1>
              </div>
 
            </Slide>
            
          <br />
          <br />
          {tests && tests.length != 0 && (
                <Slide
                  direction="up"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  {...(true ? { timeout: 1000 } : {})}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                    className="text-center"
                  >
                    {videoTests.map((videotest, i) => (
                      
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={4} style={{textAlign:'center',width:"100%"  }}>
                       
 
<InstagramEmbed  url = {videotest.video_url}/>
                        
                      
                      </Grid>
                    ))}
                  </Grid>
                  {/* <Carousel
                    animation="slide"
                    timeout={4000}
                    autoPlay={true}
                    showIndicators={true}
                    showArrows={false}
                    centerMode={true}
                    centerSlidePercentage={100}
                    infiniteLoop
                    stopOnHover
                    swipeable
                    showThumbs={false}
                    transitionTime={1200}
                    statusFormatter={(current, total) => ``}
                  >
                    {videoTests.map((videotest, i) => (
                      <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign:'center',width:"40%"  }}>
                       
 
<InstagramEmbed  url = {videotest.video_url}/>
                        
                      </Grid>
                      </Grid>
                    ))}
                  </Carousel> */}
                </Slide>
              )}
          <div  >
          <br />
          <br />
            <Slide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              {...(true ? { timeout: 1000 } : {})}
            >
              <div class="two">
                <h1 style={{color:"#4c004c"}}>Our Clients: Successful Partnerships
                  <span>From Small Engagements to Comprehensive Solutions, Celebrating Our Clients' Progress</span>
                </h1>
              </div>
 
            </Slide>
            <br />
            <br />
            
            {clients && (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                 spacing={5}
                className="text-center"
              >
                {clients.map((client, key) => {
                   
                  return (
                    <Grid item xs={6} sm={4} md={4} lg={3} xl={3} key={key}>
                      <Grow
                        in={true}
                        {...(true ? { timeout: 1000 * (key + 1) } : {})}
                      >
                        <ButtonBase
                          className={classes.cardshadow}
                          style={{width:"100%"}}
                          id="clients"
                          LinkComponent={Link}
                          href={client.site_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="image-box" style={{          backgroundColor: getBackgroundColor(key )  }}>
                              <img
                                src={"/static/clients/" + client.image_url}
                                alt={client.name}
                              />
                            </div>

                        </ButtonBase>
                      </Grow>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            <br />
            <br />
          </div>
          <Slide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              {...(true ? { timeout: 1000 } : {})}
            >
          <div  >
            <br/>
            <br/>
            <blockquote className="article_desc"  >
            At Arawinz, we blend innovation with dedication to deliver exceptional solutions. Our team's journey is marked by a relentless pursuit of excellence and a deep commitment to understanding and fulfilling our clients' needs. Together, we are more than just a team - we are architects of the future, crafting a smarter, more connected world.
              <span>-&nbsp;Dr. P. Srinivas Rao, CEO</span>
            </blockquote>
            <br/>
            <br/>
          </div>
          </Slide>
          <Container maxWidth="sm">
            <br />
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Slide
                  direction="up"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  {...(true ? { timeout: 1000 } : {})}
                >
                   <div className="twelve">
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography style={{letterSpacing: "4px",padding:"5px"}}>Tell us your Requirement...</Typography> 
                      
                      <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        to={"/get-in-touch"}
                        className="linkstyle"
                        style={{margin:"10px",textAlign:"center"}}
                      >
                        <PhoneIcon />
                        &nbsp;&nbsp;<Typography style={{letterSpacing: "4px"}}>Contact Us</Typography>
                      </Button>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                    </Grid>
                    </div>
                </Slide>
              </Grid>
            </Grid>
           
          </Container>
          <br/>
          <br/>
          <br />
          </Container>
        </Box>
      )}
    
    </div>
  );

  
};

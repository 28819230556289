import {
  AppBar,
  Toolbar,
  Button,
  Container,
  Menu,
  MenuItem,
  Fade,
  Grid,
  Hidden,
  Popper,
  Grow,
  Paper,
  MenuList,
  ClickAwayListener,
  ListItem,
  ListItemIcon,
  List,
  ListItemText,
  Drawer,
  Divider,
  Typography,
  useTheme
} from "@material-ui/core";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserContext } from "./UserContext";
import { useContext, useState, useRef } from "react";
import { Snackbar } from "@material-ui/core";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MenuIcon from "@material-ui/icons/Menu";

import { useNavigate } from "react-router-dom";

import MuiAlert from "@material-ui/lab/Alert";

import Axios from "axios";
import { Link } from "react-router-dom";

const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const NavBar = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const anchorRef1 = useRef(null);
  const [open1, setOpen1] = useState(false);

  const [snackBar, setSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("success");
  const [snackBarType, setSnackBarType] = useState("Invalid");

  //const open = Boolean(anchorEl);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleToggle1 = () => {
    setOpen1((prevOpen) => !prevOpen);
  };

  const handleClose1 = (event) => {
    if (anchorRef1.current && anchorRef1.current.contains(event.target)) {
      return;
    }
    setState({ ...state, ["bottom"]: false });
    setOpen1(false);
  };

  function handleListKeyDown1(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1(false);
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const openSnkbar = (type, msg) => {
    setSnackBarMsg(msg);
    setSnackBarType(type);
    setSnackBar(true);
  };

  const logout = () => {
    Axios.get("/api/logout", {
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.data.status) {
        openSnkbar("success", res.data.msg);
        setUser(null);
        setOpen((prevOpen) => !prevOpen);
        setState({ ...state, ["bottom"]: false });
        navigate("/");
      } else {
      }
    });
  };

  return (
    <AppBar color={"primary"} position="sticky" id="back-to-top-anchor">
      <Container>
        <Snackbar
          open={snackBar}
          autoHideDuration={4000}
          onClose={() => {
            setSnackBar(false);
          }}
        >
          <Alert
            onClose={() => {
              setSnackBar(false);
            }}
            severity={snackBarType}
            style={{
              color: snackBarType === "warning" ? "black" : "white",
            }}
          >
            {snackBarMsg}
          </Alert>
        </Snackbar>
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Link to="/" className="linkstyle">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <div
                      style={{
                        padding: "4px",
                        margin: "4px",
                        border: "2px solid #4c004c",
                        borderRadius: "50%",
                        backgroundColor: "#f2f0f0",
                        borderStyle: "double",
                      }}
                    >
                      <img
                        src="/static/logo.png"
                        alt="Arawinz logo"
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                  </Grid>
                  <Hidden mdDown>
                    <Grid item>
                      <Typography style={{fontSize:20,color:theme.palette.cream.main}}>&nbsp;&nbsp;Arawinz Soft Solutions</Typography>
                    </Grid>
                  </Hidden>
                </Grid>
              </Link>
            </Grid>
            <Grid item>
              <Hidden lgUp>
                <Button onClick={toggleDrawer("bottom", true)}>
                  <MenuIcon style={{ color: "white" }} />
                </Button>
                <Drawer
                  anchor={"bottom"}
                  open={state["bottom"]}
                  onClose={toggleDrawer("bottom", false)}
                >
                  <List component="nav" aria-label="contacts">
                    <ListItem
                      button
                      component={Link}
                      to={"/about-us"}
                      onClick={toggleDrawer("bottom", false)}
                    >
                      <ListItemText primary="About Us" />
                    </ListItem>
                    <Divider />
                    <ListItem
                      button
                      component={Link}
                      to={"/our-services"}
                      onClick={toggleDrawer("bottom", false)}
                    >
                      <ListItemText primary="Our Services" />
                    </ListItem>
                    <Divider />
                    <ListItem
                      button
                      component={Link}
                      to={"/our-products"}
                      onClick={toggleDrawer("bottom", false)}
                    >
                      <ListItemText primary="Our Products" />
                    </ListItem>
                    <Divider />
                    <ListItem
                      button
                      component={Link}
                      to={"/our-team"}
                      onClick={toggleDrawer("bottom", false)}
                    >
                      <ListItemText primary="Our Team" />
                    </ListItem>
                    <Divider />
                    <ListItem
                      button
                      component={Link}
                      to={"/whats-new"}
                      onClick={toggleDrawer("bottom", false)}
                    >
                      <ListItemText primary="What's New" />
                    </ListItem>
                    <Divider />
                    {/* <ListItem
                      button
                      component={Link}
                      to={"/our-team"}
                      onClick={toggleDrawer("bottom", false)}
                    >
                      <ListItemText primary="Our Team" />
                    </ListItem>
                    <Divider /> */}
                    <ListItem
                      button
                      component={Link}
                      to={"/get-in-touch"}
                      onClick={toggleDrawer("bottom", false)}
                    >
                      <ListItemText primary="Contact Us" />
                    </ListItem>
                    {user && <Divider />}
                    {user && (
                      <ListItem>
                        <Button
                          ref={anchorRef1}
                          aria-controls={open1 ? "menu-list-grow" : undefined}
                          aria-haspopup="true"
                          onClick={handleToggle1}
                          style={{ color: "white", paddingLeft: "0" }}
                          fullWidth
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            style={{ color: "black" }}
                          >
                            <Grid item>My account&nbsp;</Grid>
                            <Grid item>
                              <KeyboardArrowDownIcon />
                            </Grid>
                          </Grid>
                        </Button>
                        <Popper
                          open={open1}
                          anchorEl={anchorRef1.current}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper>
                                <ClickAwayListener onClickAway={handleClose1}>
                                  <MenuList
                                    autoFocusItem={open1}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown1}
                                  >
                                    <MenuItem
                                      onClick={handleClose1}
                                      component={Link}
                                      to={"/dash"}
                                    >
                                      <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                      >
                                        <Grid item>
                                          Go To My Account&nbsp;&nbsp;
                                        </Grid>
                                        <Grid item>
                                          <AccountCircleIcon />
                                        </Grid>
                                      </Grid>
                                    </MenuItem>
                                    <MenuItem onClick={logout} component={Link}>
                                      <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <Grid item>Logout</Grid>
                                        <Grid item>
                                          <ExitToAppIcon />
                                        </Grid>
                                      </Grid>
                                    </MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </ListItem>
                    )}
                  </List>
                </Drawer>
              </Hidden>
              <Hidden mdDown>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      color="inherit"
                      component={Link}
                      to={"/about-us"}
                      className="linkstyle"
                    >
                      <Typography>About us</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="inherit"
                      component={Link}
                      to={"/our-services"}
                      className="linkstyle"
                    >
                      <Typography>Our Services</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="inherit"
                      component={Link}
                      to={"/our-products"}
                      className="linkstyle"
                    >
                      <Typography>Our Products</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="inherit"
                      component={Link}
                      to={"/our-team"}
                      className="linkstyle"
                    >
                      <Typography>Our Team</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="inherit"
                      component={Link}
                      to={"/whats-new"}
                      className="linkstyle"
                    >
                      <Typography>What's New</Typography>
                    </Button>
                  </Grid>
                  {/* <Grid item>
                    <Button
                      color="inherit"
                      component={Link}
                      to={"/our-team"}
                      className="linkstyle"
                    >
                     <Typography> Our Team</Typography>
                    </Button>
                  </Grid> */}
                  <Grid item>
                    <Button
                      color="inherit"
                      component={Link}
                      to={"/get-in-touch"}
                      className="linkstyle"
                    >
                      <Typography >Contact Us</Typography>
                    </Button>
                  </Grid>

                  {user && (
                    <Grid item>
                      <Button
                        ref={anchorRef}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        style={{ color: "white" }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                          <Typography>   My account&nbsp;</Typography></Grid>
                          <Grid item>
                            <KeyboardArrowDownIcon />
                          </Grid>
                        </Grid>
                      </Button>
                      <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  autoFocusItem={open}
                                  id="menu-list-grow"
                                  onKeyDown={handleListKeyDown}
                                >
                                  <MenuItem
                                    onClick={handleClose}
                                    component={Link}
                                    to={"/dash"}
                                  >
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="space-evenly"
                                      alignItems="center"
                                    >
                                      <Grid item>
                                        Go To My Account&nbsp;&nbsp;
                                      </Grid>
                                      <Grid item>
                                        <AccountCircleIcon />
                                      </Grid>
                                    </Grid>
                                  </MenuItem>
                                  <MenuItem onClick={logout} component={Link}>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Grid item>Logout</Grid>
                                      <Grid item>
                                        <ExitToAppIcon />
                                      </Grid>
                                    </Grid>
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </Grid>
                  )}
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

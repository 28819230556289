import { Container, Box, Grid, Button, useTheme } from "@material-ui/core";

import { useState, useContext } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import { UserContext } from "./UserContext";

import { AllTests } from "./alltests";
import {
  Route,
  Link,
  Navigate,
  useNavigate,
  BrowserRouter,
  HashRouter,
  Routes,
} from "react-router-dom";

import { AddService } from "./addservice";
import { UpdateService } from "./updateservice";
import { AllServices } from "./allservices";
import { AllTestimonials } from "./alltestimonials";
import { AllContacts } from "./allcontacts";
import { AllMetadata } from "./allmetadatas";
import { UpdateMetaData } from "./updatemetadata";
import { AllClients } from "./allclients";
import { AddClient } from "./addClient";
import { Team } from "./allteam";
import { AddTeam } from "./addteam";
import { UpdateTeam } from "./updateteam";
import {AllBanners} from "./allbanners"
import {AddBanner} from "./addbanner"
import { UpdateBanner } from "./updatebanners";

import { Helmet } from "react-helmet";

import { EditFeedback } from "./editfeedback";
import MuiAlert from "@material-ui/lab/Alert";

import Axios from "axios";
import { UpdateClient } from "./updateclient";
import { UpdateAboutUs } from "./updateaboutus";
// import { AllArticles } from "./allarticles";
// import { AddArticle } from "./addarticle";
// import { UpdateArticle } from "./updatearticle";
import {AddArticle} from "./addArticle";
import {EditArticle} from "./EditArticle"
import {AllArticles} from './allArtcles'
import {EnrolledStudents} from './enrolled_students'


const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";

export const Dash = () => {
  let navigate = useNavigate();
  const theme = useTheme();
  const { user, setUser } = useContext(UserContext);

  const [snackBar, setSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("success");
  const [snackBarType, setSnackBarType] = useState("Invalid");

  const openSnkbar = (type, msg) => {
    setSnackBarMsg(msg);
    setSnackBarType(type);
    setSnackBar(true);
  };
  return (
    <div className="container-fluid">
      <Helmet>
        <title>Admin Dashboard | Arawinz Soft Solutions</title>
        <meta
          name="description"
          content="Arawinz Soft Solutions is one of the finest Software Development company. We provide services in Web Development-Full Stack, Web Designing (Static & Responsive), Mobile App Development, Social Media Marketing, SEO, Data Analytics,  CRM, ERP, Mobile Gaming and Artificial Intelligence."
        />
        <meta
          name="keywords"
          content=" Top Web Development company in Gachibowli, SEO companies in Hyderabad, Best Application development company in India, Top 5 development companies in Vizag, Digital and Social Media Marketing, Data Analytics company in Hitech city, Graphics Design and Gaming company in Jubilee hills, Best Artificial Intelligence company in Hyderabad, Top gaming organization near me, Available ERP &amp; CRM developers Madhapur"
        ></meta>
      </Helmet>
      {user && (
        <h5
          style={{
            color: theme.palette.primary.main,
            marginTop: "1rem",
            marginRight: "32px",
          }}
          className="text-right"
        >
          Welcome, {user.aduser_name}
          <br />( {user.aduser_mob} )
        </h5>
      )}
      <Box boxShadow={3} p={3} m={4} style={{ border: "2px solid #333333" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Box boxShadow={3} p={3} m={4}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    component={Link}
                    fullWidth={true}
                    color="primary"
                    to={"/dash/enrolled_students"}
                    className="linkstyle"
                  >
                    Enrolled Students
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    component={Link}
                    fullWidth={true}
                    color="primary"
                    to={"/dash/allcontacts"}
                    className="linkstyle"
                  >
                    Contact History
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    component={Link}
                    fullWidth={true}
                    color="primary"
                    to={"/dash/allservices"}
                    className="linkstyle"
                  >
                    Services
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    component={Link}
                    fullWidth={true}
                    color="primary"
                    to={"/dash/allmetadatas"}
                    className="linkstyle"
                  >
                    MetaData
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    component={Link}
                    fullWidth={true}
                    color="primary"
                    to={"/dash/alltestimonials"}
                    className="linkstyle"
                  >
                    Testimonials
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    component={Link}
                    fullWidth={true}
                    color="primary"
                    to={"/dash/allclients"}
                    className="linkstyle"
                  >
                    Clients
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    component={Link}
                    fullWidth={true}
                    color="primary"
                    to={"/dash/team"}
                    className="linkstyle"
                  >
                    Team
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    component={Link}
                    fullWidth={true}
                    color="primary"
                    to={"/dash/banners"}
                    className="linkstyle"
                  >
                    Banners
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    component={Link}
                    fullWidth={true}
                    color="primary"
                    to={"/dash/allarticles"}
                    className="linkstyle"
                  >
                    Articles
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    component={Link}
                    fullWidth={true}
                    color="primary"
                    to={"/dash/updateaboutus"}
                    className="linkstyle"
                  >
                  About Us            </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
            <Routes>
            
              <Route
                path="/enrolled_students"
                element={user ? <EnrolledStudents /> : <Navigate to="/login_ad" />}
              />
              <Route
                path="/allservices"
                element={user ? <AllServices /> : <Navigate to="/login_ad" />}
              />
              <Route
                path="/alltestimonials"
                element={
                  user ? <AllTestimonials /> : <Navigate to="/login_ad" />
                }
              />
              <Route
                path="/allcontacts"
                element={user ? <AllContacts /> : <Navigate to="/login_ad" />}
              />
              <Route
                path="/allmetadatas"
                element={user ? <AllMetadata /> : <Navigate to="/login_ad" />}
              />
              <Route
                path="/addservice"
                element={user ? <AddService /> : <Navigate to="/login_ad" />}
              />
              <Route
                path="/editservice/:id"
                element={user ? <UpdateService /> : <Navigate to="/login_ad" />}
              />
              <Route
                path="/editfeedback/:id"
                element={user ? <EditFeedback /> : <Navigate to="/login_ad" />}
              />
              <Route
                path="/editmetadata/:id"
                element={
                  user ? <UpdateMetaData /> : <Navigate to="/login_ad" />
                }
              />
              <Route
                path="/allclients"
                element={user ? <AllClients /> : <Navigate to="/login_ad" />}
              />
              <Route
                path="/addclient"
                element={user ? <AddClient /> : <Navigate to="/login_ad" />}
              />
              <Route
                path="/updateclient/:id"
                element={user ? <UpdateClient /> : <Navigate to="/login_ad" />}
              />
              <Route
                path="/team"
                element={user ? <Team /> : <Navigate to="/login_ad" />}
              />
              <Route
                path="/addteam"
                element={user ? <AddTeam /> : <Navigate to="/login_ad" />}
              />
              <Route
                path="/updateteam/:id"
                element={user ? <UpdateTeam /> : <Navigate to="/login_ad" />}
              />
              <Route
                path="/banners"
                element={user ? <AllBanners /> : <Navigate to="/login_ad" />}
              />
               <Route
                path="/addbanner"
                element={user ? <AddBanner /> : <Navigate to="/login_ad" />}
              />
               <Route
                path="/updatebanner/:id"
                element={user ? <UpdateBanner /> : <Navigate to="/login_ad" />}
              />
               <Route
                path="/updateaboutus"
                element={user ? <UpdateAboutUs/> : <Navigate to="/login_ad" />}
              />
               <Route
                path="/allarticles"
                element={user ? <AllArticles/> : <Navigate to="/login_ad" />}
              />
               <Route
                path="/add_article"
                element={user ? <AddArticle/> : <Navigate to="/login_ad" />}
              />
               <Route
                path="/edit_articles/:id"
                element={user ? <EditArticle/> : <Navigate to="/login_ad" />}
              />
            </Routes>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
